import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MultiRangeSlider from "./range-slider";
import "./filter.css"

const Filter = (props) => {

    const {
        facetValues,
        selectedFilters,
        resetFilters,
        facetStatsValues,
        selectedRangeFilters,
        setSelectedRangeFilters,
        setSelectedFilters,
        setShowModal,
        searchQuery,
        loadRange,
        setLoadRange
    } = props;

    // Filtering list options
    const listHandle = (event, option, facetName) => {

        if (event.target.classList.contains("active")) {
            event.target.classList.remove("active");
            setSelectedFilters({ ...selectedFilters, [facetName]: [] });
        } else {
            var elems = document.querySelectorAll(".color-button button");
            [].forEach.call(elems, function (el) {
                el.classList.remove("active");
            });
            event.target.classList.add("active");
            setSelectedFilters({ ...selectedFilters, [facetName]: [option] });
        }
    }
    const dropDownHandler = (event, facetName) => {
        if (event.target.checked) {
            if (!selectedFilters[facetName])
                selectedFilters[facetName] = []
            setSelectedFilters({ ...selectedFilters, [facetName]: [...selectedFilters[facetName], event.target.value] });
        } else {
            const index = selectedFilters[facetName].indexOf(event.target.value.toString());
            if (index > -1) {
                selectedFilters[facetName].splice(index, 1);
                setSelectedFilters({ ...selectedFilters, [facetName]: selectedFilters[facetName] });
            }
        }
    }

    // Clear filter inputs
    const clear = () => {
        resetFilters();
        var w = document.getElementsByTagName("input");
        for (var i = 0; i < w.length; i++) {
            if (w[i].type == "checkbox") {
                w[i].checked = false;
            }
        }
        var elems = document.querySelectorAll(".color-button button");
        [].forEach.call(elems, function (el) {
            el.classList.remove("active");
        });
    }

    const handleListOption = (facetName, i) => {
        return (
            <li key={i}>
                <a className="uk-accordion-title" href="javascript:void(0)">COLOR
                    {selectedFilters[facetName] && selectedFilters[facetName].length > 0 ? <span class="uk-badge uk-background-muted ml-2">
                        <small className="text-dark">{selectedFilters[facetName].length}</small>
                    </span> : ""}
                </a>
                <div className="uk-accordion-content">
                    <div className="color-button uk-flex uk-display-inline">
                        {Object.keys(facetValues[facetName]).includes("yellow-gold") ?
                            <button
                                className="round-1"
                                onClick={e => listHandle(e, "yellow-gold", facetName)}
                            /> : ""}
                        {Object.keys(facetValues[facetName]).includes("rose-gold") ?
                            <button
                                className="round-2"
                                onClick={e => listHandle(e, "rose-gold", facetName)}
                            /> : ""}
                        {Object.keys(facetValues[facetName]).includes("white-gold") ?
                            <button
                                className="round-3"
                                onClick={e => listHandle(e, "white-gold", facetName)}
                            /> : ""}
                    </div>
                </div>
            </li>
        )
    }

    const uniqiueKeys = (arraySet) => {
        let filteredArray = []
        arraySet.forEach(raw => {
            const splittedArray = raw.split(",");
            splittedArray.forEach(element => {
                if (filteredArray.indexOf(element) < 0)
                    filteredArray.push(element);
            });
        });
        return filteredArray
    }

    const handleDropdownOption = (facetName, i) => {

        let formattedArray = uniqiueKeys(Object.keys(facetValues[facetName]))
        formattedArray = formattedArray.reduce((a, i) => {
            i = i.trim();
            if (!a.includes(i)) a.push(i);
            return a;
        }, [])
        return (
            <li key={i}>
                <a className="uk-accordion-title text-uppercase" href="javascript:void(0)">
                    {facetName.replace("variants.", "").replace("_name", "").replace("_", " ")}
                    {selectedFilters[facetName] && selectedFilters[facetName].length > 0 ? <span class="uk-badge uk-background-muted ml-2">
                        <small className="text-dark">{selectedFilters[facetName].length}</small>
                    </span> : ""}
                </a>
                <div className="uk-accordion-content">
                    <form>
                        <fieldset className="uk-fieldset">
                            <div className="uk-grid-small uk-child-width-auto uk-grid over_height_scroller" id="over_height_scroller_style">
                                {formattedArray.map((value, i) => (
                                    <label
                                        key={i}
                                        className="uk-padding-remove uk-width-1-1 text-uppercase"
                                    >
                                        <input
                                            id={i}
                                            className="uk-checkbox"
                                            type="checkbox"
                                            value={value}
                                            checked={Object.keys(selectedFilters).length != 0 && selectedFilters[facetName] && selectedFilters[facetName].includes(value)}
                                            onClick={e => dropDownHandler(e, facetName)}
                                        />
                                        {value.replace("_", " ")}
                                    </label>
                                ))}
                            </div>
                        </fieldset>
                    </form>
                </div>
            </li>
        )
    }

    const hideModal = () => {
        setShowModal(false)
        setLoadRange(false)
    }

    const selectRangeOption = (value) => {
        if (loadRange)
            setSelectedRangeFilters(value)
        setLoadRange(true)
    }

    const handleRangeOption = () => {
        if (facetStatsValues && facetStatsValues["variants.offerprice"])
            return (
                <li className="uk-open">
                    <a className="uk-accordion-title" href="javascript:void(0)">Price range</a>
                    <div className="uk-accordion-content">
                        <div className="d-flex justify-content-center py-3">
                            <MultiRangeSlider
                                min={facetStatsValues["variants.offerprice"].min}
                                max={facetStatsValues["variants.offerprice"].max}
                                value={selectedRangeFilters}
                                onChange={value => selectRangeOption({ value })}
                            />
                        </div>
                        <hr className="uk-margin-medium" />
                    </div>
                </li>
            )
    }

    return (
        <div>
            <div className="uk-width-1-1 new-arrivals uk-display-block">
                {/* <Link to={`${process.env.PUBLIC_URL}/jewellery/?sort_by=Newest`}>
                    <button className="uk-button uk-width-1-1 uk-button-default uk-padding-remove-left">NEW ARRIVALS</button>
                </Link> */}
                <Link to={`${process.env.PUBLIC_URL}/trending`}>
                    <button className="uk-button uk-width-1-1 uk-button-default uk-padding-remove-left">TRENDING</button>
                </Link>
                <Link to={`${process.env.PUBLIC_URL}/jewellery`}>
                    <button className="uk-button uk-width-1-1 uk-button-default uk-padding-remove-left">SHOP ALL</button>
                </Link>
                <hr />
            </div>

            {/* Filter  */}
            <div className="uk-width-1-1 uk-display-block">
                <div className="uk-child-width-1-2 uk-text-left" data-uk-grid>
                    <div className="uk-visible@m uk-padding-remove uk-flex uk-flex-middle">
                        <div className="filter-text"><b>Filter your choices</b></div>
                    </div>
                    <div className="uk-flex uk-flex-center">
                        <button className="uk-button uk-button-default w-100" onClick={() => clear()}>
                            <span>Reset</span>
                        </button>
                    </div>
                    <div className="uk-hidden@m uk-flex uk-flex-center" onClick={() => hideModal()}>
                        <div className="uk-button uk-button-primary uk-margin-medium-top@s w-100"><b>See Results</b></div>
                    </div>
                </div>
            </div>
            <hr />
            <div className="uk-width-1-1 sd-accordion">
                <ul data-uk-accordion="transition: ease; animation:true;">
                    {facetValues && Object.keys(facetValues).map((facetName, i) => {
                        if (facetName == "variants.color")
                            return (handleListOption(facetName, i))
                        else if (facetName == "variants.offerprice")
                            return null
                        else if (facetName == "category_name" && searchQuery != "")
                            return null
                        else
                            return (handleDropdownOption(facetName, i))
                    })}
                    {handleRangeOption()}
                </ul>
            </div>
        </div>
    )
}

export default Filter;
