import { RECEIVE_ENQUIRY_TYPE } from "../constants/ActionTypes";

const initialState = {
    enquiryType: []
};

const EnquiryReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_ENQUIRY_TYPE:
      return {
        ...state,
        enquiryType: action.payload
      };
    default:
      return state;
  }
  
};

export default EnquiryReducer;
