import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";

import Navbar from "./navbar";
import CartContainer from "../../containers/CartContainer"
import StickyNavbar from "./mobile-sticky-navbar";
import MobileSideNavbar from "./mobile-sidebar";

import "./css/header-body.css"

import SearchItems from "./search-items";

import axios from 'axios';


const HeaderBody = (props) => {

    const urlParams = new URLSearchParams((window.location.search));

    const [user, setUser] = useState(null)
    const [search, setSearch] = useState(urlParams.get('query'))
    const [searchResults, setSearchResults] = useState([]);
    const [totalProducts, setTotalProducts] = useState(0);
    const [isSearching, setIsSearching] = useState(false);
    const [showSearchModal, setShowSearchModal] = useState(false);

    // const searchClient = algoliasearch(window.rc.algoliasearch.appId, window.rc.algoliasearch.apiKey);
    // const index = searchClient.initIndex(window.rc.algoliasearch.defaultIndex);

    const [selectedSorting, setSelectedSorting] = useState(window.rc.algoliasearch.defaultIndex);


    useEffect(() => {
        if (sessionStorage.getItem("user_type") == "CUSTOMER")
            setUser("dashboard")
        else
            setUser("login")
    }, [sessionStorage.getItem("user_type")])

    function debounce(func) {
        var timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                timeout = null;
                func.apply(undefined, args);
            }, 200);
        };
    }

    const handleSearch = () => {
        axios.post(global.postUrl + "restproxy", {
          "headers": {
            "X-Algolia-Application-Id": window.rc.algoliasearch.appId,
            "X-Algolia-API-Key": window.rc.algoliasearch.apiKey
          },
          body: {
            query: search,
            hitsPerPage: 6,
          },
          "url": "https://" + window.rc.algoliasearch.appId + "-dsn.algolia.net/1/indexes/" + selectedSorting + "/query",
          "force": false
        })
        .then((response) => {
            let { hits, nbHits} = response.data.response;

            setSearchResults(hits);
            setTotalProducts(nbHits);
        }).catch(error => console.error(error))
      };
      

    useEffect(() => {
        if (search) {
            setIsSearching(true)
            handleSearch(search)
        }
    }, [search]);

    useEffect(() => {
        setIsSearching(false)
    }, [searchResults]);

    const searching = (value) => {
        setShowSearchModal(true)
        setSearch(value)
    }

    return (
        <div className="sticky-panel uk-sticky-fixed" onClick={() => setShowSearchModal(false)}>
            <div className="header-container" data-uk-sticky="start: 0; end:.sticky-filter-sidebar;cls-active:uk-active">
                <div className="header">

                    {/* search box  */}
                    <form className="search-box" action="/jewellery">
                        <input
                            id="search"
                            type="text"
                            placeholder="Find the best for you..."
                            className="input-text"
                            maxLength="128"
                            value={search}
                            name="query"
                            onChange={(event) => searching(event.target.value)}
                        />

                        <button
                            type="submit"
                            title="Search"
                            className="action search"
                            aria-label="Search"
                            disabled=""
                        >
                            <span>Search</span>
                        </button>
                    </form>

                    {/* essential buttons  */}

                    <div className="search-links">
                    <div className="wishlist uk-flex-inline">
                            <Link to={`${process.env.PUBLIC_URL}/compare_list`}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none" viewBox="0 0 24 24"
                                    strokeWidth="1.6"
                                    stroke="#722257"
                                    className="w-6 h-6"
                                    width="17"
                                    height="17"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                                    />
                                </svg>
                            </Link>
                            <h5 className="my-0 ml-1">({props.compareItems.length > 0 ? props.compareItems.length : 0})</h5>
                        </div>
                        <div className="wishlist uk-flex-inline ml-md-3 ml-0">
                            <Link to={`${process.env.PUBLIC_URL}/wishlists`}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    width="17"
                                    height="17"
                                >
                                    <path
                                        fill="none"
                                        stroke="#722257"
                                        strokeWidth="1.6"
                                        d="M10,4 C10,4 8.1,2 5.74,2 C3.38,2 1,3.55 1,6.73 C1,8.84 2.67,10.44 2.67,10.44 L10,18 L17.33,10.44 C17.33,10.44 19,8.84 19,6.73 C19,3.55 16.62,2 14.26,2 C11.9,2 10,4 10,4 L10,4 Z"
                                    />
                                </svg>
                            </Link>
                            <h5 className="my-0 ml-1">({props.wishListItems.length > 0 ?props.wishListItems.length : 0})</h5>
                        </div>
                        <div className="account uk-margin-small-left">
                            <Link to={`${process.env.PUBLIC_URL}/` + user}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    width="17"
                                    height="17"
                                >
                                    <circle
                                        fill="none"
                                        stroke="#722257"
                                        strokeWidth="1.6"
                                        cx="9.9"
                                        cy="6.4"
                                        r="4.4"
                                    />
                                    <path
                                        fill="none"
                                        stroke="#722257"
                                        strokeWidth="1.6"
                                        d="M1.5,19 C2.3,14.5 5.8,11.2 10,11.2 C14.2,11.2 17.7,14.6 18.5,19.2"
                                    />
                                </svg>
                            </Link>
                        </div>
                        <div className="cart cart-separation uk-margin-small-left">
                            <CartContainer />
                        </div>
                    </div>

                    {/* Mobile side navbar  */}

                    <MobileSideNavbar history={props.history} />

                    {/* Navbar  */}

                    <Navbar navBar={props.navBar} />

                    {/* logo  */}

                    <div className="logo d-lg-block d-none">
                        <Link to={`${process.env.PUBLIC_URL}/`}>
                            {window.logo.desktop.map((data, i) => (
                                <div key={i}>
                                    <img
                                        className="img-fluid"
                                        src={`${process.env.PUBLIC_URL}` + data.image}
                                        alt={data.alt}
                                    />
                                </div>
                            ))}
                        </Link >
                    </div>
                    <div className="logo d-lg-none d-block">
                        <Link to={`${process.env.PUBLIC_URL}/`}>
                            {window.logo.mobile.map((data, i) => (
                                <div key={i}>
                                    <img
                                        className="img-fluid"
                                        src={`${process.env.PUBLIC_URL}` + data.image}
                                        alt={data.alt}
                                    />
                                </div>
                            ))}
                        </Link >
                    </div>
                </div>
                {showSearchModal ?
                    <div className="w-100" style={{ position: "absolute", zIndex: 9999 }}>
                        {isSearching ?
                            <div className="uk-width-3-5 uk-align-center uk-background-default">
                                <div className="uk-box-shadow-xlarge">
                                    <div className=" uk-text-center uk-padding-small uk-flex uk-flex-middle uk-flex-center" style={{ minHeight: "220px" }}>
                                        <span className="uk-margin-small-right uk-text-lead" uk-spinner="ratio: 3"></span>
                                    </div>
                                    <Link to={`${process.env.PUBLIC_URL}/jewellery`}>
                                        <div className="uk-padding-small" style={{ background: "#72225721" }}>
                                            <h4 className="para-one uk-text-emphasis uk-margin-remove">Can't wait for search. explore the collections. <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                                            </svg></h4>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            : searchResults.length != 0 ?
                                <div className="uk-width-3-5 uk-align-center uk-background-default">
                                    <div className="uk-box-shadow-xlarge">
                                        <h5 className="uk-text-emphasis uk-margin-remove uk-padding-small uk-padding-remove-bottom">We are showcased <b>{totalProducts} products</b> for you </h5>
                                        <div className="uk-grid uk-child-width-1-1@s uk-child-width-1-2@m uk-child-width-1-3@xl uk-text-center uk-padding-small uk-padding-remove-top pl-0" data-uk-grid style={{ minHeight: "220px" }}>
                                            {searchResults.map((product, index) => (
                                                <SearchItems product={product} index={index} />
                                            ))}
                                        </div>
                                        {search ?
                                            <Link to={`${process.env.PUBLIC_URL}/jewellery?query=${search}`}>
                                                <div className="uk-padding-small" style={{ background: "#72225721" }}>
                                                    <h4 className="para-one uk-text-emphasis uk-margin-remove">Explore our products that match the key <b>"{search}"</b>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                                                        </svg>
                                                    </h4>      </div>
                                            </Link> :
                                            <Link to={`${process.env.PUBLIC_URL}/jewellery`}>
                                                <div className="uk-padding-small" style={{ background: "#72225721" }}>
                                                    <h4 className="para-one uk-text-emphasis uk-margin-remove">Explore our products <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                                                    </svg></h4>
                                                </div>
                                            </Link>}
                                    </div>
                                </div> : search ?
                                    <div className="uk-width-3-5 uk-align-center uk-background-default" >
                                        <div className="uk-box-shadow-xlarge">
                                            <div className="uk-text-center uk-padding-small uk-flex uk-flex-middle uk-flex-center" style={{ minHeight: "220px" }}>
                                                <div>
                                                    <img src="/assets/images/sunny/no_data.svg" style={{ height: "100px" }} />
                                                    <h4 className="para-one uk-text-emphasis uk-padding-small uk-margin-remove">No products matching</h4>
                                                </div>
                                            </div>
                                            <Link to={`${process.env.PUBLIC_URL}/jewellery`}>
                                                <div className="uk-padding-small" style={{ background: "#72225721" }}>
                                                    <h4 className="para-one uk-text-emphasis uk-margin-remove">Don't get stuck with the search. explore our wide variety collection even more. <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                                                    </svg></h4>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    : <div className="uk-width-3-5 uk-align-center uk-background-default" >
                                        <div className="uk-box-shadow-xlarge">
                                            <div className="uk-text-center uk-padding-small uk-flex uk-flex-middle uk-flex-center" style={{ minHeight: "220px" }}>
                                                <div>
                                                    <img src="/assets/images/sunny/search.svg" style={{ height: "100px" }} />
                                                    <h3 className="para-one uk-text-emphasis uk-padding-small uk-margin-remove">Search our products</h3>
                                                </div>
                                            </div>
                                            <Link to={`${process.env.PUBLIC_URL}/jewellery`}>
                                                <div className="uk-padding-small" style={{ background: "#72225721" }}>
                                                    <h4 className="para-one uk-text-emphasis uk-margin-remove">Explore our wide variety collection even more. <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                                                    </svg></h4>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                        }
                    </div> :
                    ""}

                {/* Sticky footer navbar  */}

                <StickyNavbar />
            </div>
        </div>
    )
}

export default HeaderBody
