import React, { Component } from "react";

// import '../assets/css/productInfo.css'

const ProductInfo = (props) => {

    function showDiamonds(item) {

        if (item.diamond_count !== 0) {
            return (
                <div className="acco-box">
                    <ul data-uk-accordion>
                        <li className="uk-open">
                            <h4 className="uk-accordion-title">Diamond Details</h4>
                            <div className="uk-accordion-content uk-margin-remove">
                                <table className="uk-table uk-table-responsive uk-table-divider">
                                    <tbody>
                                        <tr>
                                            <th><b>Diamond Count</b></th>
                                            <td>{item.diamond_count}</td>
                                        </tr>
                                        <tr>
                                            <th><b>Diamond Clarity</b></th>
                                            <td>{item.diamond_clarity.toUpperCase()}</td>
                                        </tr>
                                        <tr>
                                            <th><b>Diamond Color</b></th>
                                            <td>{item.diamond_color.toUpperCase()}</td>
                                        </tr>
                                        <tr>
                                            <th><b>Diamond Carat</b></th>
                                            <td>{item.diamond_carat}</td>
                                        </tr>
                                        <tr>
                                            <th><b>Diamond Cut</b></th>
                                            <td>{item.diamond_cut.toUpperCase()}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </li>
                    </ul>
                </div>
            );
        } else return null;
    }

    function showGemstone(item) {
        if (item.gemstone_price !== 0) {
            return (
                <div className="acco-box">
                    <ul data-uk-accordion>
                        <li className="uk-open">
                            <h4 className="uk-accordion-title" >Gemstone Details</h4>
                            <div className="uk-accordion-content uk-margin-remove">
                                <table className="uk-table uk-table-responsive uk-table-divider">
                                    <tbody>
                                        <tr>
                                            <th><b>Gem Type</b></th>
                                            <td>{item.gemstone_type.replaceAll("_", " ").toUpperCase()}</td>
                                        </tr>
                                        <tr>
                                            <th><b>Gem Carat</b></th>
                                            <td>{item.gemstone_carat}</td>
                                        </tr>
                                        <tr>
                                            <th><b>Gem Count</b></th>
                                            <td>{item.gemstone_count}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </li>
                    </ul>
                </div>
            );
        } else return null;
    }

    function showMetal(item) {
        return (
            <div className="acco-box">
                <ul data-uk-accordion>
                    <li className="uk-open">
                        <h4 className="uk-accordion-title">Metal Details</h4>
                        <div className="uk-accordion-content uk-margin-remove">
                            <table className="uk-table uk-table-responsive uk-table-divider">
                                <tbody>
                                    <tr>
                                        <th><b>Metal Weight</b></th>
                                        <td>{item.metal_weight} (gm)</td>
                                    </tr>
                                    <tr>
                                        <th><b>Setting Type</b></th>
                                        <td>{item.product_setting.toUpperCase()}</td>
                                    </tr>
                                    <tr>
                                        <th><b>Metal Purity</b></th>
                                        <td>{item.metal_purity.toUpperCase()}</td>
                                    </tr>
                                    <tr>
                                        <th><b>Metal Type</b></th>
                                        <td>{item.metal_type.toUpperCase()}</td>
                                    </tr>
                                    <tr>
                                        <th><b>Metal Color</b></th>
                                        <td>{item.color.toUpperCase()}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </li>
                </ul>
            </div>
        )
    }

    const { item } = props;

    if (props.item.length != 0) {
        return (
            <div className="uk-section sd-product-information " >
                <div className="uk-container uk-container-xlarge">
                    <div className="productinformation-acco">
                        <div className="uk-grid uk-child-width-1-1 uk-child-width-1-3@m uk-flex-center">

                            {showMetal(item)}

                            {showDiamonds(item)}

                            {showGemstone(item)}

                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else {
        return null
    }
}

export default ProductInfo;