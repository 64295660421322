import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getAllPdps } from "../../../actions";

// import '../assets/css/relatedProducts.css'

const RelatedProductItem = (props) => {

  const { product } = props;

  let images = [];
  let imgeSrc = [];
  let imgI = 0;
  const exts = [".png", ".jpg", ".webp", ".gif"];

  if (product.length != 0) {
    if (product.image) {
      let allImages = product.image;
      images = allImages.split(",");
      images.forEach(function (img) {
        let ext = img.substring(img.lastIndexOf("."));

        if (exts.includes(ext)) {
          imgeSrc[imgI] = img;
          imgI++;
        }
      });
    }
  }

  
  const img_fallback = (event) => {
    event.target.parentNode.childNodes.forEach(function (node) {
      if (node.nodeName == "IMG") node.src = window.no_image;
    });
  }

  return (
    <div className="item">
    <div className="uk-card-default glitz-item uk-inline-clip uk-transition-toggle uk-light p-1 pb-3 mb-3 shadow-sm border rounded" tabIndex="0" style={{ padding: '15px' }}>
        <div className="glitz-img uk-inline-clip uk-transition-toggle" tabIndex="0">
        <Link to={`${process.env.PUBLIC_URL}/${product.value}?variant_id=${product.variant_id}`}>
            <img
              className=""
              src={`${ imgeSrc[0]}?resize=270,270&compress=50`}
              onError={img_fallback}
              alt={product.name}
            />
          </Link>
          <Link to={`${process.env.PUBLIC_URL}/${product.value}?variant_id=${product.variant_id}`}>
         <div className="product_details_1241">{product.name}</div>
         <div><span className="fw-bold text-uppercase product_details_1241 text-muted small">{product.color}</span></div>
         <div className="product_details_1241 h6 mt-1 text-dark" >₹{product.offerprice} </div>         
         </Link>
        </div>
   
    </div>
</div>
  );
}

export default RelatedProductItem;