import React from 'react';
import { Link } from 'react-router-dom';

import "./promise.css"

const Promise = () => {

    return (
        <section className="promise-section py-5 bg-light my-4 ">
            <div className="promise-container">
                <div className="heading-section"style={{maxWidth:"302px"}}>
                    <h2 className="heading-title uk-text-center p-0">Sunny Diamonds Promise</h2>
                    <div className="heading-icon mt-0">
                        <span className="hr-line">&nbsp;</span>
                        <span className="diamond-icon">&nbsp;</span>
                        <span className="hr-line">&nbsp;</span>
                    </div>
                </div>
                <div className="promise-content">
                    <Link to={`${process.env.PUBLIC_URL}/why-buy-from-us/internally-flawless-diamonds/`}>
                        <img src="/assets/images/new_template/images/diamond.svg" title="" alt="" />
                        <span>Internally Flawless Diamonds</span>
                    </Link>
                    <Link to={`${process.env.PUBLIC_URL}/why-buy-from-us/100-money-back/`}>
                        <img src="/assets/images/new_template/images/money-back.svg" title="" alt="" />
                        <span>100% Money Back on Diamond Value</span>
                    </Link>
                    <Link to={`${process.env.PUBLIC_URL}/education/loose-diamonds/grading/diamond-certificate`}>
                        <img src="/assets/images/new_template/images/certification.svg" title="" alt="" />
                        <span>Certifications of Diamonds</span>
                    </Link>
                    <Link to={`${process.env.PUBLIC_URL}/why-buy-from-us/hallmarked-jewellery/`}>
                        <img src="/assets/images/new_template/images/hall-mark.svg" title="" alt="" />
                        <span>BIS Hall Mark for Jewellery</span>
                    </Link>
                    <Link to={`${process.env.PUBLIC_URL}/why-buy-from-us/brand-assured-quality/`}>
                        <img src="/assets/images/new_template/images/brand-assured.svg" title="" alt="" />
                        <span>Brand Assured Quality</span>
                    </Link>
                    <Link to={`${process.env.PUBLIC_URL}/why-buy-from-us/15day-return-policy/`}>
                        <img src="/assets/images/new_template/images/days-return.svg" title="" alt="" />
                        <span>15 Days Return Policy</span>
                    </Link>
                    <Link to={`${process.env.PUBLIC_URL}/why-buy-from-us/cash-on-delivery/`}>
                        <img src="/assets/images/new_template/images/cash-delivery.svg" title="" alt="" />
                        <span>Cash On Delivery</span>
                    </Link>
                    <Link to={`${process.env.PUBLIC_URL}/why-buy-from-us/free-shipping/`}>
                        <img src="/assets/images/new_template/images/shipping-truck_1.svg" title="" alt="" />
                        <span>Pan India Free Shipping</span>
                    </Link>
                </div>
            </div>
        </section>
    )
};

export default Promise;