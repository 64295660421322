// Theme
export const THEME_LAYOUT = "THEME_LAYOUT";

// Get Products
export const FETCH_PRODUCTS_BEGIN = "FETCH_PRODUCTS_BEGIN";
export const RECEIVE_PRODUCTS = "RECEIVE_PRODUCTS";
export const FETCH_SINGLE_PRODUCT = "FETCH_SINGLE_PRODUCT";

export const FETCH_TRENDING = "FETCH_TRENDING";
export const RECEIVE_TRENDING = "RECEIVE_TRENDING";
export const FAILED_TRENDING = "FAILED_TRENDING";

//Get pdp
export const FETCH_PDP = "FETCH_PDP";
export const RECEIVE_PDP = "RECEIVE_PDP";
export const FAILED_PDP = "FAILED_PDP";


// Get Categories
export const FETCH_CATEGORY = "FETCH_CATEGORY";
export const RECEIVE_DB_CATEGORY = "RECEIVE_DB_CATEGORY";
export const FAILED_CATEGORY = "FAILED_CATEGORY";

// FILTERS
export const FILTER_COLOR = "FILTER_COLOR";
export const FILTER_PRICE = "FILTER_PRICE";
export const SORT_BY = "SORT_BY";
export const FILTER_CAT = "FILTER_CAT";
export const FILTER_CAT_NULL = "FILTER_CAT_NULL";


export const CHANGE_CURRENCY = "CHANGE_CURRENCY";

// Cart
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const REMOVE_ALL_CART = "REMOVE_ALL_CART";
export const INCREMENT_QTY = "INCREMENT_QTY";
export const DECREMENT_QTY = "DECREMENT_QTY";

// WishList
export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
export const REMOVE_FROM_WISHLIST = "REMOVE_FROM_WISHLIST";

// Compare
export const ADD_TO_COMPARE = "ADD_TO_COMPARE";
export const REMOVE_FROM_COMPARE = "REMOVE_FROM_COMPARE";


// CheckOut Process
export const CHECKOUT_REQUEST = "CHECKOUT_REQUEST";
export const CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS";
export const CHECKOUT_FAILURE = "CHECKOUT_FAILURE";


//Checkout_Customer_TRACKING
export const POST_CUSTOMER_DETAIL = "POST_CUSTOMER_DETAIL";
export const FETCH_CUSTOMER_DETAIL = "FETCH_CUSTOMER_DETAIL";
export const RECEIVE_CUSTOMER_DETAIL = "RECEIVE_CUSTOMER_DETAIL";
export const FAILED_CUSTOMER_DETAIL = "FAILED_CUSTOMER_DETAIL";

//New Products
export const FETCH_NEW_PRODUCTS = "FETCH_NEW_PRODUCTS";
export const RECEIVE_NEW_PRODUCTS = "RECEIVE_NEW_PRODUCTS";
export const FAILED_NEW_PRODUCTS = "FAILED_NEW_PRODUCTS";

//New User Register
export const POST_NEW_CUSTOMER_DETAIL = "POST_NEW_CUSTOMER_DETAIL";
export const FETCH_NEW_CUSTOMER_DETAIL = "FETCH_NEW_CUSTOMER_DETAIL";
export const RECEIVE_NEW_CUSTOMER_DETAIL = "RECEIVE_NEW_CUSTOMER_DETAIL";
export const FAILED_NEW_CUSTOMER_DETAIL = "FAILED_NEW_CUSTOMER_DETAIL";

//Career Form
export const POST_CAREER_DETAILS = "POST_CAREER_DETAILS";
export const FETCH_CAREER_DETAILS = "FETCH_CAREER_DETAILS";
export const RECEIVE_CAREER_DETAILS = "RECEIVE_CAREER_DETAILS";
export const FAILED_CAREER_DETAILS = "FAILED_CAREER_DETAILS";

//USer Email
export const POST_USER_EMAIL = "POST_USER_EMAIL";
export const RECEIVE_USER_EMAIL = "RECEIVE_USER_EMAIL";
export const FAILED_USER_EMAIL = "FAILED_USER_EMAIL";


// Customized TRACKING
export const POST_CUSTOMIZED_DETAIL = "POST_CUSTOMIZED_DETAIL";
export const FETCH_CUSTOMIZED_DETAIL = "FETCH_CUSTOMIZED_DETAIL";
export const RECEIVE_CUSTOMIZED_DETAIL = "RECEIVE_CUSTOMIZED_DETAIL";
export const FAILED_CUSTOMIZED_DETAIL = "FAILED_CUSTOMIZED_DETAIL";

// Login
export const POST_LOGIN_DETAIL = "POST_LOGIN_DETAIL";
export const FETCH_LOGIN_DETAIL = 'FETCH_LOGIN_DETAIL';
export const RECEIVE_LOGIN_DETAIL = 'RECEIVE_LOGIN_DETAIL';
export const FAILED_LOGIN_DETAIL = 'FAILED_LOGIN_DETAIL';

// LogOUT
export const POST_LOGOUT_DETAIL = "POST_LOGOUT_DETAIL";
export const FETCH_LOGOUT_DETAIL = 'FETCH_LOGOUT_DETAIL';
export const RECEIVE_LOGOUT_DETAIL = 'RECEIVE_LOGOUT_DETAIL';
export const FAILED_LOGOUT_DETAIL = 'FAILED_LOGOUT_DETAIL';


// Subscribe TRACKING
export const POST_SUBSCRIBE = "POST_SUBSCRIBE";
export const FETCH_SUBSCRIBE = "FETCH_SUBSCRIBE";
export const RECEIVE_SUBSCRIBE = "RECEIVE_SUBSCRIBE";
export const FAILED_SUBSCRIBE = "FAILED_SUBSCRIBE";

//Order id

export const POST_ORDER_ID = "POST_ORDER_ID"
export const FETCH_ORDER_ID = "FETCH_ORDER_ID"
export const RECEIVE_ORDER_ID = "RECEIVE_ORDER_ID"
export const FAILED_ORDER_ID = "FAILED_ORDER_ID"

//cart check

export const POST_CART = "POST_CART"
export const FETCH_CART = "FETCH_CART"
export const CART_UPDATED = "CART_UPDATED"
export const CART_NOT_UPDATED = "CART_NOT_UPDATED"
export const FAILED_CART = "FAILED_CART"

//Email Check in Checkout

export const POST_EMAIL_ID = "POST_EMAIL_ID"
export const FETCH_EMAIL_ID = "FETCH_EMAIL_ID"
export const RECEIVE_EMAIL_ID = "RECEIVE_EMAIL_ID"
export const FAILED_EMAIL_ID = "FAILED_EMAIL_ID"

//Zoho Token

export const POST_ZOHO_ACCESS_TOKEN = "POST_ZOHO_ACCESS_TOKEN"
export const FETCH_ZOHO_ACCESS_TOKEN = "FETCH_ZOHO_ACCESS_TOKEN"
export const RECEIVE_ZOHO_ACCESS_TOKEN = "RECEIVE_ZOHO_ACCESS_TOKEN"
export const FAILED_ZOHO_ACCESS_TOKEN = "FAILED_ZOHO_ACCESS_TOKEN"

//Zoho Token

export const POST_ZOHO_SUBMIT = "POST_ZOHO_SUBMIT"
export const FETCH_ZOHO_SUBMIT = "FETCH_ZOHO_SUBMIT"
export const RECEIVE_ZOHO_SUBMIT = "RECEIVE_ZOHO_SUBMIT"
export const FAILED_ZOHO_SUBMIT = "FAILED_ZOHO_SUBMIT"

//Order TRACKING

export const POST_ORDER_TRACKING_SUBMIT = "POST_ORDER_TRACKING_SUBMIT"
export const RECEIVE_ORDER_TRACKING_SUBMIT = "RECEIVE_ORDER_TRACKING_SUBMIT"
export const FAILED_ORDER_TRACKING_SUBMIT = "FAILED_ORDER_TRACKING_SUBMIT"


//Order TRACKING Login

export const POST_ORDER_TRACKING_LOGIN = "POST_ORDER_TRACKING_LOGIN"
export const RECEIVE_ORDER_TRACKING_LOGIN = "RECEIVE_ORDER_TRACKING_LOGIN"
export const FAILED_ORDER_TRACKING_LOGIN = "FAILED_ORDER_TRACKING_LOGIN"


//Profile

export const POST_PROFILE = "POST_PROFILE"
export const RECEIVE_PROFILE = "RECEIVE_PROFILE"
export const FAILED_PROFILE = "FAILED_PROFILE"


//Profile eDIT

export const POST_PROFILE_EDIT = "POST_PROFILE_EDIT"
export const RECEIVE_PROFILE_EDIT = "RECEIVE_PROFILE_EDIT"
export const FAILED_PROFILE_EDIT = "FAILED_PROFILE_EDIT"

//Forgot Password

export const POST_FORGOT_PASSWORD = "POST_FORGOT_PASSWORD"
export const RECEIVE_FORGOT_PASSWORD = "RECEIVE_FORGOT_PASSWORD"
export const FAILED_FORGOT_PASSWORD = "FAILED_FORGOT_PASSWORD"

//Change Password

export const POST_CHANGE_PASSWORD = "POST_CHANGE_PASSWORD"
export const RECEIVE_CHANGE_PASSWORD = "RECEIVE_CHANGE_PASSWORD"
export const FAILED_CHANGE_PASSWORD = "FAILED_CHANGE_PASSWORD"

//Unique check

export const POST_UNIQUE = "POST_UNIQUE"
export const RECEIVE_UNIQUE = "RECEIVE_UNIQUE"
export const FAILED_UNIQUE = "FAILED_UNIQUE"

//My Orders listStyle:

export const POST_MY_ORDER = "POST_MY_ORDER"
export const RECEIVE_MY_ORDER = "RECEIVE_MY_ORDER"
export const FAILED_MY_ORDER = "FAILED_MY_ORDER"
export const FETCH_MY_ORDER = "FETCH_MY_ORDER"

//Promo check

export const POST_PROMO = "POST_PROMO"
export const RECEIVE_PROMO = "RECEIVE_PROMO"
export const FAILED_PROMO = "FAILED_PROMO"


//Order pRODUCTS DETAILS

export const POST_ORDER_PRODUCTS = "POST_ORDER_PRODUCTS"
export const RECEIVE_ORDER_PRODUCTS = "RECEIVE_ORDER_PRODUCTS"
export const FAILED_ORDER_PRODUCTS = "FAILED_ORDER_PRODUCTS"

//id

export const POST_ID = "POST_ID"
export const RECEIVE_ID = "RECEIVE_ID"
export const FAILED_ID = "FAILED_ID"

//id

export const POST_LOG = "POST_LOG"
export const SUCCESS_LOG = "SUCCESS_LOG"
export const FAILED_LOG = "FAILED_LOG"

//price update

export const WISHLIST_TO_CART = "WISHLIST_TO_CART"

//cart to server

export const SUCCESS_CART_SERVER = "SUCCESS_CART_SERVER"
export const FAILED_CART_SERVER = "FAILED_CART_SERVER"


//Request OTP

export const POST_REQUEST_OTP = "POST_REQUEST_OTP"
export const RECEIVE_REQUEST_OTP = "RECEIVE_REQUEST_OTP"
export const FAILED_REQUEST_OTP = "FAILED_REQUEST_OTP"


//Verify OTP

export const POST_VERIFY_OTP = "POST_VERIFY_OTP"
export const RECEIVE_VERIFY_OTP = "RECEIVE_VERIFY_OTP"
export const FAILED_VERIFY_OTP = "FAILED_VERIFY_OTP"

//Aboutus-CMS

export const POST_CMS = "POST_CMS"
export const RECEIVE_CMS = "RECEIVE_CMS"
export const FAILED_CMS = "FAILED_CMS"

//Timer

export const DECREMENT_TIME = "DECREMENT_TIME"
export const INITIAL_TIME = "INITIAL_TIME"

// Message for OTP

export const OTP_VERIFIED = "OTP_VERIFIED"
export const SET_OTP_DETAILS = "SET_OTP_DETAILS"
export const ERROR_MSG = "ERROR_MSG"
export const MSG_RESET = "MSG_RESET"

export const OTP_HANDLER = "OTP_HANDLER"

//giftcard
export const RECIEVE_GIFTCARD_DETAILS = "RECIEVE_GIFTCARD_DETAILS"
export const FAILED_GIFTCARD_DETAILS = "FAILED_GIFTCARD_DETAILS"

// New giftcard
export const POST_GIFT_CARD = "POST_GIFT_CARD"
export const RECIEVE_GIFTCARD_CREATE_DETAILS = "RECIEVE_GIFTCARD_CREATE_DETAILS"
export const FAILED_GIFTCARD_CREATE_DETAILS = "FAILED_GIFTCARD_CREATE_DETAILS"

// gift card BalanceGift
export const FETCH_GIFT_CARD_BALANCE = "FETCH_GIFT_CARD_BALANCE"
export const RECIEVE_GIFTCARD_BALANCE = "RECIEVE_GIFTCARD_BALANCE"
export const FAILED_GIFTCARD_BALANCE = "FAILED_GIFTCARD_BALANCE"

// Fetch gift card templates
export const FETCH_GIFT_CARD_TEMPLATE = "FETCH_GIFT_CARD_TEMPLATE"
export const RECIEVE_GIFTCARD_TEMPLATE = "RECIEVE_GIFTCARD_TEMPLATE"
export const FAILED_GIFTCARD_TEMPLATE = "FAILED_GIFTCARD_TEMPLATE"

//access otp

export const ACCESS_DECREMENT_TIME = "ACCESS_DECREMENT_TIME"
export const ACCESS_INITIAL_TIME = "ACCESS_INITIAL_TIME"

// Message for OTP with access

export const ACCESS_OTP_VERIFIED = "ACCESS_OTP_VERIFIED"
export const ACCESS_SET_OTP_DETAILS = "ACCESS_SET_OTP_DETAILS"
export const ACCESS_ERROR_MSG = "ACCESS_ERROR_MSG"
export const ACCESS_MSG_RESET = "ACCESS_MSG_RESET"

export const RECEIVE_ACCESS_REQUEST_OTP = "RECEIVE_ACCESS_REQUEST_OTP"
export const RECEIVE_ACCESS_VERIFY_OTP = "RECEIVE_ACCESS_VERIFY_OTP"
export const ACCESS_OTP_HANDLER = "ACCESS_OTP_HANDLER"

export const ACCESS_RECEIVE_REQUEST_OTP = "ACCESS_RECEIVE_REQUEST_OTP"
export const ACCESS_RECEIVE_VERIFY_OTP = "ACCESS_RECEIVE_VERIFY_OTP"


export const POST_PROMO_CODE = "POST_PROMO_CODE"
export const RECEIVE_PROMO_CODE = "RECEIVE_PROMO_CODE"
export const FAILED_PROMO_CODE = "FAILED_PROMO_CODE"

//loader
export const SET_LOADER = "SET_LOADER"

//dfe
export const POST_DFE = "POST_DFE"
export const RECEIVE_DFE = "RECEIVE_DFE"
export const FAILED_DFE = "FAILED_DFE"

// New cart server

export const UPDATE_TO_SERVER = "UPDATE_TO_SERVER"
export const READY_UPDATE_TO_SERVER = "READY_UPDATE_TO_SERVER"

//customised product category
export const POST_CATEGORY_OPTIONS = "POST_CATEGORY_OPTIONS"
export const RECIEVE_CATEGORY_PRODUCTS = "RECIEVE_CATEGORY_PRODUCTS"
export const FAILED_CATEGORY_PRODUCTS = "FAILED_CATEGORY_PRODUCTS"

//customised product category
export const FETCH_RELATED_PRODUCTS = "FETCH_RELATED_PRODUCTS"
export const RECEIVE_RELATED_PRODUCTS = "RECEIVE_RELATED_PRODUCTS"
export const FAILED_RELATED_PRODUCTS = "FAILED_RELATED_PRODUCTS"
// Career Department
export const POST_CAREER_DEPARTMENT = "POST_CAREER_DEPARTMENT"
export const RECEIVE_CAREER_DEPARTMENT = "RECEIVE_CAREER_DEPARTMENT"
export const FAILED_CAREER_DEPARTMENT = "FAILED_CAREER_DEPARTMENT"

// Career Position
export const POST_CAREER_POSITION = "POST_CAREER_POSITION"
export const RECEIVE_CAREER_POSITION = "RECEIVE_CAREER_POSITION"
export const FAILED_CAREER_POSITION = "FAILED_CAREER_POSITION"

// Home Banner 

export const POST_BANNER = "POST_BANNER"
export const RECEIVE_PAGE_BANNER = "RECEIVE_PAGE_BANNER"
export const RECEIVE_HOME_BANNER = "RECEIVE_HOME_BANNER"
export const FAILED_BANNER = "FAILED_BANNER"

// Enquiry Type
export const POST_ENQUIRY_TYPE = "POST_ENQUIRY_TYPE"
export const RECEIVE_ENQUIRY_TYPE = "RECEIVE_ENQUIRY_TYPE"
export const FAILED_ENQUIRY_TYPE = "FAILED_ENQUIRY_TYPE"

//Try@Home - Verify Stage 1
export const POST_TRYATHOMEVERIFYSTAGE_TYPE = "POST_TRYATHOMEVERIFYSTAGE1_TYPE"
export const RECEIVE_TRYATHOMEVERIFYSTAGE_TYPE = "RECEIVE_TRYATHOMEVERIFYSTAGE1_TYPE"
export const FAILED_TRYATHOMEVERIFYSTAGE_TYPE = "FAILED_TRYATHOMEVERIFYSTAGE1_TYPE"

//Try@Home - Book Confirmation Stage 2
export const POST_BOOKCONFIRMTRYATHOMESTAGE_TYPE = "POST_BOOKCONFIRMTRYATHOMESTAGE_TYPE"
export const RECEIVE_BOOKCONFIRMTRYATHOMESTAGE_TYPE = "RECEIVE_BOOKCONFIRMTRYATHOMESTAGE_TYPE"
export const FAILED_BOOKCONFIRMTRYATHOMESTAGE_TYPE = "FAILED_BOOKCONFIRMTRYATHOMESTAGE_TYPE"