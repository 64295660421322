import axios from "axios";

const SDClient = {
    apiKey: "6fc8d34930b49ce9e28d4487632994cdaf170ef406a6f17742c17f7e0255e706",
    counter: 1,
    digest: async (message) => {
        return Array.prototype.map
            .call(
                new Uint8Array(
                    await crypto.subtle.digest(
                        "SHA-256",
                        new TextEncoder().encode(message)
                    )
                ),
                (x) => ("0" + x.toString(16)).slice(-2)
            )
            .join("");
    },
    encodeMessage: function (message) {
        return message;
    },
    endpoint: function (action, data = { _action: null }) {
        let service = this;
        return new Promise(function (onSuccess, onError) {
            try {
                let message = {
                    message: service.encodeMessage(data),
                    session: localStorage.getItem("sd_session"),
                    // checksum: hash,
                    apiKey: service.apiKey,
                    counter: service.counter,
                };
                axios
                    // .post(`https://sd.sunnydiamonds.com/api/api/refine/${action}`, message, {})
                    .post(global.postUrl + `refine/${action}`, message, {})
                    .then(
                        (response) => {
                            if (response.status != 200) {
                                return;
                            }
                            data = response.data;
                            if (data.request && data.session) {
                                localStorage.setItem("sd_session", data.session);
                                onSuccess(data);
                            } else {
                                onError({
                                    request: false,
                                    reason: "REQUEST_FAILED",
                                    data: data,
                                });
                            }
                        },
                        (error) => {
                            if (error.response) {
                                if (error.response.status >= 500)
                                    onError({
                                        request: false,
                                        reason: "SERVER_ERROR",
                                    });
                                else if (error.response.status >= 300)
                                    onError({
                                        request: false,
                                        reason: "API_ERROR",
                                    });
                                else
                                    onError({
                                        request: false,
                                        reason: "NETWORK_ERROR",
                                    });
                            }
                            else
                                onError({
                                    request: false,
                                    reason: "REQUEST_FAILED",
                                });
                        }
                    );
                service.counter++;
            } catch (e) {
                onError({
                    request: false,
                    reason: "CLIENT_ERROR",
                    exception: e,
                });
            }
        });
    }
}

export default SDClient
