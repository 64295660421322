import React, { Component } from 'react';

// Custom Components
import Header from './new_header'
import { MainLoader } from '../loader';
import Footer from './new_footer';

import CookieConsent from "../components/new_template_common/cookie_consents/index";

// ThemeSettings
import ThemeSettings from "./common/theme-settings"
import { connect } from 'react-redux';

const App = (props) => {

    function showLoader() {
        if (props.loader) return (<MainLoader />)
        else return null
    }

    return (
        <div>
            {/* {showLoader()} */}
            <Header location={props.location} />
            {props.children}
            <Footer />

            <CookieConsent />
            {/* <ToastContainer autoClose={1000} className="toast-container" /> */}
            <ThemeSettings />
        </div>
    );
}

const mapStateToProps = (state) => ({
    loader: state.data.loader
})

export default connect(mapStateToProps)(App);