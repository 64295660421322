import React, { useEffect, useState, useRef } from "react";

import { connect } from 'react-redux';

import SDClient from "../../../sd_core/client";

import { timerAccessAction, errorAccessMsgAction, getReqAccessOtp, getAccessVerifyOtp } from "../../../actions"

import store from "../../../store"

import Loader from "../../common/spinner"

import SimpleReactValidator from "simple-react-validator";


const RedeemGiftCard = (props) => {

    let timer = 0
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const validator = useRef(new SimpleReactValidator())

    const [applyLoading, setApplyLoading] = useState(false);

    const [validationTrace, setValidationTrace] = useState({});

    const [cardNumber, setCardNumber] = useState("")


    const [displayTime, setDisplayTime] = useState("00:00")

    const [recpNumber, setRecpNumber] = useState("")

    const [otpNumber, setOtpNumber] = useState("");


    const handleChange = (event) => {
        event.persist()
        setCardNumber(event.target.value)
    }

    const handleValidity = () => {
        if (!validator.current.allValid()) {
            validator.current.showMessages();
            forceUpdate()
        }
    }

    const clearGiftCard = () => {
        setApplyLoading(true);
        SDClient.endpoint("virtual_order_clear_gift_card").then(
            (data) => {
                setValidationTrace({});
                setApplyLoading(false);
                props.updateVO();
            },
            // console.log
        )
    }


    const initateTransferGiftCard = (cardNumber) => {
        setApplyLoading(true);
        SDClient.endpoint("virtual_order_request_initate_transfer", { 'code': cardNumber }).then(
            (data) => {
                if (data.message.action) {
                    setValidationTrace({
                        APPLY_OK: true
                    });
                    props.updateVO();
                } else {
                    if (data.message.reason == "MESSAGE_VALIDATION_ERROR") {
                        setValidationTrace(data.message.trace);
                    } else if (data.message.reason == "INITATE_REDEEM_FAILED") {
                        setValidationTrace({ INITATE_REDEEM_FAILED: true });
                    }
                    else if (data.message.reason == "CARD_NOT_FOUND") {
                        setValidationTrace({ CARD_NOT_FOUND: true });
                    }
                    else if (data.message.reason == "SOMETHING_ERROR") {
                        setValidationTrace({ SOMETHING_ERROR: true });
                    }
                    else if (data.message.reason == "WAIT_TIMEOUT") {
                        setValidationTrace({ WAIT_TIMEOUT: true });
                    }else if (data.message.reason == "CARD_LOCKED") {
                        setValidationTrace({ CARD_LOCKED: true });
                    }
                }
                setApplyLoading(false);
            },
            // console.log
        )
    }


    const verifyTransferGiftCard = () => {
        setApplyLoading(true);
        SDClient.endpoint("virtual_order_verify_transfer_gift_card", { 'token': props.VOData.order.applied_gift_card.virtual_order_redeem_secret_key, 'otp': otpNumber }).then(
            (data) => {
                if (data.message.action) {
                    setValidationTrace({
                        APPLY_OK: true
                    });
                    props.updateVO();
                } else {
                    if (data.message.reason == "MESSAGE_VALIDATION_ERROR") {
                        setValidationTrace(data.message.trace);
                    } else if (data.message.reason == "VERIFY_PROCESS_ERROR") {
                        setValidationTrace({ VERIFY_PROCESS_ERROR: true });
                    } else if (data.message.reason == "OTP_MISSMATCH") {
                        setValidationTrace({ OTP_MISSMATCH: true });
                    }
                    else if (data.message.reason == "OTP_VERIFICATION_FAILED") {
                        setValidationTrace({ OTP_VERIFICATION_FAILED: true });
                    }
                }
                setApplyLoading(false);
            },
            // console.log
        )
    }


    const loadPromocodes = () => {
        if (!props.syncing) {
            return appliedGiftCard();
        } else {
            return (
                <>
                    <hr></hr>
                    <div className="py-2">
                        <p className="placeholder col-9"></p>
                        <p className="placeholder col-8"></p>
                    </div>
                </>
            )
        }
    }


    const handleChangeOtp = (event) => {
        event.persist()
        setOtpNumber(event.target.value);
    }


    const initateGiftCard = () => {
        return (
            <div className="mb-3">
                <div className="para-one">Gift Card Number</div>
                <div className="d-flex flex-row">
                    <input
                        type="text"
                        name="promocodes"
                        className="uk-input text-uppercase"
                        value={cardNumber}
                        onChange={handleChange}
                    />
                    <button
                        type="button"
                        className="uk-button uk-button-secondary add-cart uk-align-center uk-margin-remove uk-padding-remove uk-width-1-3"
                        onClick={() => initateTransferGiftCard(cardNumber)}
                    >
                        Apply
                    </button>
                </div>
                {validationTrace.CARD_NOT_FOUND ? (<h6 className="uk-text-danger uk-margin-small"> The card gift you applied is not a valid one, please check again. </h6>) : null}
                {validationTrace.SOMETHING_ERROR ? (<h6 className="uk-text-danger uk-margin-small"> Sorry something is wrong on applying the gift card. Please try after some time. </h6>) : null}
                {validationTrace.WAIT_TIMEOUT ? (<h6 className="uk-text-danger uk-margin-small"> Oops! Please re-try again after 60 seconds.</h6>) : null}
                {validationTrace.CARD_LOCKED ? (<h6 className="uk-text-danger uk-margin-small"> This Gift Card cannot be used, the card is already locked against another order.</h6>) : null}
            </div>
        )
    }


    const confirmTransferOtp = () => {
        return (
            <>
                <div className="col-12 mx-md-auto mx-lg-0">
                    <h6 className="product-title">OTP has been sent to <strong>xxxxxx{recpNumber}</strong></h6>
                    <div className="d-flex flex-md-row justify-content-between px-4 px-md-0">
                        <input
                            type="tel"
                            className="form-control pin text-center"
                            placeholder="Enter OTP"
                            minLength="4"
                            maxLength="4"
                            name="access_secret"
                            value={otpNumber}
                            onChange={handleChangeOtp}
                        />
                        <button
                            className="btn btn-promo"
                            onClick={() => verifyTransferGiftCard()}
                        >
                            Confirm
                        </button>
                    </div>
                    {validator.current.message(
                        "otp",
                        otpNumber,
                        "required|min:4|max:4"
                    )}
                    <div className="d-flex justify-content-between my-2">
                        {/* {resendFlag == "resend" ? */}
                        <>
                            <a href="javascript:void(0)" onClick={() => initateTransferGiftCard(props.VOData.order.applied_gift_card.virtual_order_redeem_name)}>
                                <u>Resend OTP</u>
                            </a>
                            {/* <a href="javascript:void(0)" onClick={() => resetHandler()}><u>Try another Card</u></a> */}
                        </>
                        {/* : */}
                        {/* <>
                                <a>
                                    <u className="text-muted pr-2">Resend OTP</u>
                                    <b>{displayTime}</b>
                                </a>
                                <a href="javascript:void(0)" onClick={() => resetHandler()}>
                                    <u>Try another Card</u>
                                </a>
                            </> */}
                    </div>
                    {validationTrace.WAIT_TIMEOUT ? (<h6 className="uk-text-danger uk-margin-small">Please re-try again after 60 seconds.</h6>) : null}
                    {validationTrace.OTP_MISSMATCH ? (<h6 className="uk-text-danger uk-margin-small">The give OTP is not matching with our data. Please give correct OTP to complete the verification process.</h6>) : null}
                    {validationTrace.OTP_VERIFICATION_FAILED ? (<h6 className="uk-text-danger uk-margin-small">Sorry there is something wrong on verifying this OTP.Please request to resend and complete the verification process.</h6>) : null}
                </div>
                <div>
                    {
                        props.timer.msgFlag.flag && cardNumber && <div className="col-12 mx-md-auto mx-lg-0">
                            <div className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex align-items-center my-2 p-2 mt-3 alert-" + props.timer.msgFlag.color} role="alert">
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d={props.timer.msgFlag.svg} clipRule="evenodd" />
                                </svg>
                                <div className="px-1">
                                    {props.timer.msgFlag.msg} {props.timer.msgFlag.flag === "wait_time" ? displayTime : ""}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </>
        )
    }


    const giftCardTransferSuccess = () => {
        return (
            <div>
                <h6 className="uk-margin-small text-uppercase text-info">
                    <small>Your Gift Card
                        &nbsp;<span className="text-success font-weight-bold">{props.VOData.order.applied_gift_card.virtual_order_redeem_name}</span>
                        &nbsp;is locked successfully against this order.</small>
                </h6>
            </div>
        )
    }

    const appliedGiftCard = () => {
        if (props.VOData.order.applied_gift_card) {
            const promoline = () => {
                if (props.VOData.order.applied_gift_card.virtual_order_redeem_type == "purchased") {
                    return (
                        <div>
                            <h3 class="text-uppercase fw-bold my-2">PURCHASED GIFT CARD</h3>
                            <h6 className="text-dark text-uppercase small">
                                <small>A redeem amount of <span className="text-success font-weight-bold">₹{props.VOData.order.applied_gift_card.virtual_order_redeem_amount}</span> will be applied on this orders .</small>
                            </h6>
                            <div class="alert alert-primary mb-0 small" role="alert">
                                <span uk-icon="icon: info; ratio: 0.7" className="uk-icon" />&nbsp;You can only redeem the gift card once..
                            </div>
                        </div>
                    )
                }
                if (props.VOData.order.applied_gift_card.virtual_order_redeem_type == "promotion") {
                    return (
                        <div>
                            <h3 class="text-uppercase fw-bolder my-2">PROMOTIONAL GIFT CARD</h3>
                            <h6 className="text-dark text-uppercase small">
                                <small>A redeem amount of <span className="text-success font-weight-bold">₹{props.VOData.order.applied_gift_card.virtual_order_redeem_amount}</span> will be applied on this orders.</small>
                            </h6>
                            <div class="alert alert-primary mb-0" role="alert">
                                <span uk-icon="icon: info; ratio: 0.7" className="uk-icon" />&nbsp;You can only redeem the gift card once..
                            </div>
                        </div>
                    )
                }
            }
            return (
                <>
                    <hr></hr>
                    <div className="d-flex justify-content-between align-items-center">
                        <p className="text-secondary text-uppercase mb-3">Applied Gift Card</p>
                        <div className="mt-1">
                            {props.syncing ? (<Loader />) : ""}
                        </div>
                    </div>
                    <div className="mb-3 d-flex justify-content-between">
                        <div className="m-0">
                            {props.VOData.order.applied_gift_card.virtual_order_redeem_name}
                            <span uk-icon="icon: trash; ratio: 0.7" className="uk-icon uk-margin-small-left" onClick={clearGiftCard} style={{ color: "red" }} />
                            <br />
                            {promoline()}
                        </div>
                    </div>
                </>
            )
        }
    }

    const stage = () => {
        if (props.VOData.order && props.VOData.order.applied_gift_card) {
            if (props.VOData.order.applied_gift_card.virtual_order_redeem_status === "transfer_requested") {
                return (
                    confirmTransferOtp()
                );
            }
            else if (props.VOData.order.applied_gift_card.virtual_order_redeem_status === "transfer_verified") {
                return (
                    giftCardTransferSuccess()
                );
            }
        } else {
            return (
                initateGiftCard()
            );
        }
    }


    return (
        <div className="bg-white border uk-padding-small uk-margin-bottom">
            <div className="d-flex justify-content-between">
                <p className="uk-text-lead text-uppercase para-one uk-margin-small-bottom uk-text-bold"><b>Gift Card Redeem</b></p>
                <div className="mt-1">
                    {applyLoading ? (<Loader />) : ""}
                </div>
            </div>
            {stage()}
            {loadPromocodes()}

        </div>
    )
}


const mapStateToProps = state => ({
    otp_verify: state.otp.access_otp_verify,
    symbol: state.data.symbol,
    timer: state.otp
});

export default connect(mapStateToProps, {
    getReqAccessOtp,
    getAccessVerifyOtp,
    timerAccessAction,
    errorAccessMsgAction
})(RedeemGiftCard)