import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import RelatedProductItem from './related-product-item'

import { getRelatedProducts } from "../../../actions";

const RelatedProducts = (props) => {

  // const [sortedItems,setSortedItems] = useState([]);

  useEffect(() => {
    props.getRelatedProducts(props.sku);

  }, []);

  const { items } = props;

  // useEffect(()=>{
  //   setSortedItems(items.reduce((c,v)=>{
  //     let ci = c.filter(i=>i.sku==v.sku);
  //     if(ci.length==0){
  //       return [...c,v];
  //     }else return c;
  //   },[]))
  // },[items]);

  if (items.length != 0) {
    // console.log(items);
    return (
      <div className="uk-section sd-completelook uk-padding-remove">
        <div className="uk-container uk-container-xlarge">
          <div className="uk-width-1-3@m uk-text-left uk-padding-small mb-2">
            <h2 className="px-2 py-2">Complete The Look</h2>
            <hr className="uk-divider-icon m-0" />
          </div>
          <div className="uk-position-relative uk-visible-toggle uk-light" tabIndex="-1" data-uk-slider="autoplay: true; autoplay-interval:3000;">
            <ul className="listing-box-main uk-slider-items uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-4@l uk-child-width-1-5@xl uk-grid">
              {items.map((product, index) => {
                if (product.sku != null)
                  return (
                    <li key={index}>
                      <RelatedProductItem product={product} />
                    </li>)
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  } else return null;
}

const mapStateToProps = (state) => ({
  items: state.data.related_products
});

export default connect(mapStateToProps, { getRelatedProducts })(RelatedProducts);