// New method for checkout

import React, { useEffect, useRef, useState } from "react";

import { connect } from "react-redux";
import { toast } from "react-toastify";

// chckout components

import UserDetails from "./user-details";
import AddressDetails from "./shipping-details";
import Coupons from "./coupons";
import PaymentMethod from "./payments-methods";
import RedeemGiftCard from "./redeem-giftCardNew";

import "./shimmer.scss"

// core components

import store from "../../../store";
import SDClient from "../../../sd_core/client";
import { removeFromCart, actProfile } from "../../../actions";

// feature components

import Seo from "../../layouts/common/seo";
import Breadcrumb from "../../new_template_common/breadcrumb/BreadCrumb";

import VOCartPreview from "./VOPreview";
import BillingAddressDetails from "./billing-details";
import Loader from "../../common/spinner"
import useRazorpay from "react-razorpay";

import "./checkout.css"
import { Link } from "react-router-dom";

import { v4 as uuidv4 } from 'uuid';


window.SD = SDClient

const CheckOut1 = (props) => {

  const _reCaptchaRef = useRef(null)

  const [userDetails, setUserDetails] = useState({})
  const [shippingDetails, setshippingDetails] = useState({
    'address_country': "India",
    'address_zipcode': "",
    'address_state': "",
    'address_city': "",
    'address_address': "",
  })

  const [globalLoading, setGobalLoading] = useState({
    userDebounce: false,
    shippingDebounce: false,
    billingDebounce: false,
  })

  const [globalDebounce, setGlobalDebounce] = useState(false);

  const [message, setMessage] = useState({ description: "" })
  const [mobIndex, setMobIndex] = useState(1)

  const [loadingVO, setLoadingVO] = React.useState(true);
  const [VOData, setVOData] = React.useState({});

  const [BillingShippingAddressSame, setBillingShippingAddressSame] = React.useState(true);
  const BillingAddressBlockRef = useRef(null);
  const ShippingAddressBlockRef = useRef(null);
  const UserDetailsBlockRef = useRef(null);

  const [inTransition, setInTransition] = useState(false);
  const [SelectedPaymentConfirmor, setSelectedPaymentConfirmor] = useState(null);

  const Razorpay = useRazorpay();
  const [Order, setOrder] = useState(null);
  const [OrderPayment, setOrderPayment] = useState(null);
  const [OrderResponse, setOrderResponse] = useState(null);
  const [remoteValidationTrace, setRemoteValidationTrace] = useState({
    user: {},
    shipping_address: {},
  });

  const syncCart = (callback) => {
    let cart = []
    props.cartItems.map((item, i) => {
      cart.push({
        sku: item.sku,
        quantity: item.qty,
        variant: item.selectedVariant.id
      })
    })
    store.dispatch({
      type: "READY_UPDATE_TO_SERVER",
    });
    SDClient.endpoint('cart_update', {
      cart: cart
    }).then((data) => {
      if (data.message.action) {
        store.dispatch({
          type: "UPDATE_TO_SERVER",
          payload: { cart: data.message.cart }
        });
        callback();
      } else {
        // props.history.push("/cart")
        toast.error("Something Went Wrong, please try again.");
        return
      }
    }, error => {
      // console.log(error)
      if (error.reason == "CLIENT_ERROR") {
        toast.warn("Oops! Unable to checkout, please make sure to enable cookies or try clearing your browser cache.", { autoClose: 5000, hideProgressBar: true, })
        props.history.push("/cart")
      }
    });
  }

  const updateVO = (callback = null) => {

    _updateVO(callback);
  }

  const _updateVO = (callback = null) => {
    setLoadingVO(true);
    SDClient.endpoint("virtual_order").then(
      response => {
        setVOData(response.message)
        setLoadingVO(false);
        if (callback) callback(response);
      },
      error => {
        if (callback) callback(error);
      }
    )
  }

  const gaInitiateCheckout = () => {
    /*GA code for initate checkout*/
    const products = []
    for (let i = 0; i < props.cartItems.length; i++) {
      products.push({
        'name': props.cartItems[i].name,
        'id': props.cartItems[i].sku,
        'price': props.cartItems[i].selectedVariant && props.cartItems[i].selectedVariant.offerprice,
        'brand': 'Sunny Diamonds',
        'category': props.cartItems[i].value,
        'variants': props.cartItems[i].selectedVariant && props.cartItems[i].selectedVariant.color,
        'quantity': props.cartItems[i].qty
      });
    }
    window.dataLayer = window.dataLayer || [];
    try {
      const eventLabel = `InitiateCheckout_${uuidv4()}`;
      window.dataLayer.push({
        'event': 'InitiateCheckout',
        'ecommerce': {
          'currencyCode': 'INR',
          'impressions': products
        },
        eventLabel: eventLabel,
      });
    }
    catch (err) {
      if (window.dataLayer.filter(function (obj) {
        return obj.errorMsg === err.message;
      }).length == 0) {
        window.dataLayer.push({
          'event': 'variable error',
          'errorMsg': err.message
        });
      }
    }
  }

  // globalLoading useEffect 
  useEffect(() => {
    if (!globalLoading.userDebounce && !globalLoading.shippingDebounce && !globalLoading.billingDebounce) {
      setGlobalDebounce(false)
    } else
      setGlobalDebounce(true)
  }, [globalLoading])

  // FIXME : Conditional hooks are causing :
  useEffect(() => {
    if (OrderResponse) return;
    props.actProfile()
    gaInitiateCheckout();

    if (props.cartItems.length) {
      syncCart(updateVO);
    }
    else {
      toast.warn("Oops! Cannot checkout an empty cart.", { autoClose: 5000, hideProgressBar: true, })
      props.history.push("/cart")
    }
  }, [props.cartItems]);

  useEffect(() => {
    if (VOData.order) {
      if (VOData.order.billing_address && VOData.order.shipping_address) {
        // setBillingShippingAddressSame(checkAddressEquality(VOData.order.billing_address, VOData.order.shipping_address));
        // setBillingShippingAddressSame(true);
      }
    }
  }, [VOData]);

  useEffect(() => {
    if (BillingAddressBlockRef.current)
      BillingAddressBlockRef.current.scrollIntoView({ block: "end", inline: "nearest", behavior: 'smooth' });
  }, [BillingShippingAddressSame])


  const orderPlace = (response) => {
    const user_details = response.message.order.user_details
    const shipping_address = response.message.order.shipping_address
    const payment_method = response.message.order.applied_payment_method
    if (user_details == null)
      SDClient.endpoint("virtual_order_set_user_details", { ...userDetails }).then(
        (data) => {
          if (data.message.action) {
            setRemoteValidationTrace({ ...remoteValidationTrace, user: {}, });
          } else {
            if (data.message.reason == "MESSAGE_VALIDATION_ERROR") {
              toast.warn("Oops! Please complete your user details and proceed to checkout.", { autoClose: 5000, hideProgressBar: true, })
              setRemoteValidationTrace({ ...remoteValidationTrace, user: data.message.trace });
            }
          }
        },
      )
    else if (shipping_address == null)
      SDClient.endpoint("virtual_order_set_shipping_address", { ...shippingDetails }).then(
        (data) => {
          if (data.message.action) {
            setRemoteValidationTrace({ ...remoteValidationTrace, shipping_address: {}, });
          } else {
            if (data.message.reason == "MESSAGE_VALIDATION_ERROR") {
              toast.warn("Oops! Please complete your shipping details and proceed to checkout.", { autoClose: 5000, hideProgressBar: true, })
              setRemoteValidationTrace({ ...remoteValidationTrace, shipping_address: data.message.trace });
            }
          }
        },
      )
    else if (payment_method == null)
      toast.warn("Oops! No payment method selected, please choose a payment method to continue.", { autoClose: 5000, hideProgressBar: true, });
    else if (user_details != null && shipping_address != null && payment_method != null) {
      onRecaptcha()
    }
  }


  const generate_order = () => {
    console.log("hii");
    setOrder(null);
    setInTransition(true);
    SDClient.endpoint('order_generate', {
      user_id: props.profile_data ? props.profile_data.user_id : sessionStorage.getItem("user_id")
    }).then((data) => {
      setInTransition(false);
      if (data.message.action) {
        let order_data = data.message.order;
        order_data.items = data.message.items;
        let payment_initiation = data.message.payment_data;
        order_data.payment_data = payment_initiation;
        setOrder(order_data);
        setOrderPayment(payment_initiation);
        setSelectedPaymentConfirmor(order_data.payment_mode)

        /*GA calling checkout generation*/

        const products = []
        for (let i = 0; i < order_data.items.length; i++) {
          products.push({
            'name': order_data.items[i].name,
            'id': order_data.items[i].sku,
            'price': order_data.items[i].total,
            'brand': 'Sunny Diamonds',
            'category': order_data.items[i].sku,
            'variant': order_data.items[i].color,
            'quantity': order_data.items[i].qty,
          });
        }
        window.dataLayer = window.dataLayer || [];
        try {
          window.dataLayer.push({
            'event': 'checkout',
            'ecommerce': {
              'checkout': {
                'actionField': { 'step': 4, 'option': order_data.payment_mode },
                'products': [products]
              }
            },
          });
        }
        catch (err) {
          if (window.dataLayer.filter(function (obj) {
            return obj.errorMsg === err.message;
          }).length == 0) {
            window.dataLayer.push({
              'event': 'variable error',
              'errorMsg': err.message
            });
          }
        }

      } else {
        if (data.message.reason == "SYSTEM_RUNTIME_EXCEPTION") {
          if (data.message.trace.error == "INCOMPLETE_ORDER_USER") {
            toast.warn("Oops! Please complete your user details and proceed to checkout.", { autoClose: 5000, hideProgressBar: true, })
            setRemoteValidationTrace({
              ...remoteValidationTrace,
              user: {
                first_name: true,
                last_name: true,
                email: true,
                phone: true,
              }
            })
            UserDetailsBlockRef.current.scrollIntoView({ block: "end", inline: "nearest", behavior: 'smooth' });
          } else if (data.message.trace.error == "INCOMPLETE_ORDER_SHIPPING_ADDRESS") {
            toast.warn("Oops! Please add your shipping addresss and proceed to checkout.", { autoClose: 5000, hideProgressBar: true, })
            setRemoteValidationTrace({
              ...remoteValidationTrace,
              shipping_address: {
                'address_country': true,
                'address_zipcode': true,
                'address_state': true,
                'address_city': true,
                'address_address': true,
              }
            })
            ShippingAddressBlockRef.current.scrollIntoView({ block: "end", inline: "nearest", behavior: 'smooth' });
          } else if (data.message.trace.error == "INCOMPLETE_ORDER_CART") {
            toast.error("Oops! Your cart seems empty, cannot checkout with an empty cart.", { autoClose: 5000, hideProgressBar: true, });
          } else if (data.message.trace.error == "INCOMPLETE_ORDER_PAYMENT") {
            toast.warn("Oops! No payment method selected, please choose a payment method to continue.", { autoClose: 5000, hideProgressBar: true, });
          } else if (data.message.reason == "GIFT_CARD_ALREADY_LOCK_ERROR") {
            toast.warn("Oops! This Gift Card cannot be used, the card is already locked against another order.", { autoClose: 5000, hideProgressBar: true, });
          } else if (data.message.reason == "GIFT_CARD_LOCK_ERROR") {
            toast.warn("Oops! Something Wrong on locking the gift card against this order. Please try again or contact customer support", { autoClose: 5000, hideProgressBar: true, });
          }
          else {
            toast.error("Oops! Something went wrong, please try again after a while.", { autoClose: 5000, hideProgressBar: true, });
          }
        } else {
          toast.error("Oops! Something went wrong, please make sure to enable cookies or try again after clearing your browser cache", { autoClose: 5000, hideProgressBar: true, });
        }
      }
    });
  };

  const showBillingAddress = () => {
    return (
      <BillingAddressDetails
        type="shipping"
        // handleChange={handleTextChange}
        // address={shippingAddress}
        // sameAddress={sameAddress}
        VOData={VOData}
        updateVO={updateVO}
        profile={props.profile_data}
        sameAddress={BillingShippingAddressSame}
        setSameAddress={setBillingShippingAddressSame}
        globalLoading={globalLoading}
        setGobalLoading={setGobalLoading}
      />
    );
  }

  const TransactLoading = () => {
    if (inTransition) {
      return (
        <div className="blurred-background uk-flex uk-flex-center uk-flex-middle uk-padding">
          <div className="bg-white shadow uk-padding rounded d-flex flex-column align-items-center">
            <Loader multiplier={2} />
            <p className="para-one uk-padding-small">
              Please wait as we are processing your order, this will only take a while.
            </p>
          </div>
        </div>
      )
    }
  }

  const clear_order = () => {
    setSelectedPaymentConfirmor(null)
  }

  const OrderStatus = () => {
    if (OrderResponse)
      return (
        <div className="uk-flex uk-flex-center">
          <div className="bg-white shadow uk-padding d-flex flex-column align-items-center w-100">
            <div className="success-text d-flex flex-column align-items-center">
              <i className="fa fa-check-circle" aria-hidden="true" style={{ fontSize: "5rem" }}></i>
              <h2 className="heading-title title-normal uk-margin">Thank You for the purchase !</h2>
              <p className="uk-text-center uk-margin-remove uk-padding-small">Confirmation mail has been sent to your mail id</p>
              <p className="uk-text-center uk-margin-remove uk-padding-small uk-padding-remove-vertical ">We hope you enjoy your purchase</p>
              <Link
                data-lng="en"
                className="uk-button uk-button-primary buy-now uk-align-center"
                rel="noopener noreferrer"
                to={`${process.env.PUBLIC_URL}/jewellery`}
              >
                <div>continue shopping</div>
              </Link>
            </div>
          </div>
        </div>
      )
  }

  const confirm_order = (confirmation, callback = (data) => { }) => {
    setInTransition(true);
    setSelectedPaymentConfirmor(null)
    SDClient.endpoint('order_confirm', {
      confirmation: confirmation,
      order_id: Order.order_id,
      vo_id: Order.vo_id,
    }).then((data) => {
      setInTransition(false);
      if (data.message.action) {

        /* GA calling transaction*/

        const products = []
        for (let i = 0; i < Order.items.length; i++) {
          products.push({
            'name': Order.items[i].name,
            'id': Order.items[i].sku,
            'price': Order.items[i].total,
            'brand': 'Sunny Diamonds',
            'category': Order.items[i].sku,
            'variant': Order.items[i].color,
            'quantity': Order.items[i].qty,
            'coupon': ''
          });
        }
        window.dataLayer = window.dataLayer || [];
        try {
          window.dataLayer.push({
            'event': 'transaction',
            'ecommerce': {
              'purchase': {
                'actionField': {
                  'id': data.message.order.order_id,
                  'affiliation': 'Sunny Diamonds Online Store',
                  'revenue': data.message.order.amount,
                  'tax': '0.00',
                  'shipping': '0.00',
                  'discount': data.message.order.discount
                },
                'products': products,
              }
            }
          });
        }
        catch (err) {
          if (window.dataLayer.filter(function (obj) {
            return obj.errorMsg === err.message;
          }).length == 0) {
            window.dataLayer.push({
              'event': 'variable error',
              'errorMsg': err.message
            });
          }
        }

        setOrderResponse(data.message);
        setTimeout(() => { window.scrollTo({ top: 0, behavior: 'smooth' }); }, 500)
        store.dispatch({ type: "REMOVE_ALL_CART" });
      } else {
        callback(data.message);
      }
    });
  }

  const CODConfirm = () => {
    const [countdown, setCountdown] = useState(1);
    const [OTP, setOPT] = useState("");
    const [OTPError, setOTPError] = useState(null);
    useEffect(() => {
      setCountdown(OrderPayment.otp.server_resend_step)
      const timer = setInterval(() => {
        setCountdown(c => c == 0 ? 0 : c - 1)
      }, OrderPayment.otp.server_resend_rate);
      return () => {
        return clearInterval(timer);
      }
    }, [])

    if (Order && OrderPayment) {
      return (
        <div className="blurred-background uk-flex uk-flex-center uk-padding">
          <div className="bg-white shadow uk-padding uk-width-1-4@xl uk-width-2-4@l uk-width-3-5@m otp_popup">
            <div className="uk-flex uk-flex-between">
              <h4 className="text-uppercase uk-margin-remove">Verify your mobile number</h4>
              <svg
                onClick={() => { clear_order() }}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="mx-1 text-secondary cursor-pointer"
                viewBox="0 0 16 16">
                <path
                  d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z" />
              </svg>
            </div>
            <div className="uk-margin-top">
              <hr />
              <div className="d-flex flex-column align-items-center">
                <svg
                  style={{ width: "6rem", height: "6rem" }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="text-sd"
                  viewBox="0 0 16 16">
                  <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                  <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                </svg>
                {OrderPayment.otp.success && (
                  <p className="uk-margin-remove uk-padding-small uk-padding-remove-horizontal">
                    Please verify yourself using the One Time Password that has been sent to <b>+91{Order.phone}</b> and proceed with your order.
                  </p>
                )}
              </div>
              {!OrderPayment.otp.success && (
                <div className="uk-alert-warning" uk-alert>
                  <p className="uk-padding-small uk-margin-remove"><b>Oops!</b> Please wait {countdown} seconds before requesting a new one time password to number</p>
                </div>
              )}
              {OTPError && (
                <div className="alert alert-warning" role="alert">
                  <b>Oops!</b> {OTPError}
                </div>
              )}

              <div className="uk-margin-small uk-margin-remove-horizontal">
                <div className="uk-form-label para-one uk-text-center">ONE TIME PASSWORD</div>
                <div className="uk-flex uk-flex-center">
                  <input
                    type="tel"
                    style={{ letterSpacing: "1.5rem" }}
                    className="uk-input uk-text-center"
                    value={OTP}
                    onChange={(e) => { setOPT(e.target.value) }}
                  />
                </div>
              </div>
              {countdown == 0 ?
                <a onClick={generate_order}>Request another OTP</a> :
                <div>Resend a new otp in {countdown} Seconds</div>
              }
              <button
                type="button"
                className="uk-button uk-button-primary buy-now uk-align-center w-100"
                onClick={(e) => {
                  // console.log("OTP is ", OTP);
                  if (OTP.length == 4) {
                    setOTPError(false);
                    confirm_order({ otp: OTP }, () => { clear_order(); toast.error("Order failed with incorrect one time password, please try again.", { autoClose: 5000, hideProgressBar: true, }); });
                  }
                  else setOTPError("Please enter a valid 4 digit one time password.");
                }}>
                CONFIRM and Place Order
              </button>
            </div>
          </div>
        </div>
      )
    }
  }

  const Razorpay_Confirm = () => {
    const options = {
      key: global.rzpKey,
      amount: Order.amount * 100,
      currency: Order.currency,
      order_id: Order.order_id,
      name: "Sunny Diamonds",
      description: "Sunny Diamonds Payment",
      image: "https://sunnydiamonds.com/assets/images/sunny/logo/razorpay/logo.webp",
      redirect: false,
      handler: function (response) {
        clear_order();
        confirm_order(response, (data) => {
          toast.error("Payment signature verification failed, please try again.");
        })
      },
      modal: {
        confirm_close: true,
        ondismiss: function () { clear_order(); updateVO() }
      },
      prefill: {
        name: Order.first_name + " " + Order.last_name,
        email: Order.email,
        contact: Order.phone
      },
      notes: { address: "Razorpay Corporate Office" },
      theme: { color: "#722257" },
      timeout: 300
    };
    const rzpay = new Razorpay(options);
    rzpay.open();
  }

  const Payment_Confirmor = () => {
    if (SelectedPaymentConfirmor == "payment_on_delivery") {
      if (Order)
        return (<CODConfirm />);
      else
        return null;
    } else if (SelectedPaymentConfirmor == "online_payment_razorpay") {
      if (Order) Razorpay_Confirm();
    }
  }

  const deviceDisplay = () => {
    if (OrderResponse)
      return (<>{OrderStatus()}</>)
    else {
      if (window.innerWidth < 960) {
        return (
          <>
            {TransactLoading()}
            {Payment_Confirmor()}
            <div data-uk-grid id="grid">
              {mobIndex == 1 &&
                <div className="uk-width-1-1 uk-padding-remove">
                  <div ref={UserDetailsBlockRef}>
                    <UserDetails
                      userDetails={userDetails}
                      setUserDetails={setUserDetails}
                      validationTrace={remoteValidationTrace}
                      setValidationTrace={setRemoteValidationTrace}
                      VOData={VOData}
                      profile={props.profile_data}
                      globalLoading={globalLoading}
                      setGobalLoading={setGobalLoading}
                    />
                  </div>
                </div>
              }
              {mobIndex == 2 &&
                <div className="uk-width-1-1 uk-padding-remove">
                  <div ref={ShippingAddressBlockRef}>
                    <AddressDetails
                      shippingDetails={shippingDetails}
                      setshippingDetails={setshippingDetails}
                      validationTrace={remoteValidationTrace}
                      setValidationTrace={setRemoteValidationTrace}
                      VOData={VOData}
                      sameAddress={BillingShippingAddressSame}
                      setSameAddress={setBillingShippingAddressSame}
                      profile={props.profile_data}
                      updateVO={updateVO}
                      globalLoading={globalLoading}
                      setGobalLoading={setGobalLoading}
                    />
                  </div>
                  <div ref={BillingAddressBlockRef}>
                    {showBillingAddress()}
                  </div>
                </div>
              }
              {mobIndex == 3 &&
                <div className="uk-width-1-1 uk-padding-remove">
                  <Coupons
                    symbol={props.symbol}
                    VOData={VOData}
                    syncing={loadingVO}
                    updateVO={() => { syncCart(updateVO) }}
                  />

                  <RedeemGiftCard
                    VOData={VOData}
                    updateVO={() => { syncCart(updateVO) }}
                    syncing={loadingVO}
                  />

                  {/* {console.log('RENDERING Payment Method', VOData.order ? VOData.order.applied_payment_method : "")} */}
                  <PaymentMethod
                    VOData={VOData}
                    syncing={loadingVO || props.extendedItems.syncing}
                    updateVO={() => { syncCart(updateVO) }}
                  />
                  {/* <ShippingMethod
                        handleChange={handleCheckboxChange}
                        options={orderDetails.options}
                      /> */}

                  {/* {console.log('RENDERING VO CART', props)} */}
                  <VOCartPreview
                    userDetails={userDetails}
                    shippingDetails={shippingDetails}
                    symbol={props.symbol}
                    removeFromCart={props.removeFromCart}
                    cart={props.extendedItems.list}
                    syncing={loadingVO || props.extendedItems.syncing}
                    VOData={VOData}
                    generate_order={generate_order}
                    _reCaptchaRef={_reCaptchaRef}
                    onRecaptcha={onRecaptcha}
                    updateVO={() => updateVO(orderPlace)}
                    globalDebounce={globalDebounce}
                  />
                </div>
              }
            </div>
          </>
        )
      }
      else
        return (
          <>
            {TransactLoading()}
            {Payment_Confirmor()}


            {/* <div className="uk-padding-small">
                  <progress id="js-progressbar" className="uk-progress uk-margin-remove" value="10" max="100"></progress>
                </div> */}
            <div data-uk-grid id="grid">
              <div className="uk-width-1-1 uk-width-1-3@m uk-padding-remove">
                <div ref={UserDetailsBlockRef}>
                  <UserDetails
                    userDetails={userDetails}
                    setUserDetails={setUserDetails}
                    validationTrace={remoteValidationTrace}
                    setValidationTrace={setRemoteValidationTrace}
                    VOData={VOData}
                    profile={props.profile_data}
                    globalLoading={globalLoading}
                    setGobalLoading={setGobalLoading}
                  />
                </div>
                <div ref={ShippingAddressBlockRef}>
                  <AddressDetails
                    shippingDetails={shippingDetails}
                    setshippingDetails={setshippingDetails}
                    validationTrace={remoteValidationTrace}
                    setValidationTrace={setRemoteValidationTrace}
                    VOData={VOData}
                    sameAddress={BillingShippingAddressSame}
                    setSameAddress={setBillingShippingAddressSame}
                    profile={props.profile_data}
                    globalLoading={globalLoading}
                    setGobalLoading={setGobalLoading}
                  />
                </div>
              </div>
              <div className="uk-width-1-1 uk-width-1-3@m">
                <div ref={BillingAddressBlockRef}>
                  {showBillingAddress()}
                </div>
                <Coupons
                  symbol={props.symbol}
                  VOData={VOData}
                  syncing={loadingVO}
                  updateVO={() => { syncCart(updateVO) }}
                />

                <RedeemGiftCard
                  VOData={VOData}
                  updateVO={() => { syncCart(updateVO) }}
                  syncing={loadingVO}
                />



                {/* {console.log('RENDERING Payment Method', VOData.order ? VOData.order.applied_payment_method : "")} */}
                <PaymentMethod
                  VOData={VOData}
                  syncing={loadingVO || props.extendedItems.syncing}
                  updateVO={() => { syncCart(updateVO) }}
                />
                {/* <ShippingMethod
                handleChange={handleCheckboxChange}
                options={orderDetails.options}
              /> */}
              </div>
              <div className="uk-width-1-1 uk-width-1-3@m">

                {/* {console.log('RENDERING VO CART', props)} */}
                <VOCartPreview
                  userDetails={userDetails}
                  shippingDetails={shippingDetails}
                  symbol={props.symbol}
                  removeFromCart={props.removeFromCart}
                  cart={props.extendedItems.list}
                  syncing={loadingVO || props.extendedItems.syncing}
                  VOData={VOData}
                  generate_order={generate_order}
                  _reCaptchaRef={_reCaptchaRef}
                  onRecaptcha={onRecaptcha}
                  updateVO={() => updateVO(orderPlace)}
                  globalDebounce={globalDebounce}
                />
              </div>
            </div>
          </>
        )
    }
  }

  const incrementIndex = (response) => {
    if (mobIndex == 1) {
      if (response.message.order.user_details != null) {
        window.scrollTo(0, 0);
        setMobIndex(mobIndex + 1)
      }
      else
        SDClient.endpoint("virtual_order_set_user_details", { ...userDetails }).then(
          (data) => {
            if (data.message.action) {
              setRemoteValidationTrace({ ...remoteValidationTrace, user: {}, });
            } else {
              if (data.message.reason == "MESSAGE_VALIDATION_ERROR") {
                toast.warn("Oops! Please complete your user details", { autoClose: 2000, hideProgressBar: true, });
                setRemoteValidationTrace({ ...remoteValidationTrace, user: data.message.trace, });
              }
            }
          },
        )
    }
    else if (mobIndex == 2) {
      if (response.message.order.shipping_address != null) {
        window.scrollTo(0, 0);
        setMobIndex(mobIndex + 1)
      }
      else
        SDClient.endpoint("virtual_order_set_user_details", { ...shippingDetails }).then(
          (data) => {
            if (data.message.action) {
              setRemoteValidationTrace({ ...remoteValidationTrace, shipping_address: {}, });
            } else {
              if (data.message.reason == "MESSAGE_VALIDATION_ERROR") {
                toast.warn("Oops! Please complete your shipping details", { autoClose: 2000, hideProgressBar: true, });
                setRemoteValidationTrace({ ...remoteValidationTrace, shipping_address: data.message.trace, });
              }
            }
          },
          // console.log
        )
    }
    else if (mobIndex == 3) {
      if (response.message.order.applied_payment_method != null) {
        onRecaptcha()
      }
      else
        toast.warn("Oops! No payment method selected.", { autoClose: 2000, hideProgressBar: true, });

    }
  }

  const decrementIndex = () => {
    if (mobIndex > 1) {
      window.scrollTo(0, 0);
      setMobIndex(mobIndex - 1)
    }
  }

  /*Recaptcha*/
  const onRecaptcha = () => {
    _reCaptchaRef.current.executeAsync();
  }

  const handleButton = () => {
    if (!OrderResponse && window.innerWidth < 960) {
      return (
        <div className="uk-child-width-1-2" data-uk-grid>
          <div className=" uk-margin-small-bottom">
            <div
              className={"uk-button uk-align-center uk-button-secondary add-cart" + (mobIndex <= 1 ? "-button-hide" : "")}
              onClick={() => decrementIndex()}
            >
              Previous
            </div>
          </div>
          <div className=" uk-margin-small-bottom">
            <div
              className="uk-button uk-align-center uk-button-primary buy-now"
              onClick={() => updateVO(incrementIndex)}
            >
              Next
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <div className={"uk-padding-small " + (OrderResponse != null ? "light-layout" : "")}>
      <Seo type="checkout-head" />

      <section className='container'>
        {OrderResponse == null && <Breadcrumb ancestor="Checkout" />}
        {message.description &&
          <div className="alert alert-danger" role="alert" style={{ cursor: "pointer" }} onClick={() => setMessage({ description: "" })}>
            {message.description}
          </div>
        }
        {deviceDisplay()}

        {handleButton()}
      </section>
    </div>
  )
}

const mapStateToProps = (state) => ({
  cartItems: state.cartList.cart,
  extendedItems: state.cartList.extended_cart,
  profile_data: state.data.profile_data,
  symbol: state.data.symbol,
})

export default connect(mapStateToProps, { removeFromCart, actProfile, })(CheckOut1)
