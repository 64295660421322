import {
  ADD_TO_WISHLIST,
  REMOVE_FROM_WISHLIST,
  CART_UPDATED
} from "../constants/ActionTypes";
import { toast } from "react-toastify";

export default function wishlistReducer(
  state = {
    list: []
  },
  action
) {
  switch (action.type) {
    case ADD_TO_WISHLIST:
      const variantId = action.variants.id
      if (state.list.findIndex(product => product.variant.id === variantId) !== -1) {
        const list = state.list.reduce((cartAcc, product) => {
          cartAcc.push({
            ...product,
            // variant: action.variants
          });
          return cartAcc;
        }, []);
        toast.warn("Item already Added");
        return { ...state, list };
      } else {
        toast.success("Item Added to Wishlist");
      }
      return { ...state, list: [...state.list, { ...action.product, variant: action.variants }] };
    case CART_UPDATED:
      state.list.forEach(oldWishlistItem => {
        action.payload.forEach(newWishlistItem => {
          if (oldWishlistItem.id === newWishlistItem.id) {
            oldWishlistItem.variant.offerprice = newWishlistItem.variant.offerprice
            oldWishlistItem.selectedVariant.offerprice = newWishlistItem.variant.offerprice
            oldWishlistItem.active = newWishlistItem.deleted ? false : true
            // oldWishlistItem.active = false
          }
        })
      });
      return {
        ...state,
        list: [...state.list]
      };
    case REMOVE_FROM_WISHLIST:
      const variantid = action.variants.id
      return {
        list: state.list.filter(item => item.variant.id !== action.variants.id)
      };
    default:
  }
  return state;
}
