import React from "react";
import ProductSlider from "../putting_on_the_glitz/product-slider";
import { addToCart, addToWishlist, addToCompare, removeFromWishlist } from "../../../actions";
import { connect } from 'react-redux';

const TrendingProducts = (props) => {

  const {
    addToCart,
    addToWishlist,
    addToCompare,
    removeFromWishlist,
    wishListItems,
    searchResults,
    symbol
  } = props;

  if (searchResults.length != 0) {
    return (
      <section className="gift-ideas-section px-0 py-5 px-md-5">
        <div className="gift-ideas-container">
          <div className="heading-section px-3 p-md-0" style={{ maxWidth: "250px" }}>
            <h2 className="heading-title p-0">Trending products</h2>
            <div className="heading-icon mt-0">
              <span className="hr-line">&nbsp;</span>
              <span className="diamond-icon">&nbsp;</span>
              <span className="hr-line">&nbsp;</span>
            </div>
          </div>
          <div className="mt-3" data-uk-slider="autoplay:true;autoplay-interval=500;velocity=10;easing=smooth">
            <div className="uk-position-relative">
              <div className="uk-slider-container uk-light">
                <ul className="uk-slider-items uk-grid uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-4@l uk-child-width-1-5@xl">
                  {searchResults.map((item, index) => (
                    <div key={index} className='p-0 px-lg-1 py-lg-3'>
                      <ProductSlider
                        item={item}
                        onAddToCompareClicked={addToCompare}
                        onAddToWishlistClicked={addToWishlist}
                        onRemoveFromWishlistClicked={removeFromWishlist}
                        onAddToCartClicked={addToCart}
                        wishListedItem={wishListItems}
                        symbol={symbol}
                      />
                    </div>
                  ))}
                </ul>
              </div>
              <div className="uk-visible@s">
                <a className="uk-position-center-left-out uk-position-small mx-0" href data-uk-slidenav-previous data-uk-slider-item="previous"></a>
                <a className="uk-position-center-right-out uk-position-small mx-0" href data-uk-slidenav-next data-uk-slider-item="next"></a>
              </div>

            </div>
            <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
          </div>
        </div>
      </section>
    );
  } else return null;
}

const mapStateToProps = state => ({
  wishListItems: state.wishlist.list,
  symbol: state.data.symbol,
});

export default connect(mapStateToProps, { addToCart, addToWishlist, removeFromWishlist, addToCompare })(TrendingProducts);
