import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Item from './item';
import { addToCart, addToWishlist, removeFromWishlist, addToCompare } from "../../../actions";
import CategoryDescription from './catagory-description';

const ListingPanel = (props) => {

    const {
        products,
        symbol,
        addToCart,
        addToWishlist,
        removeFromWishlist,
        addToCompare,
        wishListItems,
        selectedPage,
        selectedSorting,
        setSelectedSorting,
        totalProducts,
        loadItems,
        hitsPerPage,
        fetchMoreItems,
        productShimmer,
        variantTag
    } = props;

    return (
        <div>
            <CategoryDescription
                categoryTitle={props.title}
                categoryDescription={props.description}
                selectedSorting={selectedSorting}
                setSelectedSorting={setSelectedSorting}
                totalProducts={totalProducts}
                history={props.history}
                productsCount={products.length}
            />

            <div className="uk-width-1-1 listing-box-main">
                <h3 className='uk-visible@s'>Select Your Style</h3>
                {products !== undefined && products.length > 0 ? !productShimmer ? (
                    <div>
                        <div className="uk-grid uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-4@m uk-text-center" data-uk-grid>
                            {products.map((product, index) => (
                                <Item
                                    key={index}
                                    index={index}
                                    product={product}
                                    symbol={symbol}
                                    onAddToCompareClicked={addToCompare}
                                    onAddToWishlistClicked={addToWishlist}
                                    onRemoveFromWishlistClicked={removeFromWishlist}
                                    onAddToCartClicked={addToCart}
                                    variantTag={variantTag}
                                    wishListedItem={wishListItems}
                                />
                            ))}
                        </div>
                        {totalProducts > products.length ?
                            <div className="uk-width-1-1 uk-padding-remove uk-text-center uk-align-center">
                                {!loadItems ?
                                    <div
                                        className="uk-button uk-button-secondary add-cart"
                                        onClick={() => fetchMoreItems()}
                                    >
                                        Load more
                                    </div> : <div data-uk-spinner></div>}
                            </div> : ""}
                        <li className='text-muted uk-hidden@s w-100 uk-text-center'>
                            Showing {products.length} in {totalProducts} Products
                        </li>
                    </div>
                ) : <div className="card-text placeholder-glow col-12 p-0 w-100">
                    <div className='px-2 mt-4 row'>
                        {[...Array(8).keys()].map(() => (
                            <div className='p-2 col-6 col-md-4 col-lg-3'>
                                <div className="placeholder placeholder-thumb w-100" style={{ height: "17rem" }}>
                                </div>
                            </div>
                        ))}
                    </div>
                </div> : (
                    <div className="row">

                        <div className="col-sm-12 text-center section-b-space my-5 no-found">
                            <h2 className='p-0'>
                                Sorry! Couldn't find the product you were looking For!!!
                            </h2>
                            <div class="searchRefult ">
                                <div className='col-lg-3 col-6'>
                                    <Link to={`${process.env.PUBLIC_URL}/jewellery/rings`}>
                                        <img src="/assets/images/new_template/images/rings.jpg" class="img-fluid" alt="Rings" style={{ borderRadius: "50%" }} />
                                        <p class="searchRefult--list-text text-center">Rings</p>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-6'>
                                    <Link to={`${process.env.PUBLIC_URL}/jewellery/earrings`}>
                                        <img src="/assets/images/new_template/images/earrings.jpg" class="img-fluid" alt="Earrings" style={{ borderRadius: "50%" }} />
                                        <p class="searchRefult--list-text text-center">Earrings</p>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-6'>
                                    <Link to={`${process.env.PUBLIC_URL}/jewellery/pendants`}>
                                        <img src="/assets/images/new_template/images/pendant.jpg" class="img-fluid" alt="Pendant" style={{ borderRadius: "50%" }} />
                                        <p class="searchRefult--list-text text-center">Pendant</p>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-6'>
                                    {/* <Link to={`${process.env.PUBLIC_URL}/jewellery/necklace`}>
                                        <img src="/assets/images/new_template/images/necklace.jpg" class="img-fluid" alt="Necklace" style={{borderRadius:"50%"}}/>
                                        <p class="searchRefult--list-text text-center">Necklace</p>
                                    </Link> */}
                                    <Link to={`${process.env.PUBLIC_URL}/jewellery/bracelets`}>
                                        <img src="/assets/images/new_template/images/bracelet.jpg" class="img-fluid" alt="Bracelet" style={{ borderRadius: "50%" }} />
                                        <p class="searchRefult--list-text text-center">Bracelet</p>
                                    </Link>
                                </div>

                            </div>
                            <Link
                                to={`${process.env.PUBLIC_URL}/trending`}
                                className="btn btn-solid mt-4 text-uppercase" style={{ fontSize: '20px' }}
                            >
                                Expolre Design
                            </Link>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    symbol: state.data.symbol,
    wishListItems: state.wishlist.list,
});

export default connect(mapStateToProps, { addToCart, addToWishlist, removeFromWishlist, addToCompare })(ListingPanel);