import React, { useState, useEffect } from 'react';
import ColorThief from 'color-thief';

const ImageDiv = ({ imageUrl, children }) => {
  const [edgeColor, setEdgeColor] = useState(null);

  useEffect(() => {
    const image = new Image();
    image.crossOrigin = 'Anonymous';
    image.src = imageUrl;

    image.onload = () => {
      try {
        const colorThief = new ColorThief();
        const edgeColors = getEdgeColors(colorThief, image);
        setEdgeColor(`rgb(${edgeColors[0]}, ${edgeColors[1]}, ${edgeColors[2]})`);
      } catch (error) {
        console.error('Error extracting edge color:', error);
      }
    };

    image.onerror = (error) => {
      console.error('Error loading image:', error);
    };
  }, [imageUrl]);

  const getEdgeColors = (colorThief, image) => {
    const sampleSize = 10;
  
    if (image.width > 0 && image.height > 0) {
      const canvas = document.createElement('canvas');
      canvas.width = sampleSize;
      canvas.height = sampleSize;
      const context = canvas.getContext('2d');
      // context.drawImage(image, 0, 0, sampleSize, sampleSize);
      context.drawImage(image, 0, 0, image.width, image.height);
  
      const pixelColor = context.getImageData(0, 0, 1, 1).data;
  
      return [
        pixelColor[0],
        pixelColor[1],
        pixelColor[2],
      ];
    } else {
      throw new Error('Image dimensions are invalid.');
    }
  };

  return (
    <div id="parentDiv" style={{ background:edgeColor, height:'500px', width:'1000px' }}>
      {children}
    </div>
  );
};

export default ImageDiv;