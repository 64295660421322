import React, { useRef, useState, useReducer } from 'react';

import axios from 'axios';

import SimpleReactValidator from "simple-react-validator";

import { Link } from 'react-router-dom';
import RectangleLoader from '../../common/rectangleLoader';

const TryAtHome = (props) => {

  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);

  const [stage, setStage] = useState(1);
  const [number, setNumber] = useState('');
  const [pincode, setPincode] = useState('');
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [date, setDate] = useState('');
  const [token, setToken] = useState('');
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleClose = () => {
    setShowModal(false)
    setNumber("");
    setPincode("");
    setName("");
    setAddress("");
    setDate("");
    setTimeSlots("");
    setToken("");
    setErrorMessage("");
    setStage(1);
    validator.current.hideMessages();
  };

  const validator = useRef(new SimpleReactValidator());
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const getMinDate = () => {
    const today = new Date();
    const nextDate = new Date(today);
    nextDate.setDate(today.getDate() + 1);

    const year = nextDate.getFullYear();
    const month = String(nextDate.getMonth() + 1).padStart(2, '0');
    const day = String(nextDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const handleInputChange = (setter) => (e) => setter(e.target.value);

  const handleNextStage = (stage) => {
    setErrorMessage("");
    if (validator.current.allValid()) {
      setStage(stage + 1);
    } else {
      validator.current.showMessages();
      forceUpdate();
    }
  }
  const handlePreviousStage = (stage) => {
    setErrorMessage("");
    setStage(stage - 1);
  }


  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const img_fallback = (event) => {
    event.target.parentNode.childNodes.forEach(function (node) {
      if (node.nodeName == "IMG") node.src = window.no_image;
    });
  }


  //First Stage axios call for checking stock and pincode
  const initalVerificationCall = async () => {
    if (validator.current.allValid()) {
      setLoading(true);
      try {
        const response = await axios.post(`${global.postUrl}try_at_home/product/availability/check`, {
          mobile: number,
          pincode: pincode,
          sku: props.item.sku,
        });
        if (response.data.success) {
          validator.current.hideMessages();
          setStage(2);
          setToken(response.data.token);
          setTimeSlots(response.data.showroom.time_slot);
        } else {
          const reason = response.data.reason;
          switch (reason) {
            case 'PINCODE_CHECK_FAILED':
              setErrorMessage("Sorry, this feature isn't available at the provided pincode. Please try another design. We apologize for the inconvenience.");
              break;
            case 'PRODUCT_NOT_FOUND':
              setErrorMessage('Sorry, this product is currently unavailable. Please select a different one. We apologize for the inconvenience.');
              break;
            case 'OUT_OF_STOCK':
              setErrorMessage("We're sorry, but this product is currently out of stock. Please select a different product. We apologize for the inconvenience.");
              break;
            case 'VALIDATION_ERROR':
              setErrorMessage('There was a validation error. Please review your input.');
              break;
            default:
              setErrorMessage('An unknown error occurred.');
          }
        }
      } catch (error) {
        console.error('Error verifying data', error);
        setErrorMessage('An error occurred while verifying your data. Please try again later. We apologize for the inconvenience.');
      } finally {
        setLoading(false); // Stop loading
      }
    } else {
      validator.current.showMessages();
      forceUpdate();
    }
  };

  //Second stage axios call for confirm booking
  const finalConfirmationCall = async () => {
    if (validator.current.allValid()) {
      setLoading(true);
      try {
        const response = await axios.post(`${global.postUrl}try_at_home/request`, {
          name: name,
          address: address,
          date: date,
          token: token,
          timeSlot: selectedTimeSlot
        });
        if (response.data.success) {
          validator.current.hideMessages();
          setStage(4);
        } else if (response.data.errors.date) {
          setErrorMessage('The date must be a future date. Please select a different date. We apologize for the inconvenience.')
        } else {
          const reason = response.data.reason;
          switch (reason) {
            case 'INVALID_TOKEN':
              setErrorMessage('The reference number does not match our records. Please check and try again.');
              break;
            case 'VALIDATION_ERROR':
              setErrorMessage('There was a validation error. Please review your input.');
              break;
            case 'DATABASE_ERROR':
              setErrorMessage('There is an issue with the server. Please try again later. We apologize for the inconvenience.');
              break;
            default:
              setErrorMessage('An unknown error occurred.');
          }
        }
      } catch (error) {
        console.error('Error verifying data', error);
        setErrorMessage('An error occurred while verifying your data. Please try again later. We apologize for the inconvenience.');
      } finally {
        setLoading(false); // Stop loading
      }
    } else {
      validator.current.showMessages();
      forceUpdate();
    }

  };

  const inr = new Intl.NumberFormat('en-IN', { style: 'decimal' })

  const stage1 = () => {
    return (
      <div>
        <div className='text-center'>
          <h4 className="text-captialize uk-margin-remove ">Try on your favourite designs</h4>
          <h4>from the comfort of your home</h4>
          {isLoading && <div className="placeholder placeholder-thumb w-100"></div>}
          <img
            src="https://static.sunnydiamonds.com/images/others/try@home/try_at_home.png"
            className={`img-fluid rounded mx-auto d-block w-100 ${isLoading ? 'd-none' : ''}`}
            alt="try@home"
            onLoad={handleImageLoad}
            onError={img_fallback}
          />
        </div>
        <div className='text-center my-3'>
          {/* <h3 className='text-capitalize fw-bold mb-0'>Try At Home for free</h3> */}
          <div className='m-0 text-center'>
            *Enter your details to check availability. There is no obligation to buy.
          </div>
        </div>
        <div className='my-2'>
          <div className="uk-margin-small uk-margin-remove-horizontal">
            <div className="uk-flex uk-flex-center">
              <input
                type="tel"
                name='number'
                className="uk-input uk-text-center rounded"
                value={number}
                onChange={handleInputChange(setNumber)}
                placeholder='Mobile No*'
              />
            </div>
            {validator.current.message(
              "Mobile Number",
              number,
              "required|phone|min:10|max:10"
            )}
          </div>
          <div className="uk-margin-small uk-margin-remove-horizontal">
            <div className="uk-flex uk-flex-center">
              <input
                type="tel"
                className="uk-input uk-text-center rounded"
                value={pincode}
                onChange={handleInputChange(setPincode)}
                placeholder='Pin Code*'
              />
            </div>
            {validator.current.message(
              "Pin Code",
              pincode,
              "required|number|min:6|max:6"
            )}
          </div>
          {errorMessage && <div className="alert alert-danger p-2">
            <i class="fa fa-exclamation-triangle"></i> {errorMessage}
          </div>}
          <div>
            <button
              type="button"
              className="uk-button uk-button-primary buy-now uk-align-center w-100 text-uppercase rounded"
              onClick={initalVerificationCall}
              disabled={loading}
            >
              {loading ? (
                <RectangleLoader />) : (
                'Submit'
              )}
            </button>
          </div>
        </div>
      </div>
    )
  }

  const stage2 = () => {
    return (
      <div className='my-3'>
        <div className='my-2'>
          <h4 className='uk-text-bolder text-center'>Try@Home Booking Details</h4>
          <div className="uk-card uk-card-default">
            <div className="uk-card-header uk-padding-small">
              <h4 className='uk-text-bolder uk-text-meta'>Selected Product Detail</h4>
              <div className="uk-grid-small uk-flex" data-uk-grid>
                <div className="uk-width-auto p-0">
                  <img width="116" height="116"
                    src={`${props.imageArray[0]}?resize=116,116&compress=50`}
                    alt={props.item.name}
                    onError={img_fallback} />
                </div>
                <div className="uk-width-expand">
                  <h3 className="uk-card-title uk-margin-remove-bottom uk-text-bolder">
                    <small>{props.item.name.toUpperCase()}</small>
                  </h3>
                  <p className="uk-margin-remove-top uk-margin-remove-bottom uk-text-meta">
                    <small>{props.item.sku.toUpperCase()}</small>
                  </p>
                  <p className="uk-text-meta uk-margin-remove-top uk-margin-remove-bottom uk-text-meta">
                    <small>{props.symbol} {inr.format(props.item.variants[0].offerprice)}</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='my-2'>
          <div className="uk-margin-small uk-margin-remove-horizontal">
            <div className="uk-form-label para-one uk-text-left mb-0 ml-0">Full Name<span className='text-danger'>*</span></div>
            <div className="uk-flex uk-flex-center">
              <input
                type="text"
                name='name'
                className="uk-input rounded"
                value={name}
                onChange={handleInputChange(setName)}
                placeholder='Enter Your Name*'
              />
            </div>
            {validator.current.message(
              "Name",
              name,
              "required|alpha_space"
            )}
          </div>
          <div className="uk-margin-small uk-margin-remove-horizontal">
            <div className="uk-form-label para-one uk-text-left mb-0 ml-0">Address<span className='text-danger'>*</span></div>
            <div className="uk-flex uk-flex-center">
              <textarea
                className="uk-textarea rounded"
                name="address"
                id="address"
                placeholder="Address with Landmark"
                rows="3"
                maxLength="150"
                value={address}
                onChange={handleInputChange(setAddress)}
              />
            </div>
            {validator.current.message(
              "Address",
              address,
              "required|alpha_numeric_space"
            )}
          </div>
          <div className="uk-margin-small uk-margin-remove-horizontal">
            <div className="uk-form-label para-one uk-text-left mb-0 ml-0">Choose a Date<span className='text-danger'>*</span></div>
            <div className="uk-flex uk-flex-center">
              <input
                type="date"
                className="uk-input rounded"
                value={date}
                onChange={handleInputChange(setDate)}
                placeholder='Choose a time*'
                min={getMinDate()}
              />
            </div>
            {validator.current.message(
              "Date",
              date,
              "required"
            )}
          </div>
          <div className="uk-margin-small uk-margin-remove-horizontal">
            <div>Pick Your Preferred Time Slot</div>
            {timeSlots.length > 0 && (
              <div>
                {timeSlots.map((slot, index) => (
                  <div className="form-check" key={index}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="timeSlot"
                      id={`timeSlot${index}`}
                      value={slot}
                      checked={selectedTimeSlot === slot}
                      onChange={(e) => setSelectedTimeSlot(e.target.value)}
                      onBlur={() => validator.current.showMessageFor('timeSlot')}
                    />
                    <label className="form-check-label" htmlFor={`timeSlot${index}`}>
                      {slot}
                    </label>
                  </div>
                ))}
                {validator.current.message('timeSlot', selectedTimeSlot, 'required', {
                  className: 'text-danger',
                  messages: { required: 'Please select a time slot' },
                })}
              </div>
            )}
          </div>
          <div>
            <button
              type="button"
              className="uk-button uk-button-primary buy-now uk-align-center w-100 text-uppercase rounded"
              onClick={() => { handleNextStage(2) }}>
              Submit
            </button>
          </div>
        </div>
      </div>
    )
  }

  const stage3 = () => {
    return (
      <div className="row justify-content-center mt-1">
        {errorMessage && <div className="alert alert-danger p-2">
          <i class="fa fa-exclamation-triangle"></i> {errorMessage}
        </div>}
        <div className="col-12 p-3 pt-0">
          <h4 className='uk-text-bolder text-center'>Confirm Your Booking</h4>
          <div className="border-bottom d-flex justify-content-between py-3">
            <div className="small text-uppercase pe-2 uk-text-bold">Full Name</div>
            <div className="fw-bold small text-uppercase text-right">
              {name.toUpperCase()}
            </div>
          </div>
          <div className="border-bottom d-flex justify-content-between py-3">
            <div className="small text-uppercase pe-2 uk-text-bold">Contact Number</div>
            <div className="fw-bold small text-uppercase text-right">
              {number.toUpperCase()}
            </div>
          </div>
          <div className="border-bottom d-flex justify-content-between py-3">
            <div className="small text-uppercase pe-2 uk-text-bold">Address</div>
            <div className="fw-bold small text-uppercase text-right">
              {address.toUpperCase()}
            </div>
          </div>
          <div className="border-bottom d-flex justify-content-between py-3">
            <div className="small text-uppercase pe-2 uk-text-bold">Product Name</div>
            <div className="fw-bold small text-uppercase text-right">
              {props.item.name.toUpperCase()}
            </div>
          </div>
          <div className="border-bottom d-flex justify-content-between py-3">
            <div className="small text-uppercase pe-2 uk-text-bold">Booked Date</div>
            <div className="fw-bold small text-uppercase text-right">
              {date} {selectedTimeSlot}
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-between'>
          <div className='mx-2'>
            <button
              type="button"
              className='uk-button uk-button-secondary add-cart uk-align-center w-100 text-uppercase rounded'
              onClick={() => { handlePreviousStage(3) }}
            >
              Back to Edit
            </button>
          </div>
          <div>
            <button
              type="button"
              className='uk-button uk-button-primary buy-now uk-align-center w-100 text-uppercase rounded'
              onClick={finalConfirmationCall}
            >
              {loading ? (
                <RectangleLoader />) : (
                'Confirm Booking'
              )}
            </button>
          </div>
        </div>
      </div>
    )
  }

  const stage4 = () => {
    return (
      <div className='my-3'>
        <div className="uk-flex uk-flex-center">
          <div className="uk-padding d-flex flex-column align-items-center w-100">
            <div className="success-text d-flex flex-column align-items-center">
              <i className="fa fa-check-circle" aria-hidden="true" style={{ fontSize: "5rem" }}></i>
              <h2 className="heading-title title-normal uk-margin">Your Booking is Complete</h2>
              <p className="uk-text-center uk-margin-remove uk-padding-small">
                Your booking is confirmed with reference ID: <span className='uk-text-bold'>{token}</span>. The Confirmation SMS has been sent to your contact number.
              </p>
              <p className="uk-text-center uk-margin-remove uk-padding-small uk-padding-remove-vertical ">We hope you enjoy your service</p>
              <Link
                data-lng="en"
                className="uk-button uk-button-primary buy-now uk-align-center rounded"
                rel="noopener noreferrer"
                to={`${process.env.PUBLIC_URL}/jewellery`}
              >
                <div>continue shopping</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="card bg-white rounded shadow text-dark text-uppercase p-3">
            <div className="d-flex flex-row justify-content-between my-3">
              <div>
                <h4 className="text-uppercase fw-bold mb-0">Try at Home</h4>
                <h5 className="small m-0">How it Works ?</h5>
              </div>
              <div>
                <button
                  type="button"
                  className="uk-button uk-button-primary buy-now uk-width-1-1 rounded"
                  style={{ lineHeight: "30px" }}
                  onClick={handleShow}>
                  Try At Home
                </button>
              </div>
            </div>
            <div className="d-flex flex-md-row flex-column justify-content-between">
              <div className="card rounded shadow p-3 m-2">
                <div className="fw-bold mb-0 text-left">
                  <small style={{ fontWeight: "bolder", color: "green" }}>Book Your Free Appointment</small>
                </div>
                <p className="m-0 text-left"><small><small>At your chosen place, date & time</small></small></p>
              </div>
              <div className="card rounded shadow p-3 m-2">
                <div className="fw-bold mb-0 text-left">
                  <small style={{ fontWeight: "bolder", color: "#722257" }}>Try On at Your Leisure</small>
                </div>
                <p className="m-0 text-left"><small><small>Let us bring the store to you</small></small></p>
              </div>
              <div className="card rounded shadow p-3 m-2">
                <div className="fw-bold mb-0 text-left">
                  <small style={{ fontWeight: "bolder", color: "black" }}>Buy Only If You Love</small>
                </div>
                <p className="m-0 text-left"><small><small>There's no compulsion to buy</small></small></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Try@Home Modal */}
      {showModal && (

        <div className="blurred-background uk-flex uk-flex-center uk-padding">
          <div className="bg-white shadow uk-padding uk-width-1-4@xl uk-width-2-4@l uk-width-3-5@m otp_popup rounded">
            <div className="uk-flex uk-flex-right">
              <svg
                onClick={() => { handleClose() }}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="mx-1 text-secondary cursor-pointer"
                viewBox="0 0 16 16">
                <path
                  d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z" />
              </svg>
            </div>
            <div>
              {stage == 1 && stage1()}
              {stage == 2 && stage2()}
              {stage == 3 && stage3()}
              {stage == 4 && stage4()}
            </div>
          </div>
        </div>
      )}

    </div>
  );
};


export default TryAtHome;