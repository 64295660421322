import React, { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import Loader from "../../common/spinner"

import './shimmer.scss'

const VOCartPreview = (props) => {

  const inr = new Intl.NumberFormat('en-IN', { style: 'decimal' })

  const img_fallback = (event) => {
    event.target.parentNode.childNodes.forEach(function (node) {
      if (node.nodeName == "IMG") node.src = window.no_image;
    });
  }

  const loadingPreview = () => {
    return (
      <div className="bg-white border uk-padding-small uk-margin-bottom">
        <p className="uk-text-lead text-uppercase para-one uk-margin-small-bottom">Order preview</p>

        <div className="card-text placeholder-glow">
          <div className="d-flex align-items-start">
            <div className="placeholder placeholder-thumb"></div>
            <div className="p-1 px-3 flex-fill">
              <p className="placeholder placeholder-xl w-100"></p>
              <p className="placeholder col-8"></p>
            </div>
          </div>
          <hr />
          <p className="placeholder col-8"></p>
          <p className="placeholder col-7"></p>
          <p className="placeholder col-6"></p>
          <p className="placeholder col-8"></p>
          <br></br>
          <p type="button" className="placeholder btn  btn-promo-code w-100 d-block">Apply</p>
        </div>
      </div>
    )
  }

  const cartPreview = () => {

    const items = props.cart.map((item, index) => {
      return (
        <div className="d-flex align-items-start uk-margin-small-top" key={index}>
          <img
            className="border rounded shadow-sm"
            src={`${item.cart_product_image.split(',')[0]}?resize=98,98&compress=50`}
            onError={img_fallback}
            alt={item.cart_product_name}
            style={{ width: "100px", height: "100px" }}
          />
          <div className="flex-fill uk-padding-small uk-padding-remove-vertical">
            <h5 className="d-flex justify-content-between text-uppercase noraml-para uk-text-bold uk-margin-small">
              {item.cart_product_name}
              <a href="javascript:void(0)"
                className="icon"
                onClick={() => {
                  props.removeFromCart(item.cart_product_data.product_id, { id: item.cart_product_data.id });
                }}>
                <i className="icon-close" />
              </a>
            </h5>
            <h6 className="uk-margin-small">{item.cart_product_sku}</h6>
            <div className="uk-flex uk-flex-between">
              <p className="uk-margin-small para-one">
                {item.cart_product_quantity} X {props.symbol} {inr.format(item.cart_product_unit_price)}
              </p>
              <p className="para-one">Total: {props.symbol}{inr.format((item.cart_product_quantity) * (item.cart_product_unit_price))}</p>
            </div>
          </div>
        </div>
      )
    })

    const pricing = (
      <>
        <div className="d-flex justify-content-between">
          <p className="uk-margin-remove">Cart Subtotal</p>
          <p className="uk-margin-remove">
            {/* {props.symbol} */}
            {props.symbol}{inr.format(props.VOData.order.summery.subtotal)}
          </p>
        </div>
        {/* <div className="d-flex justify-content-between">
          <p className="uk-margin-remove">Giftcard Discount</p>
          <p className="uk-margin-remove">
            {props.symbol}{props.VOData.order.summery.redeems}
          </p>
        </div> */}
        <div className="d-flex justify-content-between">
          <p className="uk-margin-remove">Promocode Discount</p>
          <p className="uk-margin-remove">
            {props.symbol}{inr.format(props.VOData.order.summery.discounts)}
          </p>
        </div>
        <div className="d-flex justify-content-between">
          <p className="uk-margin-remove">Redeemed Amount</p>
          <p className="uk-margin-remove">
            {props.symbol}{inr.format(props.VOData.order.summery.redeems)}
          </p>
        </div>
        <div className="d-flex justify-content-between">
          <p className="uk-margin-remove">Service Charges</p>
          <p className="uk-margin-remove">
            {props.symbol}{inr.format(props.VOData.order.summery.charges)}
          </p>
        </div>
        <div className="uk-flex uk-flex-between">
          <h2 className="cart_sum"><b>Grand Total</b></h2>
          <h2 className="cart_sum">
            {props.symbol}{inr.format(props.VOData.order.summery.payable_amount)}
          </h2>
        </div>
      </>
    )

    return (
      <div className="bg-white border uk-padding-small uk-margin-bottom">
        <p className="uk-text-lead text-uppercase para-one uk-margin-small-bottom uk-text-bold"><b>Order preview</b></p>
        <div style={{ maxHeight: '50vh', overflow: "auto" }}>
          {items}
        </div>
        <hr />
        {pricing}
        {props.globalDebounce ?
          <button
            type="button"
            className="uk-button uk-button-primary buy-now uk-align-center w-100 uk-margin-small uk-disabled"
          >
            <Loader
              loaderColor='#fff'
            />
          </button> :
          <button
            type="button"
            className="uk-button uk-button-primary buy-now uk-align-center w-100 uk-margin-small"
            onClick={props.updateVO}
          // onClick={props.generate_order}
          >
            Pay and Place Order
          </button>
        }
        <ReCAPTCHA
          ref={props._reCaptchaRef}
          size="invisible"
          sitekey={global.reCaptcha}
          onChange={(e) => {
            if (e)
              props.generate_order()
            else
              return null
          }}
        />
      </div>
    )
  }

  if (props.syncing) return loadingPreview();
  else return cartPreview();
  // return [
  //   loadingPreview(),
  //   cartPreview()
  // ]
}

export default VOCartPreview;
