import React, { useState } from 'react';

import './instagram-widget.css'

const InstagramWidget = () => {
    const [loader, setLoader] = useState(true)

    const hideLoader = () => {
        setLoader(false)
    }

    return (
        <section className="image-gallery">
            {!loader ?
                <div className="home-container">
                    <div className="heading-section">
                        <h2 className="heading-title title-normal px-2 py-2">As Seen On @Sunnydiamonds</h2>
                        <div className="heading-icon">
                            <span className="hr-line">&#160;</span>
                            <span className="diamond-icon">&#160;</span>
                            <span className="hr-line">&#160;</span>
                        </div>
                    </div>
                </div> : null}
            <div className="tagembed-container instagram1">
                <div
                    className="tagembed-socialwall instagram2"
                    data-wall-id={window.rc.pinterest.data_wall_id}
                    view-url={window.rc.pinterest.view_url}>
                    <iframe
                        className="instagram3"
                        src={window.rc.pinterest.view_url}
                        data-fixed-height="1"
                        data-height="150"
                        data-wall={window.rc.pinterest.data_wall_id}
                        data-position="0"
                        id="iframetv3zdq"
                        scrolling="no"
                        frameBorder="0"
                        allowtransparency="true"
                        title="Tagembed widget"
                        onLoad={hideLoader}
                    />
                </div>
            </div>
        </section>
    );
};

export default InstagramWidget;