import React, { useState } from "react";
import SDClient from "../../../sd_core/client";

const PaymentMethod = (props) => {

  const paymentMethodSync = (data, setErrors) => {
    SDClient.endpoint("virtual_order_set_payment_method", data).then((d) => {
      // console.log("Payment Method UPDATED:", d)

      if (d.message.action) {
      } else {
        let errors = {}
        errors[data.apply_method] = "Unable to set selected payment method.";
        setErrors(errors);
      }
      props.updateVO();
    }, (d) => {
      let errors = {}
      errors[data.apply_method] = "Unable to set selected payment method.";
      setErrors(errors);
      props.updateVO();
    })
  }

  const loadingPreview = () => {
    return (
      <div className="py-2">
        <p className="placeholder col-9"></p>
        <p className="placeholder col-8"></p>
      </div>
    )
  }

  const showMethods = () => {
    const paymentMethod = props.VOData.order.applied_payment_method;
    const availablePaymentMethods = props.VOData.options.payment_methods;
    // console.log("SELECTED PAYMENT METHOD", paymentMethod)
    const [currentMethod, setCurrentMethod] = useState(paymentMethod ? paymentMethod.virtual_order_payment_mode : "")
    const [displayErrors, setDisplayErrors] = useState({});
    const paymentMethods = []

    const icons = {
      "payment_on_delivery": (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1.5rem"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          style={{ height: "1.5rem", width: "1.5rem", }}
          className="w-6 h-6 flex-shrink-0 mx-1">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
        </svg>
      ),
      "online_payment_razorpay": (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1.5rem"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          style={{ height: "1.5rem", width: "1.5rem", }}
          className="w-6 h-6 flex-shrink-0 mx-1">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
        </svg>
      )
    }

    const showErrors = (id) => {
      if (displayErrors[id])
        return (<h6 className="uk-text-danger uk-margin-small">{displayErrors[id]}</h6>)
      else return null;
    }

    for (let [k, v] of Object.entries(availablePaymentMethods)) {
      // console.log("PAYMENT METHODS", v)
      paymentMethods.push((
        <div key={v.id} className="form-group uk-flex-inline">
          <input
            className="uk-radio"
            type="radio"
            name="virtual_order_payment_mode"
            id={v.id}
            checked={currentMethod == v.id}
            onClick={() => { setCurrentMethod(v.id); paymentMethodSync({ apply_method: v.id }, setDisplayErrors) }}
          />
          <div className="uk-flex uk-flex-between">
            <label className="form-check-label" htmlFor={v.id}>
              <p className="uk-margin-remove text-left">{v.method}</p>
            </label>
            {icons[v.id]}
          </div>
          {showErrors(v.id)}
        </div>
      ))
    }
    return paymentMethods;
  }

  const drawPM = () => {
    return props.syncing ? loadingPreview() : showMethods();
  }

  return (
    <div className="bg-white border uk-padding-small uk-margin-bottom">
      <p className="uk-text-lead text-uppercase para-one uk-margin-small-bottom uk-text-bold"><b>Payment Method</b></p>
      {drawPM()}
    </div >
  )

}


export default PaymentMethod
