import React, { useEffect, useState } from "react";
import SDClient from "../../../sd_core/client";
import Loader from "../../common/spinner"

function BillingAddressDetails(props) {
  // const [debounceLock, setDebounceLock] = useState(false);
  const [debounceLockID, setDebounceLockID] = useState(-1);
  const [validationTrace, setValidationTrace] = useState({});
  const [billingDetails, setbillingDetails] = useState({
    'address_country': "India",
    'address_zipcode': "",
    'address_state': "",
    'address_city': "",
    'address_address': "",
  })

  useEffect(() => {
    if (debounceLockID == -1) {
      setDebounceLockID(-2)
      return
    }
    debounce();
  }, [billingDetails])

  useEffect(() => {
    if (props.profile.length > 0)
      setbillingDetails({
        address_country: props.profile.country,
        address_zipcode: props.profile.pincode,
        address_state: props.profile.state,
        address_city: props.profile.city,
        address_address: props.profile.address,
      })
  }, [props.profile])

  useEffect(() => {
    if (props.VOData.order)
      if (props.VOData.order.billing_address)
        setbillingDetails({
          address_country: props.VOData.order.billing_address.virtual_order_address_country,
          address_zipcode: props.VOData.order.billing_address.virtual_order_address_zipcode,
          address_state: props.VOData.order.billing_address.virtual_order_address_state,
          address_city: props.VOData.order.billing_address.virtual_order_address_city,
          address_address: props.VOData.order.billing_address.virtual_order_address_address,
        })
  }, [props.VOData])

  const billingDetailsync = () => {
    SDClient.endpoint("virtual_order_set_billing_address", { ...billingDetails }).then(
      (data) => {
        if (data.message.action) {
          setValidationTrace({});
        } else {
          if (data.message.reason == "MESSAGE_VALIDATION_ERROR") {
            setValidationTrace(data.message.trace);
          }
        }
      },
    )
  }

  const debounce = () => {
    if (props.globalLoading.billingDebounce) {
      clearTimeout(debounceLockID);
    }
    setDebounceLockID(setTimeout(() => {
      billingDetailsync();
      props.setGobalLoading({
        ...props.globalLoading, billingDebounce: false
      });
    }, 1000));
    props.setGobalLoading({
      ...props.globalLoading, billingDebounce: true
    });
  }

  const showbillingDetailsForm = () => {
    return (
      <div className="py-1">
        <div>
          <div className=" para-one">Country</div>
          <input
            type="text"
            name="virtual_order_profile_first_name"
            className="uk-input"
            value={billingDetails.address_country}
            onChange={(e) => { setbillingDetails({ ...billingDetails, address_country: e.target.value }) }}
          />
          {/* <select className="uk-input" value={billingDetails.address_country} onChange={(e) => { setbillingDetails({ ...billingDetails, address_country: e.target.value }) }}>
            <option value="india">India</option>
          </select> */}
          {/* {validationTrace.address_country ? (<h6 className="uk-text-danger uk-margin-small"> Please specify a valid country name. </h6>) : <div className="uk-margin-small"></div>} */}
          <h6 className={validationTrace.address_country ? "failed uk-text-danger " : "passed uk-text-danger "}> Please specify a valid country name. </h6>
        </div>
        <div>
          <label className=" para-one">Address</label>
          <input
            type="text"
            name="virtual_order_profile_first_name"
            className="uk-input"
            value={billingDetails.address_address}
            onChange={(e) => { setbillingDetails({ ...billingDetails, address_address: e.target.value }) }}
          />
          {/* {validationTrace.address_address ? (<h6 className="uk-text-danger uk-margin-small"> Please specify an address for billing address.</h6>) : <div className="uk-margin-small"></div>} */}
          <h6 className={validationTrace.address_address ? "failed uk-text-danger " : "passed uk-text-danger "}> Please specify an address for billing address.</h6>
        </div>
        <div>
          <div className=" para-one">City </div>
          <input
            type="text"
            name="virtual_order_profile_first_name"
            className="uk-input"
            value={billingDetails.address_city}
            onChange={(e) => { setbillingDetails({ ...billingDetails, address_city: e.target.value }) }}
          />
          {/* {validationTrace.address_city ? (<h6 className="uk-text-danger uk-margin-small"> Please specify a valid city for billing address.</h6>) : <div className="uk-margin-small"></div>} */}
          <h6 className={validationTrace.address_city ? "failed uk-text-danger " : "passed uk-text-danger "}> Please specify a valid city for billing address.</h6>
        </div>
        <div>
          <div className=" para-one">State</div>
          <input
            type="text"
            name="virtual_order_profile_first_name"
            className="uk-input"
            value={billingDetails.address_state}
            onChange={(e) => { setbillingDetails({ ...billingDetails, address_state: e.target.value }) }}
          />
          {/* {validationTrace.address_state ? (<h6 className="uk-text-danger uk-margin-small"> Please specify a valid state for billing address. </h6>) : <div className="uk-margin-small"></div>} */}
          <h6 className={validationTrace.address_state ? "failed uk-text-danger " : "passed uk-text-danger "}> Please specify a valid state for billing address. </h6>
        </div>
        <div>
          <div className=" para-one">Postal code</div>
          <input
            type="text"
            name="virtual_order_profile_first_name"
            className="uk-input"
            value={billingDetails.address_zipcode}
            onChange={(e) => { setbillingDetails({ ...billingDetails, address_zipcode: e.target.value }) }}
          />
          {/* {validationTrace.address_zipcode ? (<h6 className="uk-text-danger uk-margin-small"> Please specify a valid pin code for billing address. </h6>) : <div className="uk-margin-small"></div>} */}
          <h6 className={validationTrace.address_zipcode ? "failed uk-text-danger " : "passed uk-text-danger "}> Please specify a valid pin code for billing address. </h6>
        </div>
      </div >
    )
  }

  const showLoadingInputs = () => {
    return (
      <div className="py-1">
        <div className="form-group ">
          <div className=" para-one">Country</div>
          <input
            type="text" disabled
            name="virtual_order_profile_first_name"
            className="uk-input"
          />
        </div>
        <div className="form-group ">
          <div className=" para-one">Address</div>
          <input
            type="text" disabled
            name="virtual_order_profile_first_name"
            className="uk-input"
          />
        </div>
        <div className="form-group ">
          <div className=" para-one">City </div>
          <input
            type="text" disabled
            name="virtual_order_profile_first_name"
            className="uk-input"
          />
        </div>
        <div className="form-group ">
          <div className=" para-one">State</div>
          <input
            type="text" disabled
            name="virtual_order_profile_first_name"
            className="uk-input"
          />
        </div>
        <div className="form-group ">
          <div className=" para-one">Postal code</div>
          <input
            type="text" disabled
            name="virtual_order_profile_first_name"
            className="uk-input"
          />
        </div>
      </div>
    )
  }

  const differentBillingAddressRequsted = (e) => {
    if (!props.sameAddress) {
      // setBillingAddressToShippingAddress();
    }
    props.setSameAddress(!props.sameAddress);
    props.updateVO();
  }

  return (
    <div id="CheckoutBillingAddressBlock" className="bg-white border uk-padding-small uk-margin-bottom">
      <div className="d-flex justify-content-between">
        <p className="uk-text-lead text-uppercase para-one uk-margin-small-bottom uk-text-bold"><b>Billing Details</b></p>
        <div className="mt-1">
          {props.globalLoading.billingDebounce ? (<Loader />) : ""}
        </div>
      </div>
      <div className="form-group d-inline-flex mb-3">
        <input
          type="checkbox"
          className="uk-checkbox"
          name="same_shipping_address"
          id="same_shipping_address"
          checked={props.sameAddress}
          onChange={differentBillingAddressRequsted}
        />
        <label htmlFor="same_shipping_address" className="uk-margin-remove">
          <p className="text-left uk-margin-remove">
            Use my shipping address as billing address.
          </p>
        </label>
      </div>
      {!props.sameAddress ? (props.VOData.order ? showbillingDetailsForm() : showLoadingInputs()) : ""}
    </div>
  )
}


export default BillingAddressDetails;
