import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';


const SearchItems = (props) => {
    const [imageLoader, setImageLoader] = useState(true);
    const [images, setImages] = useState("")
    const inr = new Intl.NumberFormat('en-IN', { style: 'decimal' })

    const { product, index } = props

    const loadingPreview = () => {
        return (
            <div className="card-text placeholder-glow">
                <div className="d-flex align-items-start">
                    <div className="placeholder" style={{ height: "104px" }}></div>
                </div>
            </div>
        )
    }
    useEffect(() => {
        if (product.variants[0])
            setImages(product.variants[0].image.split(","))
    }, [])

    const img_fallback = (event) => {
        // if (proxyFailed)
        event.target.parentNode.childNodes.forEach(function (node) {
            if (node.nodeName == "SOURCE") node.srcset = window.no_image;
            if (node.nodeName == "IMG") node.src = window.no_image;
        });
        // else
        // setProxyFailed(true);
    }

    return (
        <div key={index} className=" uk-padding-small uk-margin-remove uk-padding-remove-bottom uk-padding-remove-right">
            <Link onClick={() => props.setShowModal(false)} to={`${process.env.PUBLIC_URL}/${product.value}`}>
                <div key={index} className="uk-card-default">
                    <div data-uk-grid>
                        <div className="uk-width-1-4 uk-padding-remove">
                            {imageLoader ? loadingPreview() : ""}
                            <div className="uk-inline-clip uk-transition-toggle uk-light" tabIndex="0">
                                <picture>
                                    {/* {create_sources(srcset, url_1)} */}
                                    {/* <img src={proxy_resize(300, 300, url_1)} alt={product.name} */}
                                    <img src={`${images[0]}?resize=88,88&compress=50`}
                                        alt={product.name}
                                        onError={img_fallback}
                                        loading='lazy'
                                        onLoad={() => setImageLoader(false)}
                                    />
                                </picture>
                                <picture
                                    className='uk-transition-scale-up uk-position-cover'>
                                    {/* {create_sources(srcset, url_2)} */}
                                    {/* <img src={proxy_resize(300, 300, url_2)}  */}
                                    <img src={(images[1] ? images[1] : images[0]) + '?resize=88,88&compress=50'}
                                        alt={product.name}
                                        onError={img_fallback} loading='lazy'
                                        onLoad={() => setImageLoader(false)}
                                    />
                                </picture>
                            </div>
                        </div>
                        <div className="uk-width-3-4 uk-padding-small uk-flex uk-flex-middle py-0">
                            <div className="listing-page-content uk-text-left">
                                <h5 className="uk-width-expand my-1 tw-line-clamp-2 uk-visible@l">{product.name.toUpperCase()}</h5>
                                <small className="uk-width-expand tw-line-clamp-2 uk-hidden@l" style={{color:"#722257"}}>{product.name.toUpperCase()}</small>                                {product.variants[0].offerprice ?
                                    <div>₹ {inr.format(product.variants[0].offerprice)}
                                    </div> : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </Link >
        </div>
    )
}

export default SearchItems;
