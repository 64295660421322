import React, { useEffect, useState } from "react";
import SDClient from "../../../sd_core/client";
import Loader from "../../common/spinner"

function UserDetails(props) {

  // const [debounceLock, setDebounceLock] = useState(false);
  const [debounceLockID, setDebounceLockID] = useState(-1);
  // const [validationTrace, setValidationTrace] = useState({});
  // const [props.userDetails, props.setUserDetails] = useState({})

  // useEffect(() => {
  //   // console.log("User Details Got Change in validationTrace", props.validationTrace)
  //   setValidationTrace(props.validationTrace);
  // }, [props.validationTrace])

  useEffect(() => {
    if (props.profile.length > 0)
      props.setUserDetails({
        first_name: props.profile.first_name,
        last_name: props.profile.last_name,
        email: props.profile.email,
        phone: props.profile.phone,
      })
  }, [props.profile])

  useEffect(() => {
    // console.log("User Details Changed", props.userDetails)
    if (debounceLockID == -1) {
      setDebounceLockID(-2)
      return
    }
    debounce();
  }, [props.userDetails])


  useEffect(() => {
    // console.log("VOData Changed", props.VOData)
    if (props.VOData.order)
      if (props.VOData.order.user_details)
        props.setUserDetails({
          first_name: props.VOData.order.user_details.virtual_order_profile_first_name,
          last_name: props.VOData.order.user_details.virtual_order_profile_last_name,
          email: props.VOData.order.user_details.virtual_order_profile_email,
          phone: props.VOData.order.user_details.virtual_order_profile_phone,
        })
  }, [props.VOData])

  const userDetailSync = () => {
    SDClient.endpoint("virtual_order_set_user_details", { ...props.userDetails }).then(
      (data) => {
        if (data.message.action) {
          props.setValidationTrace({ ...props.validationTrace, user: {}, });
        } else {
          if (data.message.reason == "MESSAGE_VALIDATION_ERROR") {
            props.setValidationTrace({ ...props.validationTrace, user: data.message.trace, });
          }
        }
      },
      // console.log
    )
  }

  const debounce = () => {
    if (props.globalLoading.userDebounce) {
      clearTimeout(debounceLockID);
    }
    setDebounceLockID(setTimeout(() => {
      // console.log("DEBOUNCE CALLBACK!")
      userDetailSync();
      props.setGobalLoading({
        ...props.globalLoading, userDebounce: false
      });
    }, 2000));
    props.setGobalLoading({
      ...props.globalLoading, userDebounce: true
    });
  }

  const showUserDetailsForm = () => {

    return (
      <div>
        <div>
          <div className=" para-one">First Name</div>
          <input
            type="text"
            name="virtual_order_profile_first_name"
            className="uk-input"
            value={props.userDetails.first_name}
            onChange={(e) => { props.setUserDetails({ ...props.userDetails, first_name: e.target.value }) }}
          />
          {/* {props.validationTrace.user.first_name ? (<h6 className="uk-text-danger uk-margin-small"> Please enter your first name. </h6>) : null} */}
          <h6 className={props.validationTrace.user.first_name ? "failed uk-text-danger " : "passed uk-text-danger "}> Please enter your first name. </h6>
        </div>
        <div>
          <div className=" para-one">Last Name</div>
          <input
            type="text"
            name="virtual_order_profile_first_name"
            className="uk-input"
            value={props.userDetails.last_name}
            onChange={(e) => { props.setUserDetails({ ...props.userDetails, last_name: e.target.value }) }}
          />
          {/* {props.validationTrace.user.last_name ? (<h6 className="uk-text-danger uk-margin-small"> Please enter your last name. </h6>) : null} */}
          <h6 className={props.validationTrace.user.last_name ? "failed uk-text-danger " : "passed uk-text-danger "}> Please enter your last name. </h6>
        </div>
        <div>
          <div className=" para-one">Email Address</div>
          <input
            type="email"
            name="virtual_order_profile_first_name"
            className="uk-input"
            value={props.userDetails.email}
            onChange={(e) => { props.setUserDetails({ ...props.userDetails, email: e.target.value }) }}
          />
          {/* {props.validationTrace.user.email ? (<h6 className="uk-text-danger uk-margin-small"> Please use a valid email address. </h6>) : null} */}
          <h6 className={props.validationTrace.user.email ? "failed uk-text-danger " : "passed uk-text-danger "}> Please use a valid email address. </h6>
        </div>
        <div>
          <div className=" para-one">Phone Number</div>
          <input
            type="tel"
            name="virtual_order_profile_first_name"
            className="uk-input"
            value={props.userDetails.phone}
            onChange={(e) => { props.setUserDetails({ ...props.userDetails, phone: e.target.value }) }}
          />
          {/* {props.validationTrace.user.phone ? (<h6 className="uk-text-danger uk-margin-small"> Please use a valid phone number. </h6>) : null} */}
          <h6 className={props.validationTrace.user.phone ? "failed uk-text-danger " : "passed uk-text-danger "}> Please use a valid phone number. </h6>
        </div>
      </div>
    )
  }

  const showLoadingInputs = () => {
    return (
      <div className="py-1">
        <div className="form-group ">
          <div className=" para-one">First Name</div>
          <input
            type="text" disabled
            name="virtual_order_profile_first_name"
            className="uk-input"
          />
        </div>
        <div className="form-group ">
          <div className=" para-one">Last Name</div>
          <input
            type="text" disabled
            name="virtual_order_profile_first_name"
            className="uk-input"
          />
        </div>
        <div className="form-group ">
          <div className=" para-one">Email Address</div>
          <input
            type="text" disabled
            name="virtual_order_profile_first_name"
            className="uk-input"
          />
        </div>
        <div className="form-group ">
          <div className=" para-one">Phone Number</div>
          <input
            type="text" disabled
            name="virtual_order_profile_first_name"
            className="uk-input"
          />
        </div>
      </div>
    )
  }

  return (
    <div className="bg-white border uk-padding-small uk-margin-bottom">
      <div className="d-flex justify-content-between">
        <small className="uk-text-lead text-uppercase para-one uk-margin-small-bottom"><b>User Details</b></small>
        <div className="mt-1">
          {props.globalLoading.userDebounce || !props.VOData.order ? (<Loader />) : ""}
        </div>
      </div>
      {props.VOData.order ? showUserDetailsForm() : showLoadingInputs()}
      {/* <div className="form-group ">
        <div className=" para-one">Email</div>
        <input
          type="email"
          name="virtual_order_profile_email"
          className="uk-input"
          value={props.props.userDetails.virtual_order_profile_email}
          // onChange={(e) => props.handleChange(e, 1)}
        />
        {props.validator.current.message("virtual_order_profile_email", props.props.userDetails.email, "required|email")}
      </div>
      <div className="form-group ">
        <div className=" para-one">Phone</div>
        <input
          type="tel"
          name="virtual_order_profile_phone"
          className="uk-input"
          value={props.props.userDetails.virtual_order_profile_phone}
          // onChange={(e) => props.handleChange(e, 1)}
        />
        {props.validator.current.message("virtual_order_profile_phone", props.props.userDetails.phone, "required|phone|min:10|max:10")}
      </div> */}
    </div >
  );
}

export default UserDetails;
