import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import CartPage from "../components/common/headers/common/cart-header";
import { removeFromCart } from "../actions";
import { getCartTotal } from "../services";

const CartContainer = ({ cartList, total, symbol, removeFromCart }) => {
  const [button, setButton] = useState("Check For Price Changes")

  useEffect(() => {
    if (button == "please wait...")
      setButton("Price updated")
  }, [cartList]);

  return (
    <li className="onhover-div mobile-cart uk-margin-small-left">
      <div className="uk-flex-inline">
        <Link to={`${process.env.PUBLIC_URL}/cart`}>
          <img
            src={`${process.env.PUBLIC_URL}` + "assets/images/new_template/images/layer-2.svg"}
            alt=""
            width="14"
          />
        </Link>
        {cartList.length > 0 ? (<h5 className="ml-1 my-0"style={{position:"absolute", bottom:"5px",right:"-20px"}}>({cartList.length})</h5>) : ("")}
      </div>
      <ul className="show-div shopping-cart p-3 shadow border rounded">
        {cartList.slice().reverse().map((item, index) => (
          <CartPage
            key={index}
            item={item}
            total={total}
            symbol={symbol}
            removeFromMiniCart={removeFromCart}
          />
        ))}
        {cartList.length > 0 ? (
          <div>
            <li>
              <div className="total">
                <h5 className="uk-flex uk-flex-between text-secondary para-one">
                  subtotal :
                  <b>{symbol}{total}</b>
                </h5>
              </div>
            </li>
            <li>
              <div className="uk-flex uk-flex-between">
                <Link to={`${process.env.PUBLIC_URL}/cart`} className="view-cart">
                  <b>VIEW CART</b>
                </Link>
                <Link
                  to={`${process.env.PUBLIC_URL}/checkout`}
                  className="view-cart"
                >
                  <b>CHECKOUT</b>
                </Link>
              </div>
            </li>
          </div>
        ) : (
          <li className="d-flex justify-content-center align-items-center">
            <h5 className="m-0 py-3">Your cart is currently empty.</h5>
          </li>
        )}
      </ul>
    </li>
  );
}

function mapStateToProps(state) {
  return {
    cartList: state.cartList.cart,
    extendedItems: state.cartList.extended_cart,
    total: getCartTotal(state.cartList.cart),
    symbol: state.data.symbol
  };
}
export default connect(mapStateToProps, { removeFromCart })(CartContainer);