import React from "react";
import { Link } from "react-router-dom";

import './css/navigation.css'
// import './css/navbar.css'

const Navbar = (props) => {

    const handleDropDownNavBar = (list, i) => {
        return (
            <li key={i} className="dropdown">
                <Link to={`${process.env.PUBLIC_URL}/` + list.link} className="dropbtn">
                    {list.heading}
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <polyline
                            fill="none"
                            stroke="#000"
                            strokeWidth="1.5"
                            points="16 7 10 13 4 7"
                        />
                    </svg>
                </Link>
                <div className="dropdown-content dropdown-content_links">
                    <div className="menu-list-row">
                        <div className="dropdown-expand">
                            <div className="menu__list-expand">
                                {list.subCategoryCol0 &&
                                    <div className="menu__list-expand-col menu__list-expand-col-0 uk-padding-remove">
                                        <ul>
                                            {list.subCategoryCol0.map((list, i) => (
                                                <li key={i}>
                                                    <Link to={`${process.env.PUBLIC_URL}/` + list.link} className="togglebtn">
                                                        {list.category}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul >
                                    </div>}
                                <div className="menu__list-expand-col menu__list-expand-col-1 uk-flex uk-flex-column uk-flex-between">
                                    <ul>
                                        {list.subCategoryCol1.list.map((list, i) => (
                                            <li key={i}>
                                                <Link
                                                    to={`${process.env.PUBLIC_URL}/` + list.link}
                                                    className={list.type == "heading" ?
                                                        "heading uk-margin-top uk-text-bold uk-padding-remove-bottom" :
                                                        "uk-padding-small uk-padding-remove-bottom uk-text-center"}                                                >
                                                    {list.category}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul >
                                    {list.subCategoryCol1.link ?
                                        <Link to={`${process.env.PUBLIC_URL}/` + list.subCategoryCol1.link.link} className="quick-link">
                                            {list.subCategoryCol1.link.name}
                                        </Link> : ""}
                                </div>
                                <div className="menu__list-expand-col menu__list-expand-col-2 uk-flex uk-flex-column uk-flex-between">
                                    <ul>
                                        {list.subCategoryCol2.list.map((list, i) => (
                                            <li key={i}>
                                                <Link
                                                    to={`${process.env.PUBLIC_URL}/` + list.link}
                                                    className={list.type == "heading" ?
                                                        "heading uk-margin-top uk-text-bold uk-padding-remove-bottom" :
                                                        "uk-padding-small uk-padding-remove-bottom uk-text-center"}
                                                >
                                                    {list.category}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul >
                                    <Link to={`${process.env.PUBLIC_URL}/` + list.subCategoryCol2.link.link} className="quick-link">
                                        {list.subCategoryCol2.link.name}
                                    </Link>
                                </div>
                                <div className="menu__list-expand-col menu__list-expand-col-3  uk-flex uk-flex-column uk-flex-between">
                                    <div className="uk-padding-small uk-padding-remove-bottom">
                                        <img src={`${process.env.PUBLIC_URL}/` + list.src} alt={list.heading} />
                                    </div>
                                    <p className="uk-margin-remove uk-padding-small uk-padding-remove-vertical">{list.text}</p>
                                    <Link to={`${process.env.PUBLIC_URL}/` + list.quickLink.link} className="menu__list-view-all quick-link  uk-padding-remove-top">
                                        {list.quickLink.name}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li >
        )
    }

    const handleLinkNavBar = (list, i) => {
        return (
            <li key={i} className="navlist">
                <Link to={`${process.env.PUBLIC_URL}/` + list.link} className={(list.tag == "animate") ? "ribbon" : ""}>{list.heading}</Link>
            </li>
        )
    }

    const handleAnchorNavBar = (list, i) => {
        return (
            <li key={i}>
                <a href={list.href} target="_blank" rel="noopener noreferrer" className="dropdown">
                    {list.heading}
                </a>
            </li>
        )
    }

    return (
        <div className="navigation topnav p-0 pb-md-2" id="myTopnav">
            <ul>
                {props.navBar.map((list, i) => {
                    if (list.type == "dropdown")
                        return (
                            handleDropDownNavBar(list, i)
                        )
                    else if (list.type == "link")
                        return (
                            handleLinkNavBar(list, i)
                        )
                    else if (list.type == "anchor")
                        return (
                            handleAnchorNavBar(list, i)
                        )
                })}
            </ul>

            {/* Mobile sidebar button  */}

            <a
                className="icon"
                uk-navbar-toggle-icon=""
                uk-toggle="target: #offcanvas-nav-primary"
                aria-expanded="false"
            />
        </div>
    )
}

export default Navbar