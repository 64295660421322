import React, { useState } from 'react';

import '../css/MobileFilterPanel.css'
import Filter from './filter/filter';

const MobileFilterPanel = (props) => {

    const {
        facetValues,
        selectedFilters,
        resetFilters,
        facetStatsValues,
        selectedRangeFilters,
        setSelectedRangeFilters,
        setSelectedFilters,
        showModal,
        setShowModal,
        searchQuery,
        selectedSorting,
        setSelectedSorting,
        loadRange,
        setLoadRange
    } = props;

    return (
        <div className="uk-hidden@l">
            <div className="uk-width-expand@m uk-padding-remove">
                <h2 className="uk-h2 p-2 uk-text-left">{props.title}</h2>
            </div>
            <div data-uk-grid className='px-sm-2'>
                <button className="uk-width-1-2 uk-button uk-button-default uk-width-1-1 uk-margin-small-bottom uk-flex uk-flex-middle" onClick={() => setShowModal(true)}>
                    <small className="uk-text-left uk-width-1-1 uk-margin-remove">Filter</small>
                    <a className="uk-text-right" href="javascript:void(0)" data-uk-icon="settings"></a>
                </button>
                <div className="uk-width-1-2 uk-button-group">
                    <div className="uk-inline w-100">
                        <button className="uk-button uk-button-default uk-flex uk-flex-between w-100" type="button">
                            <small>{window.filterOption.algolia_sorting.filter(item => item.value == selectedSorting)[0].name}</small>
                            <a href="javascript:void(0)" className="uk-align-center uk-margin-remove">
                                <span uk-icon="icon: chevron-down" />
                            </a>
                        </button>
                        <div data-uk-dropdown="mode: click; pos: bottom-center; target: !.uk-button-group;">
                            <ul className="uk-nav uk-dropdown-nav">
                                {window.filterOption.algolia_sorting.map((element, i) => (
                                    <li
                                        key={i} className="d-block uk-dropdown-close"
                                        onClick={() => setSelectedSorting(element.value)}>
                                        <a href="javascript:void(0)">{element.name}</a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {
                showModal ?
                    <div className="back-drop-search">
                        <div className="uk-panel uk-panel-scrollable h-100">
                            <div className="uk-flex uk-flex-right text-danger">
                                <button className="uk-button-default text-muted uk-h4 bg-light mb-2" type="button" onClick={() => setShowModal(false)}>x</button>
                            </div>
                            <div className="uk-width-1-1 side-bar-listing uk-background-default uk-padding-small h-100">
                                <Filter
                                    facetValues={facetValues}
                                    selectedFilters={selectedFilters}
                                    resetFilters={resetFilters}
                                    facetStatsValues={facetStatsValues}
                                    selectedRangeFilters={selectedRangeFilters}
                                    setSelectedRangeFilters={setSelectedRangeFilters}
                                    setSelectedFilters={setSelectedFilters}
                                    setShowModal={setShowModal}
                                    searchQuery={searchQuery}
                                    loadRange={loadRange}
                                    setLoadRange={setLoadRange}
                                />
                            </div>
                        </div>
                    </div> : ""
            }
        </div >
    )
}

export default MobileFilterPanel;