import {
    RECEIVE_CAREER_DEPARTMENT,
    RECEIVE_CAREER_POSITION
} from "../constants/ActionTypes";

const initialState = {
    department: [],
    position: []
}

const CareerReducer = (state = initialState, action) => {
    switch (action.type) {

        case RECEIVE_CAREER_DEPARTMENT:
            return {
                ...state,
                department: action.payload.data
            };
        case RECEIVE_CAREER_POSITION:
            return {
                ...state,
                position: action.payload.data
            };
        default:
            return state;
    }
}

export default CareerReducer;