import React, { useEffect, useState, useCallback } from 'react';
import algoliasearch from 'algoliasearch';

import { connect } from 'react-redux';

import Breadcrumbs from '../new_template_common/breadcrumb/BreadCrumb'
import MobileFilterPanel from './components/MobileFilterPanel';
import ListingPanel from './components/ListingPanel';
import Filter from './components/filter/filter';

import store from '../../store';
import { getAllCategories } from "../../actions";

import './css/lisitng.css'
import { Helmet } from 'react-helmet';
import axios from 'axios';

const Listing = (props) => {
  const hitsPerPage = 40

  const [fetchProducts, setFetchProducts] = useState(false);
  const [shimmer, setShimmer] = useState(true);
  const [productShimmer, setProductShimmer] = useState(true);
  const [showModal, setShowModal] = useState(false)
  const [loadItems, setLoadItems] = useState(false)
  const [loadRange, setLoadRange] = useState(false)

  const [searchQuery, setSearchQuery] = useState("");
  const [facetQuery, setFacetQuery] = useState([]);
  const [rangeQuery, setRangeQuery] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [selectedPage, setSelectedPage] = useState(0);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedRangeFilters, setSelectedRangeFilters] = useState({});
  const [selectedSorting, setSelectedSorting] = useState(window.rc.algoliasearch.defaultIndex);

  const [facetFetchValues, setFacetFetchValues] = useState({});
  const [facetValues, setFacetValues] = useState({});
  const [facetStatsValues, setFacetStatsValues] = useState({});

  // const searchClient = algoliasearch(window.rc.algoliasearch.appId, window.rc.algoliasearch.apiKey);
  // let index = searchClient.initIndex(window.rc.algoliasearch.defaultIndex);

  const refresh = () => {
    setSelectedFilters({})
    setSelectedRangeFilters({})
    setSearchQuery("")
    setFacetQuery([]);
    setRangeQuery([]);
    setFacetStatsValues({})
    setLoadRange(false)
  }
  useEffect(() => {

    refresh()

    const element = document.getElementsByClassName("site-main");
    element.scrollTop = 0;

    store.dispatch({ type: "RECEIVE_DB_CATEGORY", payload: "" })
    props.getAllCategories(props.match.params.id1 == "nose-pins" ? "nosepins" : props.match.params.id1);

    const queryParams = new URLSearchParams(window.location.search);
    const urlFilterList = queryParams.keys()

    let appliedFilters = [];
    let appliedQuery = "";
    let appliedRange = [];

    for (let option of urlFilterList) {
      if (option == "query")
        appliedQuery = queryParams.get(option);
      else if (option == "range")
        appliedRange.push(`variants.offerprice:${queryParams.get(option)}`);
      else if (window.filterOption.facetList.includes(option))
        appliedFilters.push(`${option}:${queryParams.get(option)}`);
    }
    if (window.location.pathname == "/trending")
      appliedFilters.push([`trending:true`]);
    else if (props.match.params.id1)
      appliedQuery = props.match.params.id1 == "nose-pins" ? "nosepins" : props.match.params.id1;

    if (appliedQuery || appliedFilters.length != 0 || appliedRange.length != 0) {
      setSearchQuery(appliedQuery)
      setFacetQuery(appliedFilters);
      setRangeQuery(appliedRange);
    }
    else {
      setSearchQuery("")
      setFacetQuery([]);
    }
    if (selectedSorting == "")
      setSelectedSorting(window.filterOption.algolia_sorting[0].value)
    setFetchProducts(true)
  }, [props.location.pathname, props.location.search])

  const applyFilters = () => {
    var appliedFilters = [];
    for (const key in selectedFilters) {
      const formattedFilter = []
      selectedFilters[key].forEach(element => {
        formattedFilter.push(`${key}:${element}`);
      });
      appliedFilters.push(formattedFilter);
    }
    setFacetQuery(appliedFilters);
  };

  const applyRangeFilters = (selectedRangeFilters) => {
    if (Object.keys(selectedRangeFilters).length != 0) {
      const formattedFilter = [`variants.offerprice:${selectedRangeFilters.value.min} TO ${selectedRangeFilters.value.max}`]
      setRangeQuery(formattedFilter);
    }
  };

  const fetchMoreItems = () => {
    if (selectedPage < totalPages) {
      setSelectedPage(selectedPage + 1)
      setLoadItems(true)
    }
  }

  const search = () => {
    axios.post(global.postUrl + "restproxy", {
      "headers": {
        "X-Algolia-Application-Id": window.rc.algoliasearch.appId,
        "X-Algolia-API-Key": window.rc.algoliasearch.apiKey
      },
      body: {
        query: searchQuery,
        hitsPerPage: hitsPerPage,
        facets: window.filterOption.facetList,
        facetFilters: facetQuery,
        numericFilters: rangeQuery,
        page: selectedPage,
      },
      "url": "https://" + window.rc.algoliasearch.appId + "-dsn.algolia.net/1/indexes/" + selectedSorting + "/query",
      "force": false
    }).then((response) => {
      console.log(response.data);
      let { hits, nbHits, facets, nbPages, facets_stats } = response.data.response;

      hits.map((element) => {
        const random = Math.floor(Math.random() * element.variants.length);
        let firstIndex = element.variants[0];
        element.variants[0] = element.variants[random];
        element.variants.push(firstIndex);
        return element;
      })
      if (loadItems)

        setSearchResults(searchResults.concat(hits));
      else

        setSearchResults(hits);


      setTotalProducts(nbHits);
      setFacetFetchValues(facets);
      setTotalPages(nbPages);
      if (Object.keys(facetStatsValues).length == 0)
        setFacetStatsValues(facets_stats);

      searchFinish()
    }).catch((err) => {
      searchFinish();
    })
  };

  const searchFinish = () => {
    setShimmer(false)
    setProductShimmer(false)
    setLoadItems(false)
  }

  useEffect(() => {
    if (Object.keys(facetValues).length == 0)
      setFacetValues(facetFetchValues);
  }, [facetFetchValues]);

  useEffect(() => {
    if (Object.keys(selectedFilters).length != 0)
      applyFilters();
  }, [selectedFilters]);

  function debounce(func) {
    var timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(undefined, args);
      }, 200);
    };
  }

  const handleFilterRange = useCallback(
    debounce(selectedRangeFilters => {
      applyRangeFilters(selectedRangeFilters);
    }),
    []
  );

  useEffect(() => {
    if (Object.keys(selectedRangeFilters).length != 0) {
      handleFilterRange(selectedRangeFilters)
    }
  }, [selectedRangeFilters]);

  useEffect(() => {
    if (fetchProducts) {
      if (!loadItems)
        setProductShimmer(true)
      // index = searchClient.initIndex(selectedSorting);
      search();
    }
  }, [facetQuery, rangeQuery, selectedPage, searchQuery, selectedSorting]);

  useEffect(() => {
    resetPagination()
  }, [facetQuery, rangeQuery, searchQuery, selectedSorting]);

  const resetPagination = () => {
    setSelectedPage(0)
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
  }

  const resetFilters = () => {
    setFacetQuery("");
    setSelectedFilters({})
    setSelectedRangeFilters({})
    setFacetStatsValues({})
  }

  if (!shimmer)
    return (
      <main className="site-main uk-margin-bottom">
        <Helmet>
          <title>{props.category.seo_title}</title>
          <meta name="description" content={props.category.seo_description} />
          <meta name="keywords" content={props.category.seo_keywords} />
          <meta property="og:site_name" content="Sunny Diamonds" />
          <meta property="og:type" content="Summary" />
          <meta property="og:title" content={props.category.seo_title} />
          <meta
            property="og:description"
            content={props.category.seo_description}
          />
          <meta property="og:url" content={window.location.href} />
          <meta name="twitter:card" content="Summary" />
          <meta name="twitter:site" content="@SunnyDiamond_4c" />
          <meta name="twitter:creator" content="Sunny Diamonds" />
          <meta name="twitter:title" content={props.category.seo_title} />
          <meta
            name="twitter:description"
            content={props.category.seo_description}
          />
        </Helmet>
        <div className="p-0 uk-container uk-container-xlarge px-md-3">
          <Breadcrumbs
            ancestor="Jewellery"
            current={props.match.params.id1}
          />

          <MobileFilterPanel
            facetValues={facetValues}
            selectedFilters={selectedFilters}
            resetFilters={resetFilters}
            facetStatsValues={facetStatsValues}
            selectedRangeFilters={selectedRangeFilters}
            setSelectedRangeFilters={setSelectedRangeFilters}
            setSelectedFilters={setSelectedFilters}
            showModal={showModal}
            setShowModal={setShowModal}
            title={props.category.banner_title}
            searchQuery={searchQuery}
            setSelectedSorting={setSelectedSorting}
            selectedSorting={selectedSorting}
            loadRange={loadRange}
            setLoadRange={setLoadRange}
          />

          <div data-uk-grid id="grid">
            <div className="uk-width-1-1 uk-width-1-5@l uk-padding-remove side-bar-listing uk-visible@l ">
              <div className='sticky-top' style={{ top: '6rem' }}>

                {/* Filter section */}
                <br />
                <Filter
                  facetValues={facetValues}
                  selectedFilters={selectedFilters}
                  resetFilters={resetFilters}
                  facetStatsValues={facetStatsValues}
                  selectedRangeFilters={selectedRangeFilters}
                  setSelectedRangeFilters={setSelectedRangeFilters}
                  setSelectedFilters={setSelectedFilters}
                  setShowModal={setShowModal}
                  searchQuery={searchQuery}
                  loadRange={loadRange}
                  setLoadRange={setLoadRange}
                />
              </div>
            </div>
            <div className="uk-width-1-1 uk-width-4-5@l">

              {/* Listing section  */}

              <ListingPanel
                products={searchResults}
                title={props.category.banner_title}
                description={props.category.banner_content}
                history={props.history}
                totalPages={totalPages}
                setSelectedSorting={setSelectedSorting}
                selectedSorting={selectedSorting}
                totalProducts={totalProducts}
                selectedPage={selectedPage}
                hitsPerPage={hitsPerPage}
                fetchMoreItems={fetchMoreItems}
                loadItems={loadItems}
                productShimmer={productShimmer}
                variantTag={selectedFilters["variants.color"] ? selectedFilters["variants.color"][0] : null}
              />
            </div>
          </div>
        </div>
        <div className='sticky-tag' />
      </main>
    );
  else return (
    <main className="site-main">
      <div className="uk-container uk-container-xlarge uk-padding-remove">
        <div className='py-4 mx-0 row'>
          <div className="py-2 card-text placeholder-glow col-3 d-lg-block d-none">
            <div className="px-1 mb-2 d-flex">
              <p className="my-0 mr-1 placeholder col-md-4 col-2" style={{ height: "1rem" }}></p>
              <span className="text-muted"> /</span>
              <p className="mx-1 my-0 placeholder col-md-8 col-4" style={{ height: "1rem" }}></p>
            </div>
            <br></br>
            <p className="mx-1 my-0 placeholder col-6" style={{ height: "1rem" }}></p> <br></br>
            <p className="mx-1 my-0 placeholder col-4" style={{ height: "1rem" }}></p> <br></br>
            <p className="mx-1 my-0 placeholder col-5" style={{ height: "1rem" }}></p>
            <hr></hr>
            <p className="mx-1 my-0 placeholder col-3" style={{ height: "1rem" }}></p>
            <p className="mx-1 my-0 placeholder col-4" style={{ height: "1rem" }}></p> <br></br>
            <p className="mx-1 my-0 placeholder col-2" style={{ height: "1rem" }}></p>
            <hr></hr>
            <div className='py-5 placeholder col-8 md:tw-h-96'>
            </div>
          </div>
          <div className="p-0 card-text placeholder-glow col-lg-9 col-12">
            <p className="mx-2 my-0 placeholder col-8" style={{ height: "2.5rem" }}></p>
            <p className="mx-2 my-0 mt-2 placeholder col-9" style={{ height: "1rem" }}></p>
            <p className="mx-2 my-0 mt-2 text-center placeholder col-3 d-block d-lg-none" style={{ height: "1rem" }}></p>
            <div className='mx-0 mt-4 row w-100'>
              {[...Array(8).keys()].map(() => (
                <div className='p-2 col-6 col-md-4 col-lg-3'>
                  <div className="placeholder placeholder-thumb w-100" style={{ height: "17rem" }}>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </main>
  )
};


const mapStateToProps = state => ({
  category: state.data.category,
});

export default connect(mapStateToProps, { getAllCategories })(Listing);
