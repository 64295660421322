import React from "react";

const PriceBreakUp = (props) => {
    const { product, symbol } = props
    const totalAmount = product.metal_price + product.diamond_price + product.gemstone_price + product.making_charge

    const inr = new Intl.NumberFormat('en-IN', { style: 'decimal' })

    return (
        <div className="uk-inline uk-text-default">
            <a href="javascript:void(0)">Price Breakup <span data-uk-icon="icon:plus; ratio: 0.7" /></a>
            <div
                className="uk-card uk-card-body uk-card-default uk-padding-small "
                data-uk-dropdown="animation: uk-animation-slide-top-small; animate-out: false; duration: 700; mode:click; container:true;boundary:true">
                <table className="uk-table uk-table-small uk-table-divider">
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th>Rate</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Metal price</td>
                            <td>{symbol} {inr.format(product.metal_price)}</td>
                        </tr>
                        <tr>
                            <td>Diamond price</td>
                            <td>{symbol} {inr.format(product.diamond_price)}</td>
                        </tr>
                        {product.gemstone_price ?
                            <tr>
                                <td>Gemstone price</td>
                                <td>{symbol} {inr.format(product.gemstone_price)}</td>
                            </tr> : ""}
                        <tr>
                            <td>Making charge</td>
                            <td>{symbol} {inr.format(product.making_charge)}</td>
                        </tr>
                        <tr>
                            <td>GST({product.gst}%)</td>
                            <td>{symbol} {inr.format((totalAmount * (product.gst / 100)))}</td>
                        </tr>
                        <tr>
                            <td><b>Total</b></td>
                            <td><b>{symbol} {inr.format(product.offerprice)}</b></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default PriceBreakUp