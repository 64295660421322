import React from "react";
import { Link } from "react-router-dom";

import "./css/mobile-sidebar.css"

const MobileSideNavbar = () => {

    const handleDropDownNavBar = (list, i, checkUrlActive) => {
        return (
            <li key={i} className="uk-margin-small-bottom">
                <Link className={"uk-accordion-title side-bar para-one " + checkUrlActive} to={`${process.env.PUBLIC_URL}` + list.link} aria-expanded="true">
                    {list.heading}
                </Link>
                <div className="uk-accordion-content mt-0 ml-4">
                    <ul className="uk-nav uk-dropdown-nav uk-nav-parent-icon uk-margin-small-top">
                        {list.links.map((element, i) => (
                            <li key={i} data-uk-toggle="#offcanvas-nav-primary">
                                <Link to={`${process.env.PUBLIC_URL}` + element.link}>{element.name}</Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </li>
        )
    }

    const handleLinkNavBar = (list, i, checkUrlActive) => {
        return (
            <Link key={i} to={`${process.env.PUBLIC_URL}` + list.link}>
                <li data-uk-toggle="#offcanvas-nav-primary">
                    <p className={"none-link uk-margin-small-bottom " + checkUrlActive}>{list.heading}</p>
                </li>
            </Link>
        )
    }
    const handleAnchorNavBar = (list, i, checkUrlActive) => {
        return (
            <a key={i} href={list.href} target="_blank" rel="noopener noreferrer">
                <li data-uk-toggle="#offcanvas-nav-primary">
                    <p className={"none-link uk-margin-small-bottom " + checkUrlActive}>{list.heading}</p>
                </li>
            </a>
        )
    }

    return (
        <div
            id="offcanvas-nav-primary"
            data-uk-offcanvas="overlay: true"
            className="uk-offcanvas uk-offcanvas-overlay"
            tabIndex="-1"
        >
            <div className="uk-offcanvas-bar uk-flex uk-flex-column uk-offcanvas-bar-animation uk-offcanvas-slide">
                <button className="uk-offcanvas-close uk-icon uk-close" type="button" data-uk-close><svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><line fill="none" stroke="#000" strokeWidth="1.1" x1="1" y1="1" x2="13" y2="13"></line><line fill="none" stroke="#000" strokeWidth="1.1" x1="13" y1="1" x2="1" y2="13"></line></svg></button>
                <a className="uk-logo-mobile uk-text-left uk-scrollspy-inview " href="index.html" data-uk-scrollspy="cls: uk-animation-slide-bottom-small; delay: 200">
                    {window.logo.mobile_menubar_logo.map((data, i) => (
                        <div key={i}>
                            <img
                                className="uk-align-center uk-margin-remove-bottom"
                                src={`${process.env.PUBLIC_URL}` + data.image}
                                alt={data.alt}
                                width="180" height="180"
                            />
                        </div>
                    ))}
                    {/* <img
                        className="img-fluid"
                        src={`${process.env.PUBLIC_URL}/assets/images/sunny/logo/logo_white.svg`}
                    /> */}
                </a>

                <div className="heading-icon">
                    <span className="sidemenubar-hr-line">&nbsp;</span>
                    <span className="diamondstand-icon">&nbsp;</span>
                    <span className="sidemenubar-hr-line">&nbsp;</span>
                </div>

                <ul className="uk-accordion-menu-bar uk-accordion mt-3" data-uk-accordion="multiple: true; duration:1000">
                    {window.navLinksTopMobile.navBar.map((list, i) => {
                        const urlActive = window.location.pathname == list.link ? "url-active" : ""
                        if (list.type == "dropdown")
                            return (
                                handleDropDownNavBar(list, i, urlActive)
                            )
                        else if (list.type == "link")
                            return (
                                handleLinkNavBar(list, i, urlActive)
                            )
                        else if (list.type == "anchor")
                            return (
                                handleAnchorNavBar(list, i, urlActive)
                            )
                    })}
                </ul>
            </div>
        </div>
    )
}

export default MobileSideNavbar
