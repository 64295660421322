import { ADD_TO_COMPARE, CART_UPDATED, REMOVE_FROM_COMPARE } from "../constants/ActionTypes";
import { toast } from "react-toastify";

export default function compareReducer(
  state = {
    items: []
  },
  action
) {
  switch (action.type) {
    case ADD_TO_COMPARE:
      const productId = action.product.id;
      const variantId = action.variant.id;
      if (state.items.findIndex(product => product.variant.id === variantId) !== -1) {
        const items = state.items.reduce((cartAcc, product) => {
          cartAcc.push({
            ...product,
            // variant: action.variants
          });
          return cartAcc;
        }, []);
        toast.warn("Item already Added");
        return { ...state, items };
      } else {
        toast.success("Item Added to Compare list");
      }
      return { ...state, items: [...state.items, { ...action.product, variant: action.variant }] };

    case CART_UPDATED:
      state.items.forEach(oldCompareItem => {
        action.payload.forEach(newCompareItem => {
          if (oldCompareItem.id === newCompareItem.id) {
            oldCompareItem.variant.offerprice = newCompareItem.variant.offerprice
            oldCompareItem.selectedVariant.offerprice = oldCompareItem.variant.offerprice
            oldCompareItem.active = newCompareItem.deleted ? false : true
            // oldCompareItem.active = false
          }
        })
      });
      return {
        ...state,
        items: [...state.items]
      };

    case REMOVE_FROM_COMPARE:
      return {
        items: state.items.filter(id => id !== action.product_id)
      };
    default:
  }
  return state;
}
