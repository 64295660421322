import React from 'react';
import { Link } from 'react-router-dom';

import './breadcrumb.css'

const Breadcrumbs = (props) => {

    return (
        <ul className="uk-breadcrumb uk-padding-remove text-uppercase d-lg-block d-none my-4">
            <li><Link to={`${process.env.PUBLIC_URL}/`}>Home</Link></li>
            {props.ancestor && <li className={!props.current ? "active" : ""}><span>{props.ancestor}</span></li>}
            {props.current && <li className='active'><span>{props.current}</span></li>}
        </ul >
    )
}

export default Breadcrumbs;