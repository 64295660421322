import {
    CHANGE_CURRENCY,
    RECEIVE_PRODUCTS,
    RECEIVE_PDP,
    RECEIVE_DB_CATEGORY,
    RECEIVE_LOGIN_DETAIL,
    RECEIVE_LOGOUT_DETAIL,
    RECEIVE_SUBSCRIBE,
    RECEIVE_PROFILE,
    RECEIVE_UNIQUE,
    RECEIVE_ORDER_TRACKING_SUBMIT,
    RECEIVE_MY_ORDER,
    RECEIVE_ORDER_PRODUCTS,
    RECEIVE_REQUEST_OTP,
    RECEIVE_VERIFY_OTP,
    SET_LOADER,
    RECEIVE_RELATED_PRODUCTS,
    RECEIVE_CAREER_DETAILS
} from "../constants/ActionTypes";

const initialState = {
    products: [],
    category: [],
    pdp: [],
    symbol: "₹",
    subscribe_data: [],
    profile_data: [],
    unique_data: "",
    order_track: [],
    order_detail: [],
    user_type: "GUEST",
    otp_request: [],
    otp_verify: [],
    related_products: [],
    loader: false,
    career: []
};

const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_PRODUCTS:
            return { ...state, products: action.products };
        case RECEIVE_DB_CATEGORY:
            return {
                ...state,
                category: action.payload
            };
        case RECEIVE_PDP:
            return {
                ...state,
                pdp: action.payload
            };

        case CHANGE_CURRENCY:
            return {
                ...state,
                symbol: action.payload.symbol,
            };
        case RECEIVE_LOGIN_DETAIL:
            return {
                ...state,
                user_type: action.payload.user_type
            };
        case RECEIVE_LOGOUT_DETAIL:
            return {
                ...state,
                user_type: "GUEST"
            };
        case RECEIVE_SUBSCRIBE:
            return {
                ...state,
                subscribe_data: action.payload
            };
        case RECEIVE_PROFILE:
            return {
                ...state,
                profile_data: action.payload
            };
        case RECEIVE_UNIQUE:
            return {
                ...state,
                unique_data: action.payload
            };
        case RECEIVE_ORDER_TRACKING_SUBMIT:
            return {
                ...state,
                order_track: action.payload
            };
        case RECEIVE_ORDER_PRODUCTS:
            return {
                ...state,
                order_products: action.payload
            };
        case RECEIVE_MY_ORDER:
            return {
                ...state,
                order_detail: action.payload
            };

        case RECEIVE_REQUEST_OTP:
            return {
                ...state,
                otp_request: action.payload
            };
        case RECEIVE_VERIFY_OTP:
            return {
                ...state,
                otp_verify: action.payload
            };
        case RECEIVE_RELATED_PRODUCTS:
            return {
                ...state,
                related_products: action.payload
            }
        case SET_LOADER:
            return {
                ...state,
                loader: action.payload
            }
        case RECEIVE_CAREER_DETAILS:
            return {
                ...state,
                career: action.payload
            }
        default:
            return state;
    }
};
export default productReducer;