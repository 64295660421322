import React from 'react';

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.scss';
import "./components/common/index.scss";
import Header from "./components/new_header"

import { toast } from "react-toastify";

// Import custom components
import store from './store';
import { getAllProducts } from './actions'
import Routes from './routes';

class Root extends React.Component {

    constructor() {
        super();
        /* LIVE mode */
        global.postUrl = 'https://sunnydiamonds.com/api/api/';
        global.rzpKey = 'rzp_live_CPpNWhC0ODrQeV';
        global.rzpCallbk = 'https://sunnydiamonds.com/api/api/razorpay/response';
        global.rzpCancel = 'https://sunnydiamonds.com/api/api/razorpay/cancel';
        global.publicUrl = 'https://sunnydiamonds.com/';
        global.reCaptcha = '6LeqaKUfAAAAAJ1RJspvJAojvMjcGi11E3UsMTnF';
        global.rzpGiftCallbk = 'https://sunnydiamonds.com/api/api/giftcards/purchase/confirm';
        global.rzpGiftCancel = 'https://sunnydiamonds.com/api/api/giftcards/purchase/cancel';
        //for assets 
        global.webdisk = 'https://sprdigitallab.com/sdwebdisk/'

        /* STAGING mode */
        // global.postUrl = 'https://sd.sunnydiamonds.com/api/api/';
        // global.rzpKey = 'rzp_test_O6CaPqbNbFTaEJ';
        // global.rzpCallbk = 'https://sd.sunnydiamonds.com/api/api/razorpay/response';
        // global.rzpCancel = 'https://sd.sunnydiamonds.com/api/api/razorpay/cancel';
        // global.publicUrl = 'https://sd.sunnydiamonds.com/';
        // global.reCaptcha = '6LdG_wAgAAAAAJtwvnhJaCPTEpDLXVSdCflRuC0V';
        // global.rzpGiftCallbk = 'https://sd.sunnydiamonds.com/api/api/giftcards/purchase/confirm';
        // global.rzpGiftCancel = 'https://sd.sunnydiamonds.com/api/api/giftcards/purchase/cancel';
        // //for assets 
        // global.webdisk = 'https://sprdigitallab.com/sdstagingwebdisk/'

        /* LOCAL mode */
        // global.postUrl = 'http://192.168.10.52:8080/api/';
        /* global.rzpKey = 'rzp_test_O6CaPqbNbFTaEJ';
        global.rzpCallbk = 'http://192.168.10.52:8080/razorpay/response';
        global.rzpCancel = 'http://192.168.10.52:8080/razorpay/cancel';
        global.publicUrl = 'http://localhost:3000/';
        global.reCaptcha = '6LdG_wAgAAAAAJtwvnhJaCPTEpDLXVSdCflRuC0V';
        global.rzpGiftCallbk = 'http://192.168.10.52:8080/giftcards/purchase/confirm';
        global.rzpGiftCancel = 'http://192.168.10.52:8080/giftcards/purchase/cancel';
        // //for assets 
        global.webdisk = 'https://static.sunnydiamonds.com/images/' */
    }

    componentDidMount() {
        const queryParams = new URLSearchParams(window.location.search);
        const t = queryParams.get('t');
        const c = queryParams.get('c');
        if (t == 1) {
            if (c == 1)
                toast.success("Successfully Subscribed")
            else if (c == 2)
                toast.success("Successfully Unsubscribed")
            queryParams.delete('t');
            queryParams.delete('c');
            window.history.replaceState({}, "", window.location.pathname + "?" + queryParams.toString());
        }
        store.dispatch({ type: "SORT_BY", sort_by: "LowToHigh" })
    }

    render() {
        store.dispatch(getAllProducts());
        return (
            <Provider store={store}>
                <Routes />
            </Provider>
        );
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));


