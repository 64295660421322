import React, { useEffect, useCallback, lazy, Suspense } from "react";

import { BrowserRouter, Route, Switch } from "react-router-dom";


// import Sunny from './components/layouts/sunny-diamonds/main';

//Gift Card Page

// import GiftCard from "./components/pages/Gift-Card/index";
// import GiftPurchaseResponse from "./components/pages/Gift-Card/gift-status-page";

// Features
// import App from "./components/app";
// import Cart from "./components/cart";
// import Compare from "./components/compare/index";
// import Wishlist from "./components/wishlist/index";

// Extra Pages
// import PageNotFound from "./components/pages/404";
// import Login from "./components/pages/login";
// import Register from "./components/pages/register";
// import Search from "./components/pages/search/search";
// import ForgetPassword from "./components/pages/forget-password";
// import CustomerService from "./components/layouts/sunny-diamonds/customer-service";
// import StoreDetails from "./components/pages/storedetails";
// import StoreLoc from "./components/pages/store-locator";
// import Faq from "./components/pages/faq/index";
// import Careers from "./components/pages/Career-page/index";

//Diamond For Everyone
// import DiamondForEveryone from "./components/pages/Diamond-for-everyone/dfe-landing-page";
// import DfeForm from "./components/pages/Diamond-for-everyone/dfe-forms/dfe-form";
// import ActivePlan from "./components/pages/dashboard/active-plan";

//policies
// import PrivacyPolicy from "./components/pages/policy/privacy-policy";
// import TermsAndCondition from "./components/pages/policy/terms-and-condition";
// import MoneyBackPolicy from "./components/pages/policy/money-back";
// import ReturnPolicy from "./components/pages/policy/return-policy";
// import CancellationPolicy from "./components/pages/policy/cancellation";
// import ShippingPolicy from "./components/pages/policy/shipping";
// import CashOnDeliveryPolicy from "./components/pages/policy/cash-on-delivery";
// import ExchangePolicy from "./components/pages/policy/exchange-and-resizing-policy";
// import OldGoldPolicy from "./components/pages/policy/old-gold";
// import DfeTc from "./components/pages/policy/dfe-tc";

//customer
// import OrderTrack from "./components/pages/order-tracking";
// import TrackDetails from "./components/pages/track-details";
// import Success from "./components/pages/success";
// import MyOrderDetails from "./components/pages/dashboard/my_orders";
// import Profile from "./components/pages/dashboard/profile";
// import Dashboard from "./components/pages/dashboard/dashboard";
// import ChangePassword from "./components/pages/dashboard/change_password";

//Education
// import RingSize from "./components/pages/education/ring-size-chart";
// import Education from "./components/pages/education/index";

//News Page
// import NewsPage from "./components/pages/news-page/index";

//Why Sunny Diamonds
// import fairPricing from "./components/pages/why_buy_from_us/fair-pricing";
// import bangleSizeChart from "./components/pages/education/bangle-size-chart";
// import PaymentMethods from "./components/pages/why_buy_from_us/payment-method";
// import WhyFromUs from "./components/pages/why_buy_from_us/why-buy-from-us";
// import giftCardTc from "./components/pages/policy/gift-card-tc";

import { ScrollContext } from "react-router-scroll-4";

import App from "./components/app";
// Checkout dummy
import CheckOut1 from "./components/checkout/upgraded-checkout/index_1";

//new template
// import Listing from "./components/new_listing";
import Listing from "./components/algolia_listing ";
// import NewHomePage from "./components/new_home_page/index";
import PdpDetails from "./components/new_pdp";
import HeaderBody from "./components/new_header/header-body";
import Seo from "./components/layouts/common/seo";

//new CMS Pages
// import NewAboutUs from "./components/pages/about-us/index";

const GiftCard = lazy(() => import("./components/pages/Gift-Card/index"));
const GiftPurchaseResponse = lazy(() =>
  import("./components/pages/Gift-Card/gift-status-page")
);

// const App = lazy(() => import('./components/app'));
const Cart = lazy(() => import("./components/cart"));
const Compare = lazy(() => import("./components/compare/index"));
const Wishlist = lazy(() => import("./components/wishlist/index"));

const PageNotFound = lazy(() => import("./components/pages/404"));
const Login = lazy(() => import("./components/pages/login"));
const Register = lazy(() => import("./components/pages/register"));
const Search = lazy(() => import("./components/pages/search/search"));
const ForgetPassword = lazy(() => import("./components/pages/forget-password"));
const CustomerService = lazy(() =>
  import("./components/layouts/sunny-diamonds/customer-service")
);
const StoreDetails = lazy(() => import("./components/pages/storedetails"));
const StoreLoc = lazy(() => import("./components/pages/store-locator"));
const Faq = lazy(() => import("./components/pages/faq/index"));
const Careers = lazy(() => import("./components/pages/Career-page/index"));

const PrivacyPolicy = lazy(() =>
  import("./components/pages/policy/privacy-policy")
);
const TermsAndCondition = lazy(() =>
  import("./components/pages/policy/terms-and-condition")
);
const MoneyBackPolicy = lazy(() =>
  import("./components/pages/policy/money-back")
);
const ReturnPolicy = lazy(() =>
  import("./components/pages/policy/return-policy")
);
const CancellationPolicy = lazy(() =>
  import("./components/pages/policy/cancellation")
);
const ShippingPolicy = lazy(() => import("./components/pages/policy/shipping"));

const CashOnDeliveryPolicy = lazy(() =>
  import("./components/pages/policy/cash-on-delivery")
);
const ExchangePolicy = lazy(() =>
  import("./components/pages/policy/exchange-and-resizing-policy")
);
const OldGoldPolicy = lazy(() => import("./components/pages/policy/old-gold"));

const GiftCardTc = lazy(() => import("./components/pages/policy/gift-card-tc"));

const DfeTc = lazy(() => import("./components/pages/policy/dfe-tc"));

const GiftVoucherTc = lazy(() => import("./components/pages/policy/gift-voucher-tc"));

const OrderTrack = lazy(() => import("./components/pages/order-tracking"));
const TrackDetails = lazy(() => import("./components/pages/track-details"));
const Success = lazy(() => import("./components/pages/success"));
const MyOrderDetails = lazy(() =>
  import("./components/pages/dashboard/my_orders")
);
const Profile = lazy(() => import("./components/pages/dashboard/profile"));
const Dashboard = lazy(() => import("./components/pages/dashboard/dashboard"));
const ChangePassword = lazy(() =>
  import("./components/pages/dashboard/change_password")
);

const RingSize = lazy(() =>
  import("./components/pages/education/ring-size-chart")
);
const Education = lazy(() => import("./components/pages/education/index"));

const NewsPage = lazy(() => import("./components/pages/news-page/index"));

const fairPricing = lazy(() =>
  import("./components/pages/why_buy_from_us/fair-pricing")
);
const bangleSizeChart = lazy(() =>
  import("./components/pages/education/bangle-size-chart")
);
const PaymentMethods = lazy(() =>
  import("./components/pages/why_buy_from_us/payment-method")
);
const WhyFromUs = lazy(() =>
  import("./components/pages/why_buy_from_us/why-buy-from-us")
);

const NewHomePage = lazy(() => import("./components/new_home_page/index"));
// const PdpDetails = lazy(() => import('./components/new_pdp'));

const NewAboutUs = lazy(() => import("./components/pages/about-us/index"));
const CollectionComponent = lazy(() => import("./collection/Collection"));

const Routes = () => {
  const resetWindowScrollPosition = useCallback(
    () => window.scrollTo(0, 0),
    []
  );

  useEffect(
    () => {
      window.onbeforeunload = function () {
        resetWindowScrollPosition();
      };
    },
    [resetWindowScrollPosition]
  );

  return (
    <BrowserRouter basename={"/"}>
      <Suspense
        fallback={
          <>
            <App>
              <Seo type="sunny-head" />
              <section className="py-2 my-2 d-flex justify-content-center">
                {/* <div class="placeholder-glow">
                  <div className="placeholder placeholder-thumb w-100" />
                </div> */}
              </section>
            </App>
          </>
        }
      >
        <ScrollContext>
          <Switch>
            {/* <Route exact path={`${process.env.PUBLIC_URL}/`} component={Sunny} /> */}
            <App>
              <Switch>
                {/* new home page  */}
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/`}
                  component={NewHomePage}
                />

                {/*Routes For custom Features*/}
                <Route
                  path={`${process.env.PUBLIC_URL}/cart`}
                  component={Cart}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/wishlists`}
                  component={Wishlist}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/compare_list`}
                  component={Compare}
                />

                {/*Routes For Extra Pages*/}
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/locations`}
                  component={StoreLoc}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/locations/:id`}
                  component={StoreDetails}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/careers`}
                  component={Careers}
                />

                {/* <Route
                  path={`${process.env.PUBLIC_URL}/search`}
                  component={Search}
                /> */}
                <Route
                  path={`${process.env.PUBLIC_URL}/customer-service`}
                  component={CustomerService}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/dashboard`}
                  component={Dashboard}
                />
                <Route path={`${process.env.PUBLIC_URL}/faq`} component={Faq} />
                <Route
                  path={`${process.env.PUBLIC_URL}/sales/guest/form`}
                  component={OrderTrack}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/track-details`}
                  component={TrackDetails}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/my-orders`}
                  component={MyOrderDetails}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/user/profile`}
                  component={Profile}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/customer/account/success`}
                  component={Success}
                />

                {/* Education */}
                <Route
                  path={`${process.env.PUBLIC_URL
                    }/education/size-guide/rings-size-guide`}
                  component={RingSize}
                />
                <Route
                  path={`${process.env.PUBLIC_URL
                    }/education/size-guide/bangle-and-bracelet-size-guide`}
                  component={bangleSizeChart}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/education/:id1/:id2/:id3`}
                  component={Education}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/education/:id1/:id2`}
                  component={Education}
                />

                {/* account */}
                <Route
                  path={`${process.env.PUBLIC_URL}/login`}
                  component={Login}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/create`}
                  component={Register}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/forgot-password`}
                  component={ForgetPassword}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/change-password`}
                  component={ChangePassword}
                />

                {/* Policies */}
                <Route
                  path={`${process.env.PUBLIC_URL}/privacy-policy`}
                  component={PrivacyPolicy}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/terms-and-conditions`}
                  component={TermsAndCondition}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/money-back-policy`}
                  component={MoneyBackPolicy}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/cancellation-policy`}
                  component={CancellationPolicy}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/return-policy`}
                  component={ReturnPolicy}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/cash-on-delivery-policy`}
                  component={CashOnDeliveryPolicy}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/shipping-policy`}
                  component={ShippingPolicy}
                />
                <Route
                  path={`${process.env.PUBLIC_URL
                    }/exchange-and-resizing-policy`}
                  component={ExchangePolicy}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/old-gold-purchase-policy`}
                  component={OldGoldPolicy}
                />
                <Route
                  path={`${process.env.PUBLIC_URL
                    }/gift-card-terms-and-conditions`}
                  component={GiftCardTc}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/dfe-terms-and-conditions`}
                  component={DfeTc}
                />

                <Route
                  path={`${process.env.PUBLIC_URL}/gift-voucher-and-conditions`}
                  component={GiftVoucherTc}
                />

                {/* News Page */}
                <Route
                  path={`${process.env.PUBLIC_URL}/news`}
                  component={NewsPage}
                />

                {/* Why Sunny Diamonds  */}
                <Route
                  path={`${process.env.PUBLIC_URL}/fair-pricing`}
                  component={fairPricing}
                />
                <Route
                  path={`${process.env.PUBLIC_URL
                    }/why-buy-from-us/payment-options`}
                  component={PaymentMethods}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/why-buy-from-us/:id`}
                  component={WhyFromUs}
                />

                {/*Diamond For Everyone*/}
                {/*
                            <Route path={`${process.env.PUBLIC_URL}/diamond-for-everyone`} component={DiamondForEveryone} />
                            <Route path={`${process.env.PUBLIC_URL}/dfe-form`} component={DfeForm} />
                            <Route path={`${process.env.PUBLIC_URL}/dfe-active-plan`} component={ActivePlan} /> */}

                {/* Gift Card Page  */}
                <Route
                  path={`${process.env.PUBLIC_URL}/gifts`}
                  component={GiftCard}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/gifts-purchase/:id`}
                  component={GiftPurchaseResponse}
                />

                {/* 404 */}
                <Route
                  path={`${process.env.PUBLIC_URL}/404`}
                  component={PageNotFound}
                />

                {/* checkout routing */}
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/checkout`}
                  component={CheckOut1}
                />

                {/* Listing page  */}
                <Route
                  exact
                  trending="true"
                  path={`${process.env.PUBLIC_URL}/trending`}
                  component={Listing}
                // render={(props) => <Listing trending={true} {...props} />}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/jewellery/`}
                  component={Listing}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/jewellery/:id1`}
                  component={Listing}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/jewellery/:id1/:id2`}
                  component={Listing}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/jewellery/:id1/:id2/:id3`}
                  component={Listing}
                />

                {/* NEW CMS PAGE ROUTE  */}
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/about-us`}
                  component={NewAboutUs}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/collections/:documentName`}
                  component={CollectionComponent} />
                {/* Product detail page  */}
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/:id`}
                  component={PdpDetails}
                />

                <Route
                  path={`${process.env.PUBLIC_URL}/*`}
                  component={PageNotFound}
                />

                <Route component={HeaderBody} />
              </Switch>
            </App>
          </Switch>
        </ScrollContext>
      </Suspense>
    </BrowserRouter>
  );
};

export default Routes;
