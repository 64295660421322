import axios from "axios";

export const getMinMaxPrice = (products) => {
    let min = products[0].variants[0].offerprice,
        max = min
    if (products !== undefined) {
        products.map((product, index) => {
            let variants = product.variants[0]
            let v = variants.offerprice;
            min = v < min ? v : min;
            max = v > max ? v : max;
        });
    }
    return { min: min, max: max };
};

// filtering with multivariant

export const getCategoryProducts = (items, { placeholder, color, value, sortBy }) => {
    if (items !== undefined) {
        let check_buffer = items;

        // reduce fucntion for traverse array
        check_buffer = Object.keys(placeholder).reduce(
            function (changedArray, currentCategory, index, menuCategories) {
                let filterSubject = Object.values(placeholder)[index];
                if (filterSubject && filterSubject.length > 0) {

                    let bufferedArray = [];
                    // checking products for filter based on selected value

                    filterSubject.forEach(menuValue => {
                        if (currentCategory == "metal_weight") {
                            bufferedArray = bufferedArray.concat(
                                changedArray.filter(card => {
                                    if (bufferedArray.indexOf(card) < 0) {
                                        // let metalWeight = card[currentCategory]
                                        let metalWeight
                                        card.variants.forEach(variant => { metalWeight = variant[currentCategory] })
                                        if (metalWeight > 0 && metalWeight <= 2 && menuValue == "0-2") return true
                                        else if (metalWeight > 2 && metalWeight <= 5 && menuValue == "2-5") return true
                                        else if (metalWeight > 5 && metalWeight <= 10 && menuValue == "5-10") return true
                                        else if (metalWeight > 10 && metalWeight <= 20 && menuValue == "10-20") return true
                                        else if (metalWeight > 20 && metalWeight <= 30 && menuValue == "20-30") return true
                                        else if (metalWeight > 30 && menuValue == "30-999") return true
                                    }
                                })
                            );
                        }
                        else if (currentCategory == "category") {
                            bufferedArray = bufferedArray.concat(
                                changedArray.filter(card => {
                                    if (card[currentCategory] != null)
                                        return (card[currentCategory].includes(menuValue) && bufferedArray.indexOf(card) < 0)
                                })
                            );
                        }
                        else {
                            bufferedArray = bufferedArray.concat(
                                changedArray.filter(card => {
                                    let filterCategory
                                    card.variants.forEach(variant => { filterCategory = variant[currentCategory] })
                                    if (filterCategory != null) {
                                        return (filterCategory.includes(menuValue))
                                    }
                                    // && bufferedArray.indexOf(card) < 0
                                })
                            );
                        }
                    });
                    return bufferedArray;
                } else return changedArray;
            },

            //filtered products against checkbox
            check_buffer
        );
        // filter products against color
        let color_buffer = check_buffer.filter(product => {
            // filter products against price range
            const startPriceMatch = typeof value.min !== "number" || value.min <= product.variants[0].offerprice;
            const endPriceMatch = typeof value.max !== "number" || product.variants[0].offerprice <= value.max;
            let colorMatch;
            if (color && color.length > 0) {
                let filterColor
                product.variants.forEach(variant => {
                    filterColor = variant.color;
                    if (filterColor.includes(color)) {
                        colorMatch = true;
                        return colorMatch && startPriceMatch && endPriceMatch;
                    }
                })
            }
            else {
                colorMatch = true;
            }
            return colorMatch && startPriceMatch && endPriceMatch;
        });

        return color_buffer.sort((product1, product2) => {
            // sorting
            if (sortBy === "HighToLow") {
                return product2.variants[0].offerprice < product1.variants[0].offerprice ? -1 : 1;
            } else if (sortBy === "LowToHigh") {
                return product2.variants[0].offerprice > product1.variants[0].offerprice ? -1 : 1;
            } else if (sortBy === "Latest") {
                return product2.id < product1.id ? -1 : 1;
            } else if (sortBy === "AscOrder") {
                return product1.name.localeCompare(product2.name);
            } else if (sortBy === "DescOrder") {
                return product2.name.localeCompare(product1.name);
            } else {
                return product2.id > product1.id ? -1 : 1;
            }
        });
    }
};

//cart total price
export const getCartTotal = cartItems => {
    var total = 0;
    for (var i = 0; i < cartItems.length; i++) {
        total += cartItems[i].qty * cartItems[i].selectedVariant.offerprice;
    }
    return total;
};

//giftcard total price
export const getGiftCardTotal = cardItems => {
    var totalPrice = 0;
    for (var i = 0; i < cardItems && cardItems.length; i++) {
        totalPrice += cardItems[i].amount;
    }
    return totalPrice;
};

// Get Related product Collection NEW API
export const getRelatedProductsCollection = async post => {
    return await axios
        .post(global.postUrl + "api2/related_products", post)
        .then(response => {
            return response;
        })
        .catch(error => {
            if (error) {
                return error;
            }
        });
};

// Get Category Collection NEW API
export const getCategoryCollection = async post => {
    return await axios
        .post(global.postUrl + "api2/catproducts", post)
        .then(response => {
            return response;
        })
        .catch(error => {
            if (error) {
                return error;
            }
        });
};

// Get All Category Collection NEW API
export const getAllCategoryCollection = async post => {
    return await axios
        .post(global.postUrl + "api2/allproducts", post)
        .then(response => {
            return response;
        })
        .catch(error => {
            if (error) {
                return error;
            }
        });
};

// Get Pdp Collection NEW API
export const getPdpCollection = async post => {
    return await axios
        .post(global.postUrl + "api2/product", post)
        .then(response => {
            return response;
        })
        .catch(error => {
            if (error) {
                return error;
            }
        });
};

// get contact
export const getContact = (products, storetype) => {
    const items = products.filter(product => {
        return (
            product.storetype.includes(storetype)
        );
    });
    return items;
};

// get store
export const getStore = (storeName) => {
    const items = window.StoreDetails.filter(store => {
        return (
            store.storetype.includes(storeName)
        );
    });
    return items;
};

// Get Career
export const getCareer = (products, type) => {
    const items = products.filter(product => {
        return product.category === type;
    });
    return items;
};

//Get News
export const getNews = (products, type) => {
    const items = products.filter(product => {
        return product.category === type;
    });
    return items;
};

// Get Education
export const getEdu = (products, type) => {
    const items = products.filter(product => {
        return product.name === type;
    });
    return items;
};

// Get Policy
export const getPolicy = (products, type) => {
    const items = products.filter(product => {
        return product.category === type;
    });
    return items;
};

//Get Job Feild
export const getDescription = (products, type) => {
    const items = products.filter(product => {
        return product.name === type;
    });
    return items[0]["content1"];
};

export const getDescription1 = (products, type) => {
    const items = products.filter(product => {
        return product.name === type;
    });
    return items[0]["content2"];
};

export const getDescription2 = (products, type) => {
    const items = products.filter(product => {
        return product.name === type;
    });
    return items[0]["content3"];
};

// Get Category
export const getCategory = (products, type) => {
    const items = products.filter(product => {
        return product.category === type;
    });
    return items;
};

//Get Customer Service Details
export const postCustomerServices = async type => {
    return await axios
        .post(global.postUrl + "customerservice", type)
        .then(response => {
            return response;
        })
        .catch(error => {
            if (error) {
                return error;
            }
        });
};

export const postNewCustomerAccount = async type => {
    return await axios
        .post(global.postUrl + "register2", type)
        .then(response => {
            return response;
        })
        .catch(error => {
            if (error) {
                return error;
            }
        });
};

//New user Mail Template
export const postUserEmail = async data => {
    return await axios
        .post("https://sunnydiamonds.com/sd_mailer/", data)
        .then(response => {
            return response;
        })
        .catch(error => {
            if (error) {
                return error;
            }
        });
};

export const postLoginData = async post => {
    return await axios
        .post(global.postUrl + "login", post)
        .then(response => {
            return response;
        })
        .catch(error => {
            if (error) {
                return error;
            }
        });
};
//User Logout
export const serLogoutData = async post => {
    return await axios
        .post(global.postUrl + "logout")
        .then(response => {
            return response;
        })
        .catch(error => {
            if (error) {
                return error;
            }
        });
};

//Email Subscription
export const emailSubscription = async post => {
    return await axios
        .post(global.postUrl + "subscription", post)
        .then(response => {
            return response;
        })
        .catch(error => {
            if (error) {
                return error;
            }
        });
};

//Order id
export const postOrderdetails = async (post, mode) => {
    return await axios
        .post(global.postUrl + "order/place/" + mode, post)
        .then(response => {
            return response;
        })
        .catch(error => {
            if (error) {
                return error;
            }
        });
};

//Career details
export const postCareerDetails = async data => {
    return await axios
        .post(global.postUrl + "careers", data)
        .then(response => {
            return response;
        })
        .catch(error => {
            if (error) {
                return error;
            }
        });
};

//Order Checkout Email
export const postUniqueCheck = async post => {
    return await axios
        .post(global.postUrl + "unique", post)
        .then(response => {
            return response;
        })
        .catch(error => {
            if (error) {
                return error;
            }
        });
};

//Order tracking
export const serOrderDetails = async data => {
    return await axios
        .post(global.postUrl + "order/track", data)
        .then(response => {
            return response;
        })
        .catch(error => {
            if (error) {
                return error;
            }
        });
};


export const setSessionData = data => {
    sessionStorage.setItem("user_name", data.user_name);
    sessionStorage.setItem("user_id", data.user_id);
    sessionStorage.setItem("user_type", data.user_type);
    sessionStorage.setItem("api_token", data.api_token);
    sessionStorage.setItem("token", data.token);
    sessionStorage.setItem("email", data.email);
    sessionStorage.setItem("cart_id", data.cart_id);
    axios.defaults.headers.common["Authorization"] = "Bearer " + sessionStorage.getItem("token");
    axios.defaults.headers.common["api_token"] = sessionStorage.getItem("api_token");
    axios.defaults.headers.common["user_id"] = sessionStorage.getItem("user_id");
    axios.defaults.headers.common["user_type"] = sessionStorage.getItem("user_type");
};

export const destroySessionData = () => {
    sessionStorage.clear();
    sessionStorage.removeItem("user_name");
    sessionStorage.removeItem("user_id");
    sessionStorage.removeItem("user_type");
    sessionStorage.removeItem("api_token");
    sessionStorage.removeItem("session_id");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("email");
    sessionStorage.removeItem("cart_id");
    axios.defaults.headers.common["Authorization"] = "Bearer " + sessionStorage.getItem("token", null);
    axios.defaults.headers.common["api_token"] = sessionStorage.getItem("api_token", null);
    axios.defaults.headers.common["user_id"] = sessionStorage.getItem("user_id", null);
    axios.defaults.headers.common["user_type"] = sessionStorage.getItem("user_type", null);
};

//Profile get
export const serProfile = async post => {
    return await axios
        .post(global.postUrl + "profile/get", post)
        .then(response => {
            return response;
        })
        .catch(error => {
            if (error) {
                return error;
            }
        });
};

//Profile Edit
export const serProfileEdit = async post => {
    return await axios
        .post(global.postUrl + "profile/update", post)
        .then(response => {
            return response;
        })
        .catch(error => {
            if (error) {
                return error;
            }
        });
};

//Forgot Password
export const serForgotPassword = async data => {
    return await axios
        .post(global.postUrl + "forgotpassword", data)
        .then(response => {
            return response;
        })
        .catch(error => {
            if (error) {
                return error;
            }
        });
};

//Change Password
export const serChangePassword = async data => {
    return await axios
        .post(global.postUrl + "changepassword", data)
        .then(response => {
            return response;
        })
        .catch(error => {
            if (error) {
                return error;
            }
        });
};

//My Orders list:
export const serMyOrderList = async post => {
    return await axios
        .post(global.postUrl + "order/list", post)
        .then(response => {
            return response;
        })
        .catch(error => {
            if (error) {
                return error;
            }
        });
};

//Log
export const serUserLog = async log => {
    return await axios
        .post(global.postUrl + "userlog", log)
        .then(response => {
            return response;
        })
        .catch(error => {
            if (error) {
                return error;
            }
        });
};

// server to cart sync
export const postCartDetails = async post => {
    return await axios
        .post(global.postUrl + "order/validate", post)
        .then(response => {
            return response;
        })
        .catch(error => {
            if (error) {
                return error;
            }
        });
};

// Request-OTP
export const serReqOtp = async (post, url) => {
    return axios
        .post(global.postUrl + url, post, {
            timeout: 20000
        })
        .then(response => {
            return response;
        })
        .catch(error => {
            if (error) {
                return error;
            }
        });
};

// Verify-OTP
export const serVerifyOtp = async (post, url) => {
    return await axios
        .post(global.postUrl + url, post, {
            timeout: 20000
        })
        .then(response => {
            return response;
        })
        .catch(error => {
            if (error) {
                return error;
            }
        });
};

// timer for counter
export function timerService(active) {
    return new Promise((resolve) =>
        setTimeout(() => resolve({ data: active }), 1000))
}

// create gift card
export const serCreateGift = async post => {
    return await axios
        .post(global.postUrl + "giftcards/purchase/lock", post)
        .then(response => {
            return response;
        })
        .catch(error => {
            if (error) {
                return error;
            }
        });
}

// gift card balance
export const serCheckGiftBalance = async post => {
    return await axios
        .post(global.postUrl + "giftcards/balance", post)
        .then(response => {
            return response;
        })
        .catch(error => {
            if (error) {
                return error;
            }
        });
}

// gift card Template
export const serFetchTemplate = async post => {
    return await axios
        .post(global.postUrl + "giftcards/templates")
        .then(response => {
            return response;
        })
        .catch(error => {
            if (error) {
                return error;
            }
        });
}


//DFE

export const serSubmitDfe = async post => {
    return await axios
        .post(global.postUrl + "dfe")
        .then(response => {
            return response;
        })
        .catch(error => {
            if (error) {
                return error;
            }
        });
}

// Career API call 
export const serCareerDepartment = async post => {
    return await axios
        .get(global.postUrl + "careers/departments", post)
        .then(response => {
            return response;
        })
        .catch(error => {
            if (error) {
                return error;
            }
        });
};

export const serCareerPosition = async data => {
    return await axios
        .post(global.postUrl + "careers/positions", data)
        .then(response => {
            return response;
        })
        .catch(error => {
            if (error) {
                return error;
            }
        });
};

//Home page banner

export const serBanner = async data => {
    return await axios
        .post(global.postUrl + "banners", data)
        .then(response => {
            return response;
        })
        .catch(error => {
            if (error) {
                return error;
            }
        });
};

// Customer Enquiry Type API call 
export const selEnquiryType = async post => {
    return await axios
        .get(global.postUrl + "enquiry/types", post)
        .then(response => {
            return response;
        })
        .catch(error => {
            if (error) {
                return error;
            }
        });
};
