import React from 'react';
import { Link } from 'react-router-dom'

const CartHeader = ({ item, symbol, removeFromMiniCart }) => {

    const inr = new Intl.NumberFormat('en-IN', { style: 'decimal'  })

    const CartimgeSource = (item) => {
        let images = []
        let imgeSrc = []
        let imgI = 0;
        const exts = [".png", ".jpg", ".webp", ".gif"];
        if (item.selectedVariant.image) {
            let allImages = item.selectedVariant.image;
            images = allImages.split(",")
            images.forEach(function (img) {
                let ext = img.substring(img.lastIndexOf("."));

                if (exts.includes(ext)) {
                    imgeSrc[imgI] = img;
                    imgI++;
                }
            });
        }
        return imgeSrc[0];
    };

    const removeFromCart = item => {
        removeFromMiniCart(item, item.selectedVariant)
    }

    const img_fallback = (event) => {
        event.target.parentNode.childNodes.forEach(function (node) {
          if (node.nodeName == "IMG") node.src = window.no_image;
        });
      }

    return (
        <li>
            <div className="media">
                <Link to={`${process.env.PUBLIC_URL}/cart`}>
                    <img
                        src={`${CartimgeSource(item)}?resize=88,88&compress=50`}
                        onError={img_fallback}
                        className="img-fluid lazyload bg-img image-border"
                        alt="products on cart"
                    />
                </Link>
                <div className="media-body uk-padding-small uk-padding-remove-vertical uk-margin-remove">
                    <Link to={`${process.env.PUBLIC_URL}/cart`}><h5 className='para-one uk-margin-remove'>{item.name}</h5></Link>
                    {item.selectedVariant ?
                        <div className='d-inline-flex uk-margin-small uk-margin-remove-horizontal'>
                            <p className={"border border-secondary price_update d-inline-block rounded px-2 ml-1 my-0 "}>
                                {item.selectedVariant.color.replace("-", " ").toUpperCase()}
                            </p>
                            {
                                item.selectedVariant.size > 0 ?
                                    <p className={"border border-secondary price_update d-inline-block rounded px-2 ml-1 my-0 "}>
                                        {item.selectedVariant.size}
                                    </p> : ""}
                        </div>
                        : ""
                    }
                    <h4 className='cart_sum'><span>{item.qty} x {symbol} {inr.format(item.variant.offerprice)}
                    </span>
                    </h4>
                </div>
                <div className="close-circle">
                    <a href={null} onClick={() => { removeFromCart(item) }}>
                        <i className="fa fa-times" aria-hidden="true"></i>
                    </a>
                </div>
            </div>
        </li>
    )
}

export default CartHeader;
