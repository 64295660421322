import React, { useEffect, useState } from 'react';
import './cookie-consent.css'

const CookieBanner = () => {
    const [hideBanner, setHideBanner] = useState(() => {
        const localStorageValue = localStorage.getItem('hideBanner');
        return localStorageValue === 'true' ? true : false;
    });

    useEffect(() => {
        if (!hideBanner) {
            const timer = setTimeout(() => {
                setHideBanner(true);
            }, 6000);
            return () => clearTimeout(timer);
        }
    }, [hideBanner]);


    const handleAgree = () => {
        setHideBanner(true);
        localStorage.setItem('hideBanner', 'true');
    };

    return (
        <div className={hideBanner ? 'cookie-container hide d-md-block d-none' : 'cookie-container d-md-block d-none'}>
            <div className='d-flex justify-content-end'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#fff" height="20" width="20" className="w-6 h-6" onClick={handleAgree} >
                    <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z" clipRule="evenodd" />
                </svg>
            </div>
            <p className="cookie-text uk-margin-remove-top">
            By using our website, you agree to the use of cookies as described in our 
                <a href={`${process.env.PUBLIC_URL}/privacy-policy`} target='_blank'> <b>Privacy Policy</b>.</a>
            </p>
            <div className="agree">
                <button onClick={handleAgree}>I Understood</button>
            </div>
        </div>
    );
};

export default CookieBanner;