import React from "react";

const CategoryDescription = (props) => {

    const {
        selectedSorting,
        setSelectedSorting,
        totalProducts,
        productsCount,
        categoryTitle,
        categoryDescription
    } = props;

    return (
        <div data-uk-grid className="uk-visible@l">
            <div className="uk-width-expand@m uk-padding-remove">
                <h2 className="uk-h2 pb-1 pt-0">{categoryTitle}</h2>
                <p className="uk-margin-remove">{categoryDescription}</p>
            </div>
            <div className="uk-width-1-3@m uk-flex uk-flex-middle sort-by uk-flex-right p-0">
                <div className="uk-margin">
                    <p className=" text-muted text-small">Showing {productsCount} / {totalProducts} Products</p>
                    <div className="uk-button-group uk-flex uk-flex-center">
                        <div className="uk-inline">
                            <button className="uk-button uk-button-default uk-flex uk-flex-between" type="button">
                                {window.filterOption.algolia_sorting.filter(item => item.value == selectedSorting)[0].name}
                                <div className="uk-align-center uk-margin-remove">
                                    <span uk-icon="icon: chevron-down" />
                                </div>
                            </button>
                            <div data-uk-dropdown="mode: click; pos: bottom-center; target: !.uk-button-group;">
                                <ul className="uk-nav uk-dropdown-nav">
                                    {window.filterOption.algolia_sorting.map((element, i) => (
                                        <li
                                            key={i} className="d-block uk-dropdown-close"
                                            onClick={() => setSelectedSorting(element.value)}>
                                            <a href="javascript:void(0)">{element.name}</a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CategoryDescription