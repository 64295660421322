import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import Topbar from "./topbar";
import HeaderBody from "./header-body";
import { postUserLog } from "../../actions";

import './css/sunny-diamond.css'
import './css/header.css'
import { Helmet } from "react-helmet";


const Header = (props) => {

    const [navigationData, setNavigationData] = useState({});

    useEffect(() => {
        const observer = new PerformanceObserver((list) => {
            const entries = list.getEntries();

            const entryActions = {
                navigation: (entry) => setNavigationData(JSON.stringify(entry.toJSON())),
            };

            entries.forEach((entry) => {
                const entryType = entry.entryType;
                const action = entryActions[entryType];
                if (action) {
                    action(entry);
                }
            });
        });

        observer.observe({
            entryTypes: ['navigation'],
        });

        return () => {
            observer.disconnect();
        };

    }, [props.location])




    useEffect(() => {
        getLocationData();
    }, [navigationData,props.location])


    const getLocationData = () => {
        fetch("https://geolocation-db.com/json/")
            .then((response) => {
                response.json()
                    .then((data) => {
                        var userLog = {
                            visited_page: window.location.href,
                            ip_address: data.IPv4,
                            address: Object.keys(navigationData).length != 0 ? navigationData : "Not found",
                            latitude: data.latitude == "Not found" ? 0 : data.latitude,
                            longitude: data.longitude == "Not found" ? 0 : data.longitude,
                            user_id: navigator.cookieEnabled && sessionStorage.getItem("user_id"),
                        };
                        props.postUserLog(userLog);
                    })
                    .catch(() => {
                        var userLog = {
                            visited_page: window.location.href,
                            address: Object.keys(navigationData).length != 0 ? navigationData : "Not found",
                            user_id: navigator.cookieEnabled && sessionStorage.getItem("user_id"),
                        };
                        props.postUserLog(userLog);
                    });
            })
            .catch(() => {
                var userLog = {
                    visited_page: window.location.href,
                    address: Object.keys(navigationData).length != 0 ? navigationData : "Not found",
                    user_id: navigator.cookieEnabled && sessionStorage.getItem("user_id"),
                };
                props.postUserLog(userLog);
            });
    };

    return (
        <div>
            <Helmet>
                <meta property="og:url" content={window.location.href} />
            </Helmet>
            <Topbar topbarText={window.navLinksTop.topBarText} />
            <HeaderBody
                navBar={window.navLinksTop.navBar}
                wishListItems={props.wishListItems}
                compareItems={props.compareItems}
            />
        </div>
    )
}


const mapStateToProps = state => ({
    wishListItems: state.wishlist.list,
    compareItems: state.compare.items
});

export default connect(mapStateToProps, { postUserLog })(Header);