import {
    RECEIVE_HOME_BANNER,
    RECEIVE_PAGE_BANNER
} from "../constants/ActionTypes";

const initialState = {
    home_banner: {},
    page_banner: {},
}

const BannerReducer = (state = initialState, action) => {
    switch (action.type) {

        case RECEIVE_HOME_BANNER:
            return {
                ...state,
                home_banner: action.payload
            };
            case RECEIVE_PAGE_BANNER:
                return {
                    ...state,
                    page_banner: action.payload
                };
        default:
            return state;
    }
}

export default BannerReducer;