import React, {Component} from 'react';
import { withTranslate } from 'react-redux-multilingual'

import {connect} from 'react-redux'

import {SlideUpDown} from "../../services/script"
import { ToastContainer } from 'react-toastify';

import store from '../../store';

class ThemeSettings extends Component {

    constructor(props){
        super(props);

        this.state = {
            divName:'RTL',
            themeLayout: false,
            themeColor: this.props.themeColor
        }
    }


    /*=====================
     Tap on Top
     ==========================*/
    componentWillMount(){
        window.addEventListener('scroll', this.handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    handleScroll = () => {
        if (document.documentElement.scrollTop > 600) {
            document.querySelector(".tap-top").style = "display: block";
        } else {
            document.querySelector(".tap-top").style = "display: none";
        }
    }
    clickToTop(){
        window.scroll({top: 0, left: 0, behavior: 'smooth' })
    }

    componentDidMount() {
        SlideUpDown('setting-title');
        if(navigator.cookieEnabled) {
            if(localStorage.getItem('themeLayout') === 'dark'){
                this.setState({themeLayout:'dark'})
            }else{
                this.setState({themeLayout:''})
            }
        } else {
            if(this.state.themeColor === 'dark'){
                this.setState({themeLayout:'dark'})
            } else {
                this.setState({themeLayout:''})
            }
        }
    }

    // Color Picker
    changeColor(event, color){
        var elems = document.querySelectorAll(".color-box li");
        [].forEach.call(elems, function(elemt) {
            elemt.classList.remove('active');
        })

        event.target.classList.add('active');
        document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/`+color+`.css` );
    }

    ChangeRtl(divName){
        if(divName === 'RTL') {
            document.body.classList.add('rtl');
            this.setState({divName: 'LTR'});
        }else{
            document.body.classList.remove('rtl');
            this.setState({divName: 'RTL'});
        }
    }

    changeThemeLayout() {
        this.setState({
            themeLayout:!this.state.themeLayout
        })

        if(navigator.cookieEnabled) {
            if(localStorage.getItem('themeLayout') === 'dark'){
                localStorage.setItem('themeLayout', 'light')
            }else{
                localStorage.setItem('themeLayout', 'dark')
            }
        }
        if(this.props.themeColor === 'light'){
            store.dispatch({type: 'THEME_LAYOUT', themeColor : 'dark'});
        } else {
            store.dispatch({type: 'THEME_LAYOUT', themeColor : 'light'});
        }

    }

    render() {
        if(navigator.cookieEnabled) {
            if(localStorage.getItem('themeLayout') === 'dark'){
                document.body.classList.add('dark');
            }else{
                document.body.classList.remove('dark');
            }
        } else {
            if(this.state.themeColor === 'dark'){
                document.body.classList.add('dark');
            } else {
                document.body.classList.remove('dark');
            }
        }
        let tap_to_top = {display: 'none'}

        return (
            <div>
                <div className="tap-top tw-mr-5 hover:tw-bg-primaryDark tw-bottom-[8rem]" onClick={this.clickToTop} style={tap_to_top}>
                    <div>
                        <i className="fas fa-angle-up tw-mt-1"></i>
                    </div>
                </div>

                <ToastContainer autoClose={1000} className="py-3 toast-container" />
            </div>
        );
    }
}

// export default withTranslate(ThemeSettings);


const mapStateToProps = (state) => ({
    themeColor: state.theme.themeColor
})

export default connect(mapStateToProps) (withTranslate(ThemeSettings));
