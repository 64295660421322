import React, { useState } from "react";
import SDClient from "../../../sd_core/client";
import Loader from "../../common/spinner"


const Coupons = (props) => {

  const [discountCode, setDiscountCode] = useState("");
  const [validationTrace, setValidationTrace] = useState({});
  const [applyLoading, setApplyLoading] = useState(false);
  const [viewCoupons, setViewCoupons] = useState(false);

  const applyDiscountCode = () => {
    console.log(discountCode)
    setApplyLoading(true);
    SDClient.endpoint("virtual_order_set_discount_code", { 'code': discountCode }).then(
      (data) => {
        if (data.message.action) {
          setValidationTrace({
            APPLY_OK: true
          });
          props.updateVO();
        } else {
          if (data.message.reason == "MESSAGE_VALIDATION_ERROR") {
            setValidationTrace(data.message.trace);
          } else if (data.message.reason == "DISCOUNT_COUPON_NOT_FOUND") {
            setValidationTrace({ DISCOUNT_COUPON_NOT_FOUND: true });
          } else if (data.message.reason == "SUBTOTAL_LESS_THAN_AMOUNT_LIMIT") {
            setValidationTrace({ SUBTOTAL_LESS_THAN_AMOUNT_LIMIT: true });
          }
        }
        setApplyLoading(false);
      },
      // console.log
    )
  }

  const clearDiscountCode = () => {
    setApplyLoading(true);
    SDClient.endpoint("virtual_order_clear_discount_code").then(
      (data) => {
        setValidationTrace({});
        setApplyLoading(false);
        props.updateVO();
      },
      // console.log
    )
  }

  const promoCode = () => {
    return (
      <div className="mb-3">
        <div className="para-one">Promo code</div>
        <div className="d-flex flex-row">
          <input
            type="text"
            name="promocodes"
            className="uk-input uk-text-uppercase"
            value={discountCode}
            onChange={(e) => setDiscountCode(e.target.value)}
          />
          <button
            type="button"
            className="uk-button uk-button-secondary add-cart uk-align-center uk-margin-remove"
            onClick={() => applyDiscountCode()}>
            Apply
          </button>
        </div>
        {validationTrace.DISCOUNT_COUPON_NOT_FOUND ? (<h6 className="uk-text-danger uk-margin-small"> This promocode is not a valid one, please check again. </h6>) : null}
        {validationTrace.APPLY_OK ? (<h6 className="uk-text-success uk-margin-small"> Promocode has been successfully applied </h6>) : null}
        {validationTrace.code ? (<h6 className="uk-text-danger uk-margin-small"> Please specify a valid promocode. </h6>) : null}
        {validationTrace.SUBTOTAL_LESS_THAN_AMOUNT_LIMIT ? (<h6 className="uk-text-danger uk-margin-small"> This promocode can only be applied on orders above ₹1 Lakh.</h6>) : null}
      </div>
    )
  }

  const appliedPromoCode = () => {
    if (props.VOData.order.applied_payment_discount) {
      const promoline = () => {
        if (props.VOData.order.applied_payment_discount.virtual_order_discount_type == "PERCENTAGE") {
          return "A discount of " + props.VOData.order.applied_payment_discount.virtual_order_discount_relative_value + "% has been applied."
        } else if (props.VOData.order.applied_payment_discount.virtual_order_discount_type == "FLAT") {
          return "A flat discount of " + props.VOData.order.applied_payment_discount.virtual_order_discount_relative_value + " has been applied."
        } else {
          return "A flat discount of " + props.VOData.order.applied_payment_discount.virtual_order_discount_relative_value + " will be applied on orders above ₹1 Lakh."
        }
      }
      return (
        <>
          <hr></hr>
          <div className="d-flex justify-content-between">
            <p className="text-secondary text-uppercase">Applied Discount Code</p>
            <div className="mt-1">
              {props.syncing ? (<Loader />) : ""}
            </div>
          </div>
          <div className="mb-3 d-flex justify-content-between">
            <div className="m-0">
              {props.VOData.order.applied_payment_discount.virtual_order_discount_name}
              <span uk-icon="icon: trash; ratio: 0.7" className="uk-icon uk-margin-small-left" onClick={clearDiscountCode} style={{ color: "red" }} />
              <br />
              <h6 className="uk-text-success uk-margin-small">{promoline()}</h6>
            </div>
            {/* <button className="uk-button uk-button-primary buy-now uk-align-center uk-margin-remove" onClick={clearDiscountCode}>
              <small>REMOVE</small>
            </button> */}
          </div>
        </>
      )
    }
  }

  const setCouponFromList = (couponCode) => {
    setDiscountCode(couponCode)
    setViewCoupons(false);
  }

  const showCoupons = () => {
    const discountCoupons = props.VOData.options.discount_coupons;

    const coupons = []

    for (let [k, v] of Object.entries(discountCoupons)) {
      coupons.push((
        <div class="uk-card uk-card-default uk-card-body w-100 uk-margin-small uk-padding-small rounded">
          <div className="uk-flex uk-flex-between">
            <div className="p-2 uk-width-1-2 text-center rounded" style={{ border: "1px dashed #b68b2c" }}>
              <h5 class="uk-card-title uk-margin-remove uk-text-bold uk-text-uppercase" style={{ color: "#b68b2c" }}>
                {v.code}
              </h5>
            </div>
            <button
              type="button"
              className="btn rounded d-flex justify-content-center align-items-center text-white m-1"
              style={{ background: "#722257" }}
              onClick={() => setCouponFromList(v.code)}>
              Copy
            </button>
          </div>
          <div className="uk-text-bold mt-2">{v.label}</div>
          <div class="uk-margin-remove uk-padding-remove uk-text-meta uk-panel mt-2">
            {v.description}
          </div>
        </div >
      ))
    }
    return coupons
  }

  const viewApplicablePromocodes = () => {
    if (viewCoupons) {
      if (props.VOData.options.discount_coupons.length > 0)
        return (
          <div className="blurred-background uk-flex uk-flex-center uk-flex-middle uk-padding">
            <div className="bg-light shadow uk-padding-small px-0 rounded d-flex flex-column align-items-center">
              <div className="uk-flex uk-flex-between w-100 mb-3 px-3">
                <div className="text-uppercase uk-margin-remove uk-text-bold">APPLY COUPON</div>
                <div>
                  <svg
                    onClick={() => setViewCoupons(false)}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="mx-1 text-secondary cursor-pointer"
                    viewBox="0 0 16 16">
                    <path
                      d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z" />
                  </svg>
                </div>
              </div>
              <div>
                {showCoupons()}
              </div>
            </div>
          </div>
        )
      else {
        return (
          <div className="blurred-background uk-flex uk-flex-center uk-flex-middle uk-padding">
            <div className="bg-light shadow uk-padding-small px-0 rounded d-flex flex-column align-items-center">
              <div className="uk-flex uk-flex-between w-100 mb-3 px-3">
                <div className="text-uppercase uk-margin-remove uk-text-bold" style={{ minWidth: "15rem" }}>APPLY COUPON</div>
                <div>
                  <svg
                    onClick={() => setViewCoupons(false)}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="mx-1 text-secondary cursor-pointer"
                    viewBox="0 0 16 16">
                    <path
                      d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z" />
                  </svg>
                </div>
              </div>
              <div class="uk-card uk-card-default uk-card-body w-100 uk-margin-small uk-padding-small rounded">
                <p>No cards found</p>
              </div >
            </div>
          </div>
        )
      }
    }
  }

  const loadPromocodes = () => {
    if (!props.syncing) {
      return appliedPromoCode();
    } else {
      return (
        <>
          <hr></hr>
          <div className="py-2">
            <p className="placeholder col-9"></p>
            <p className="placeholder col-8"></p>
          </div>
        </>
      )
    }
  }

  return (
    <div className="bg-white border uk-padding-small uk-margin-bottom">
      {props.discountDetails && props.discountDetails.errors && props.discountDetails.errors
        .map((error, i) => (
          < div className="alert alert-danger" role="alert">
            {error.text}
          </div>
        ))
      }
      <div className="d-flex justify-content-between">
        <p className="uk-text-lead text-uppercase para-one uk-margin-small-bottom uk-text-bold"><b>Coupon Code</b></p>
        <div className="mt-1">
          {applyLoading ? (<Loader />) : ""}
        </div>
      </div>
      {promoCode()}

      {loadPromocodes()}

      {viewApplicablePromocodes()}

      <a href="javascript:void(0)" onClick={() => setViewCoupons(true)}>View coupons</a>


      {/* {giftCard()} */}
      {/* <div className="d-flex justify-content-between ">
                    <p className="mb-0">Giftcard Discount:</p>
                    <p className="mb-0">
                        {props.symbol}0.00
                    </p>
                </div> */}
    </div>
  )
}

export default Coupons

